import { getSubmittedTasks } from 'api';
import { useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import formatFullName from 'utils/formatFullName';
import getNextPageParam from 'utils/getNextPageParam';
import getPreviousPageParam from 'utils/getPreviousPageParam';

const PAGE_SIZE = 20;

export const useFetchSubmittedTasks = (status: string) => {
  const [filterByCustomerNameQuery, setFilterByCustomerNameQuery] = useState<string>('');
  const query: UseInfiniteQueryResult<any> = useInfiniteQuery(
    'useFetchSubmittedTasks',
    ({ pageParam = 1 }) => getSubmittedTasks(status, { limit: PAGE_SIZE, skip: (pageParam - 1) * PAGE_SIZE }),
    {
      keepPreviousData: true,
      getNextPageParam,
      getPreviousPageParam,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const tasks = useMemo(() => {
    if (!query.data) return [];

    const resultsList = query.data.pages.reduce((acc, page) => [...acc, ...page?.data], [] as any[]);

    const filterByCustomerName = (resultList: any[]) =>
      resultList.filter((result) =>
        formatFullName(result.primaryDetails.firstName, undefined, result.primaryDetails.lastName)
          ?.toLowerCase()
          .includes(filterByCustomerNameQuery?.toLowerCase()),
      );

    if (filterByCustomerNameQuery) {
      return filterByCustomerName(resultsList);
    }

    return resultsList;
  }, [query.data, filterByCustomerNameQuery]);

  const totalCount = useMemo(() => {
    if (!query.data) return 0;
    return query.data.pages[0].pagination.totalCount;
  }, [query.data]);

  return { query, tasks, totalCount, setFilterByCustomerNameQuery };
};

export default useFetchSubmittedTasks;
