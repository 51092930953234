import { updateEscalationById } from 'api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { IEscalationTasksErrorResponse, ICreateEscalationTasksSuccessResponse } from 'models';

interface Props {
  id: string;
  onSuccess?: (res: ICreateEscalationTasksSuccessResponse) => void;
  onError?: (err: IEscalationTasksErrorResponse) => void;
}

export const usePutMyEscalationTask = ({
  id,
  onSuccess,
  onError,
}: Props): UseMutationResult<ICreateEscalationTasksSuccessResponse, IEscalationTasksErrorResponse, any> => {
  const queryClient = useQueryClient();

  return useMutation((props: any) => updateEscalationById(id, props), {
    onSuccess: (res) => {
      if (res.status !== 'ok') {
        if (onSuccess) onSuccess(res);
        return;
      }

      queryClient.invalidateQueries({
        queryKey: 'usePutMyEscalationTask',
      });
    },
    onError: (err: any) => {
      if (onError) onError(err);
      alert(err.response.message);
    },
  });
};

export default usePutMyEscalationTask;
