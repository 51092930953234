import { putCompletePortalCheckTask } from 'api';
import { useMutation, UseMutationResult } from 'react-query';
import {
  IPortalCheckStartStopErrorResponse,
  IPortalCheckStartStopPayload,
  IPortalCheckStartStopSuccessResponse,
} from 'models';

interface Props {
  onSuccess?: (res: IPortalCheckStartStopSuccessResponse) => void;
  onError?: (err: IPortalCheckStartStopErrorResponse) => void;
}

export const usePutPortalCheckComplete = ({
  onSuccess,
  onError,
}: Props): UseMutationResult<
  IPortalCheckStartStopSuccessResponse,
  IPortalCheckStartStopErrorResponse,
  IPortalCheckStartStopPayload
> => {
  const mutation = useMutation((props: IPortalCheckStartStopPayload) => putCompletePortalCheckTask(props), {
    onSuccess: (res) => {
      if (res.status === 'ok') {
        if (onSuccess) onSuccess(res);
        return;
      }

      alert(res.message);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      }

      alert(err.response.message);
    },
  });

  return mutation;
};

export default usePutPortalCheckComplete;
