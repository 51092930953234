import { useContext, useEffect, useState, useCallback } from 'react';
import { CTAButton, InputDropdownField, InputTextField } from 'components';
import { IInsurancePayer } from 'models';
import useFetchInsurancePayers from 'api/hooks/useFetchInsurancePayers';
import { useFetchPayerByInsurance } from 'api/hooks';
import styles from './Authorization.module.scss';
import InitiateEmailFaxOrderContext from '../../context';

const Authorization = () => {
  const [payerInfo, setPayerInfo] = useState<null | IInsurancePayer>(null);
  const [authorizedInsurance, setAuthorizedInsurance] = useState<string>('');
  const { insurancePayers } = useFetchInsurancePayers();
  const { form, setCurrentStep, setIsEscalateModalOpen } = useContext(InitiateEmailFaxOrderContext);

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const prescriptionDetails = watch('prescriptionDetails');

  useEffect(() => {
    if (prescriptionDetails?.primaryPayerAuthorizationRequestRequired) {
      setAuthorizedInsurance(prescriptionDetails.primaryInsurance);
    }
    if (prescriptionDetails?.secondaryPayerAuthorizationRequestRequired) {
      setAuthorizedInsurance(prescriptionDetails.secondaryInsurance);
    }
    if (prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired) {
      setAuthorizedInsurance(prescriptionDetails.tertiaryInsurance);
    }
  }, [prescriptionDetails]);

  const { payer } = useFetchPayerByInsurance(authorizedInsurance);

  const getRequestAuthType = useCallback(
    (payerName = '') => {
      return insurancePayers.find((x) => x.payerName === payerName) || null;
    },
    [insurancePayers],
  );
  useEffect(() => {
    //   /** Get Default values */
    const fetchPayerInfo = getRequestAuthType(payer?.payerName || '');
    if (!fetchPayerInfo) return;

    setPayerInfo(fetchPayerInfo);
    setValue('authorization.payer', fetchPayerInfo.payerName);
    setValue('authorization.authorizationMethod', fetchPayerInfo.requestAuth);
  }, [setValue, payer, getRequestAuthType]);

  const authorizationMethod = watch('authorization.authorizationMethod');

  useEffect(() => {
    setValue(
      'authorization.authorizationMethodDetails',
      (() => {
        switch (authorizationMethod) {
          case 'Fax' /* Case 0 */:
            return Array.isArray(payerInfo?.fax) ? `${payerInfo?.fax.join(' or ')}` : payerInfo?.fax;
          case 'Phone' /* Case 1 */:
            return payerInfo?.phone;
          case 'Portal' /* Case 2 */:
            return payerInfo?.portalLink;
          default:
            return '';
        }
      })(),
    );
  }, [authorizationMethod, payerInfo?.fax, payerInfo?.phone, payerInfo?.portalLink, payerInfo?.requestAuth, setValue]);

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <span className={styles.title}>Authorization</span>
        <hr />
        <InputDropdownField
          defaultValue={watch('authorization.payer')}
          {...register('authorization.payer', {
            required: 'Required field',
          })}
          className={styles.inputField}
          label="Select payer"
          noShadow
          status={errors.authorization?.payer ? 'error' : 'active'}
          bottomLabel={errors.authorization?.payer?.message}
          items={Array.from(new Set(insurancePayers.map(({ payerName }) => payerName)))}
          onChange={(e) => {
            const newPayerInfo = getRequestAuthType(e.target.value);
            if (!newPayerInfo) return;

            setPayerInfo(payerInfo);
            setValue('authorization.payer', newPayerInfo.payerName);
            setValue('authorization.authorizationMethod', newPayerInfo.requestAuth);
            setPayerInfo(newPayerInfo);
          }}
        />
        <InputDropdownField
          className={styles.inputField}
          disabled
          label="Authorization Method"
          noShadow
          placeholder="Select authorization method"
          status={errors.authorization?.authorizationMethod ? 'error' : 'active'}
          bottomLabel={errors.authorization?.authorizationMethod?.message}
          items={Array.from(new Set(insurancePayers.map(({ requestAuth }) => requestAuth)))}
          defaultValue={watch('authorization.authorizationMethod')}
          {...register('authorization.authorizationMethod', {
            required: 'Required field',
          })}
        />
        {payerInfo?.portalLink && payerInfo?.requestAuth === 'Portal' ? (
          <>
            <span className={styles.portalLinkLabel}>Portal Link</span>
            <a href={payerInfo?.portalLink} target="_blank" rel="noreferrer">
              {payerInfo?.portalLink}
            </a>
          </>
        ) : (
          <InputTextField
            {...register('authorization.authorizationMethodDetails')}
            disabled
            noShadow
            className={styles.inputField}
            label={(() => {
              switch (payerInfo?.requestAuth) {
                case 'Fax' /* Case 0 */:
                  return 'Fax Number';
                case 'Phone' /* Case 1 */:
                  return 'Phone Number';
                case 'Portal' /* Case 2 */:
                  return 'Portal Link';
                default:
                  return '';
              }
            })()}
            value={(() => {
              switch (payerInfo?.requestAuth) {
                case 'Fax' /* Case 0 */:
                  return Array.isArray(payerInfo.fax) ? `${payerInfo.fax.join()}` : payerInfo.fax;
                case 'Phone' /* Case 1 */:
                  return payerInfo.phone;
                case 'Portal' /* Case 2 */:
                  return payerInfo.portalLink;
                default:
                  return '';
              }
            })()}
            // isLink={payerInfo?.requestAuth === 'Portal'}
          />
        )}

        <div className={styles.ctaContainer}>
          <div>
            <CTAButton rectangled text="Back" onClick={() => setCurrentStep((prev) => prev - 1)} invert />
          </div>
          <div>
            <CTAButton rectangled text="Escalate" onClick={() => setIsEscalateModalOpen(true)} invert />
            <CTAButton rectangled text="Initiate" onClick={() => setCurrentStep((prev) => prev + 1)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
