import { getDbdAccounts } from 'api';
import { DbdAccount, DbdAccountsResponse } from 'models';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

const PAGE_SIZE = 50;

export const useFetchDbdAccounts = () => {
  const query: UseInfiniteQueryResult<DbdAccountsResponse> = useInfiniteQuery(
    'getDbdAccounts',
    ({ pageParam = 1 }) => getDbdAccounts(PAGE_SIZE, (pageParam - 1) * PAGE_SIZE),
    {
      keepPreviousData: true,
      getNextPageParam: (_, lastPage) => lastPage.length + 1,
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const accounts = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.reduce((acc, page) => [...acc, ...page.results], [] as DbdAccount[]);
  }, [query.data]);

  return { ...query, accounts };
};

export default useFetchDbdAccounts;
