import { IEscalationTasksResponseData } from 'models';

const escalationTypeToLabel = (type: IEscalationTasksResponseData['taskType']) => {
  switch (type) {
    case 'authRequestHandover':
      return 'Auth Request Handover';
    case 'missingInfo':
      return 'Missing Info';
    case 'l2Products':
      return 'L2 Products';
    case 'unknownLogic':
      return 'Unknown Logic';
    case 'other':
      return 'Other';
    default:
      return '';
  }
};

export default escalationTypeToLabel;
