import { useFetchPreviewDropboxFile, useFetchSubmittedTasks } from 'api/hooks';
import { ColumnConfig, InputTextField, Table, TableInfiniteScrollWrapper } from 'components';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect } from 'react';
import formatFullName from 'utils/formatFullName';
import InitiateAuthorizationOrderContext from '../../context';
import styles from './SelectPendingRequests.module.scss';

const SelectPendingRequests = () => {
  const { currentFile, setAuthorizationOrder, setCurrentStep, form } = useContext(InitiateAuthorizationOrderContext);
  const { previewSrc } = useFetchPreviewDropboxFile(currentFile.path_display);

  const { watch, register } = form;

  const selectedAuthorizationOrderIndex = watch('primaryDetails.customerId') || '';

  const {
    tasks,
    query: { isLoading },
    setFilterByCustomerNameQuery,
  } = useFetchSubmittedTasks('pending');

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Customer ID',
      renderColumn: (index) => tasks[index]?.primaryDetails.customerId,
    },
    {
      heading: 'Customer Name',
      renderColumn: (index) =>
        formatFullName(tasks[index]?.primaryDetails.firstName, undefined, tasks[index].primaryDetails.lastName),
    },
    {
      heading: 'Payer',
      renderColumn: (index) => tasks[index]?.prescriptionDetails.primaryPayer,
    },
    {
      heading: 'Last Updated',
      renderColumn: (index) => dayjs(new Date(tasks[index]?.updatedAt)).format('MM/DD/YYYY'),
    },
  ];

  const customerNameFilterQuery = watch('customerNameFilter');

  useEffect(() => {
    setFilterByCustomerNameQuery(customerNameFilterQuery);
  }, [setFilterByCustomerNameQuery, customerNameFilterQuery]);

  return (
    <div className={styles.wrapper}>
      <p>{currentFile?.name}</p>
      <div className={styles.container}>
        <iframe title="preview" src={previewSrc} />
        <div className={styles.formContainer}>
          <InputTextField
            className={styles.filterInputField}
            {...register('customerNameFilter')}
            placeholder="Search Customer Name"
            noShadow
          />
          <Table
            className={styles.table}
            columnsConfig={tableColumnsConfig}
            getRowKey={useCallback((index) => tasks[index]?.primaryDetails.customerId, [tasks])}
            itemCount={tasks.length}
            rowHeight={80}
            loadingRows={isLoading}
            columnWidth={150}
            selectedRowKeys={[selectedAuthorizationOrderIndex]}
            onRowClick={(id) => {
              const index = [...tasks].findIndex((task) => task?.primaryDetails.customerId === id);
              setAuthorizationOrder(tasks[index]);
              setCurrentStep((prev) => prev + 1);
            }}
            renderFixedSizeList={(ListComponent) => (
              <TableInfiniteScrollWrapper
                hasNextPage={false}
                items={tasks}
                isNextPageLoading={false}
                loadNextPage={() => null}
              >
                {({ onItemsRendered, ref, itemCount, renderRow }) => (
                  <ListComponent
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    itemCount={itemCount}
                    renderRow={renderRow}
                  />
                )}
              </TableInfiniteScrollWrapper>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectPendingRequests;
