import { useFetchEmailFaxDocuments, useFetchEscalationById } from 'api/hooks';
import { Stepper } from 'components';
import useSearchParams from 'hooks/useSearchParams';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { ClipLoader } from 'react-spinners';
import InitiateAuthorizationEfaxOrderContext from './context';
import Escalate from './steps/Escalate/Escalate';
import OrderCreated from './steps/OrderCreated/OrderCreated';
import SelectPendingRequests from './steps/SelectPendingRequests/SelectPendingRequests';
import styles from './InitiateAuthorizationEfaxOrder.module.scss';

const InitiateAuthorizationEfaxOrder = () => {
  const {
    params: { id },
  } = useRouteMatch() as { params: { id: string } };
  const { results, isLoading } = useFetchEmailFaxDocuments('authorizations');
  const query = useSearchParams();
  const escalationId = query.get('escalationId');
  const [currentStep, setCurrentStep] = useState(0);
  const [authorizationOrder, setAuthorizationOrder] = useState(null);
  const [isEscalateModalOpen, setIsEscalateModalOpen] = useState(false);
  const form = useForm();
  const history = useHistory();

  const {
    details: escalationDetails,
    query: { isLoading: isLoadingEscalationDetails },
  } = useFetchEscalationById(escalationId || '');

  const currentFile = results.find((file) => file.id === id);

  useEffect(() => {
    if (escalationId && escalationDetails?.formDetails) {
      form.reset(escalationDetails.formDetails);
      setAuthorizationOrder(escalationDetails.formDetails.primaryDetails);
    }
  }, [escalationDetails, escalationDetails?.formDetails, escalationId, form]);

  if ((escalationId && isLoadingEscalationDetails) || isLoading) {
    <div className={styles.loadingContainer}>
      <ClipLoader size={48} color="#06bbc9" />
    </div>;
  }

  if (!currentFile) {
    alert('Attached file not found');
    history.push('/task-manager/my-tasks');
    return <></>;
  }
  const steps = [<SelectPendingRequests key={0} />, <OrderCreated key={1} />];

  return (
    <>
      <Stepper
        currentStep={currentStep}
        steps={['Select Pending Authorizations', 'Order Created']}
        onStepClick={(step) => setCurrentStep(step)}
      />
      <InitiateAuthorizationEfaxOrderContext.Provider
        value={{
          currentStep,
          setCurrentStep,
          form,
          currentFile,
          authorizationOrder,
          setAuthorizationOrder,
          isEscalateModalOpen,
          setIsEscalateModalOpen,
        }}
      >
        {steps[currentStep]}
        {isEscalateModalOpen && <Escalate />}
      </InitiateAuthorizationEfaxOrderContext.Provider>
    </>
  );
};

export default InitiateAuthorizationEfaxOrder;
