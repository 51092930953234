import React, { memo, ReactNode, Ref } from 'react';
import { ListOnItemsRenderedProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { OverrideRowRenderer } from '../Table/Table';
import './TableInfiniteScrollWrapper.scss';

type OnItemsRendered = (props: ListOnItemsRenderedProps) => any;

export interface ChildrenProps {
  onItemsRendered: OnItemsRendered;
  ref: Ref<any>;
  itemCount: number;
  renderRow: OverrideRowRenderer;
}

interface Props<T = unknown> {
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage: boolean;
  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading: boolean;
  // Array of items loaded so far.
  items: T[];
  // Callback function responsible for loading the next page of items.
  loadNextPage: () => any;
  // The list component
  children: (childrenProps: ChildrenProps) => ReactNode;
  // Minimum number of rows to be loaded at a time; defaults to 10. This property can be used to batch requests to reduce HTTP requests.
  minimumBatchSize?: number;
  // 	Threshold at which to pre-fetch data; defaults to 15. A threshold of 15 means that data will start loading when a user scrolls within 15 rows.
  threshold?: number;
}

export const TableInfiniteScrollWrapper = memo<Props>(
  ({ hasNextPage, isNextPageLoading, items, loadNextPage, children, minimumBatchSize, threshold }) => {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => Promise.resolve() : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index: number) => index < itemCount && !!items[index];

    // Render an item or a loading indicator.
    const renderRow: OverrideRowRenderer = ({ index, renderDefault }) => {
      if (!isItemLoaded(index))
        return (
          <button
            className="TableInfiniteScrollWrapper-loadMoreButton"
            type="button"
            onClick={loadMoreItems}
            disabled={isNextPageLoading}
          >
            {isNextPageLoading ? 'Loading...' : 'Load more'}
          </button>
        );
      return renderDefault();
    };

    return (
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        minimumBatchSize={minimumBatchSize}
        threshold={threshold}
      >
        {({ onItemsRendered, ref }) => children({ onItemsRendered, ref, itemCount, renderRow })}
      </InfiniteLoader>
    );
  },
);

TableInfiniteScrollWrapper.displayName = 'TableInfiniteScrollWrapper';

export default TableInfiniteScrollWrapper;
