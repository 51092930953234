import { submitTask } from 'api';
import { useMutation, UseMutationResult } from 'react-query';
import { ICreateNewPatientErrorResponse, ICreateNewPatientSuccessResponse } from 'models';

interface Props {
  onSuccess?: (res: ICreateNewPatientSuccessResponse) => void;
  onError?: (err: ICreateNewPatientErrorResponse) => void;
}

export const usePostCreateSubmitTask = ({
  onSuccess,
  onError,
}: Props): UseMutationResult<ICreateNewPatientSuccessResponse, ICreateNewPatientErrorResponse, any> => {
  const mutation = useMutation((props: any) => submitTask(props), {
    onSuccess: (res) => {
      if (res.status === 'ok') {
        if (onSuccess) onSuccess(res);
        return;
      }

      alert(res.message);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      }

      alert(err.response.message);
    },
  });

  return mutation;
};

export default usePostCreateSubmitTask;
