import { useFetchEmailFaxDocuments, useFetchEscalationById } from 'api/hooks';
import { Stepper } from 'components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import { ClipLoader } from 'react-spinners';
import useSearchParams from 'hooks/useSearchParams';
import InitiatePrescriptionEfaxOrderContext from './context';
import Authorization from './steps/Authorization/Authorization';
import Escalate from './steps/Escalate/Escalate';
import SaveDraft from './steps/SaveDraft/SaveDraft';
import Overview from './steps/Overview/Overview';
import PrescriptionDetails from './steps/PrescriptionDetails/PrescriptionDetails';
import PrimaryDetails from './steps/PrimaryDetails/PrimaryDetails';
import styles from './InitiatePrescriptionEfaxOrder.module.scss';

const InitiatePrescriptionEfaxOrder = () => {
  const {
    params: { id },
  } = useRouteMatch() as { params: { id: string } };
  const query = useSearchParams();
  const escalationId = query.get('escalationId');

  const {
    details: escalationDetails,
    query: { isLoading: isLoadingEscalationDetails },
  } = useFetchEscalationById(escalationId || '');

  const { results, isLoading } = useFetchEmailFaxDocuments('prescriptions');
  const [currentStep, setCurrentStep] = useState(0);
  const [isEscalateModalOpen, setIsEscalateModalOpen] = useState(false);
  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);
  const form = useForm();
  const history = useHistory();
  const currentFile = results.find((file) => file.id === id);

  const steps = [
    <PrimaryDetails key={0} />,
    <PrescriptionDetails key={1} />,
    <Authorization key={2} />,
    <Overview key={3} />,
  ];

  useEffect(() => {
    if (escalationId && escalationDetails?.formDetails) {
      form.reset(escalationDetails.formDetails);
    }
  }, [escalationDetails, escalationDetails?.formDetails, escalationId, form]);

  if ((escalationId && isLoadingEscalationDetails) || isLoading) {
    <div className={styles.loadingContainer}>
      <ClipLoader size={48} color="#06bbc9" />
    </div>;
  }

  if (!currentFile) {
    alert('Attached file not found');
    history.push('/task-manager/my-tasks');
    return <></>;
  }

  return (
    <>
      <Stepper
        currentStep={currentStep}
        steps={['Primary details', 'Prescription details', 'Authorization', 'Overview']}
        onStepClick={(step) => setCurrentStep(step)}
      />
      <InitiatePrescriptionEfaxOrderContext.Provider
        value={{
          currentStep,
          setCurrentStep,
          form,
          currentFile,
          isEscalateModalOpen,
          setIsEscalateModalOpen,
          isSaveDraftModalOpen,
          setIsSaveDraftModalOpen,
        }}
      >
        {steps[currentStep]}
        {isEscalateModalOpen && <Escalate />}
        {isSaveDraftModalOpen && <SaveDraft />}
      </InitiatePrescriptionEfaxOrderContext.Provider>
    </>
  );
};

export default InitiatePrescriptionEfaxOrder;
