import { format } from 'date-fns';

export const useDatePicker = (form: { watch: any; setValue: any }) => {
  const { watch, setValue } = form;

  const handleSetDate = (fieldName: string) => {
    const value = watch(fieldName);
    return value ? new Date(value) : null;
  };

  const handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const { value } = event.target;
    setValue(fieldName, value);
  };

  const handleDateChange = (date: Date, fieldName: string) => {
    setValue(fieldName, format(date, 'MM/dd/yyyy'));
  };

  const handleOnDateClickOutside = (date: Date | null, fieldName: string) => {
    if (date) {
      const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
      setValue(fieldName, format(dtDateOnly, 'MM/dd/yyyy'));
    } else setValue(fieldName, null);
  };

  return { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside };
};

export default useDatePicker;
