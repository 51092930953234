import { useFetchEscalationById, usePostCreateEscalationThread, usePutMyEscalationTask } from 'api/hooks';
import { IEscalationTasksResponseData } from 'models';
import { useHistory, useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { CTAButton, InputTextField } from 'components';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import styles from './styles.module.scss';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const RespondEscalation = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const {
    details,
    query: { isLoading, isError, refetch: refetchEscalationById },
  } = useFetchEscalationById(id);
  const { mutate: updateMyEscalationTask } = usePutMyEscalationTask({
    id,
    onSuccess: (res) => {
      if (res.status === 'ok') {
        refetchEscalationById();
      }
    },
  });
  const { register, getValues, resetField } = useForm();
  const { mutate: sendMessage, isLoading: isSendingMessage } = usePostCreateEscalationThread({ id });

  const handleSendMessage = useCallback(() => {
    const message = getValues('message');
    sendMessage({ message });
    updateMyEscalationTask({ notes: message });
    resetField('message');
  }, [getValues, resetField, sendMessage, updateMyEscalationTask]);

  if (isLoading || !details?.createdAt) {
    return (
      <div className={styles.loadingContainer}>
        <ClipLoader size={48} color="#06bbc9" />
      </div>
    );
  }

  if (isError) {
    history.push('/task-manager/my-tasks');
    return <></>;
  }

  const { createdBy, createdAt, notes, thread } = details as IEscalationTasksResponseData;

  return (
    <div className={styles.container}>
      <div className={styles.threadListContainer}>
        <div className={styles.threadContainer}>
          <div className={styles.authorDetails}>
            <div className={styles.author}>{createdBy}</div>
            <div className={styles.date}>{timeAgo.format(new Date(createdAt), 'twitter')}</div>
          </div>
          <div className={styles.message}>{notes}</div>
        </div>

        {thread.map(({ message, createdBy: threadItemCreatedBy, createdAt: threadItemCreatedAt }) => (
          <div key={threadItemCreatedAt.toString()} className={styles.threadContainer}>
            <div className={styles.authorDetails}>
              <div className={styles.author}>{threadItemCreatedBy}</div>
              <div className={styles.date}>{timeAgo.format(new Date(threadItemCreatedAt), 'twitter')}</div>
            </div>
            <div className={styles.message}>{message}</div>
          </div>
        ))}
      </div>

      <div className={styles.inputContainer}>
        <InputTextField {...register('message')} placeholder="Add a note..." forceHideLabel type="textarea" noShadow />
        <CTAButton
          rectangled
          className={styles.ctaButton}
          text="Send"
          onClick={handleSendMessage}
          isLoading={isSendingMessage}
        />
      </div>
    </div>
  );
};

export default RespondEscalation;
