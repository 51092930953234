import { getS3ContentBuffer } from 'api';
import { useFetchDbdAccountDetails, useFetchDbdAccountInvoiceList } from 'api/hooks';
import { Card, ColumnConfig, Table } from 'components';
import dayjs from 'dayjs';
import { DashboardLayout } from 'layouts';
import { DbdAccount } from 'models';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import formatPrice from 'utils/formatPrice';
import './AccountDetails.scss';

interface Params {
  id: string;
}

const AccountDetails = () => {
  const { id } = useParams() as Params;
  const { mutate, data, isLoading: isLoadingDbdAccountInvoiceList } = useFetchDbdAccountInvoiceList({});
  const { account, isLoading } = useFetchDbdAccountDetails(id, {
    onSuccess: ({ results: { accountNo: dmeAccountNo } }) => {
      mutate({ dmeAccountNo });
    },
  });

  const handleDownloadStatement = useCallback(
    (s3Path: string, fileName: string, blobType: 'text/csv' | 'application/pdf') => {
      getS3ContentBuffer(s3Path).then((buffer) => {
        const blob = new Blob([buffer], { type: blobType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      });
    },
    [],
  );

  const invoiceList = [
    ...(data?.results?.invoiceList || []),
    {
      createdAt: '',
      invoiceNo: 'Total:',
      // Get total sum of 'charges' in invoiceList array of objects
      charges: data?.results?.invoiceList?.reduce((acc, invoice) => acc + invoice.charges, 0),
      payment: data?.results?.invoiceList?.reduce((acc, invoice) => acc + invoice.payment, 0),
      amountDue: data?.results?.invoiceList?.reduce((acc, invoice) => acc + invoice.amountDue, 0),
      pdfPath: '',
      pdfName: '',
      updatedAt: '',
    },
  ];

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Invoice Date',
      renderColumn: (index) => {
        if (invoiceList[index].createdAt === '') return <></>;
        return dayjs(invoiceList[index].createdAt).format('DD/MM/YYYY');
      },
    },
    {
      heading: 'Invoice No',
      renderColumn: (index) => invoiceList[index].invoiceNo,
      flex: '0 0 350px',
    },
    {
      heading: 'Charge',
      renderColumn: (index) => formatPrice(Number(invoiceList[index].charges)),
    },
    {
      heading: 'Payment',
      renderColumn: (index) => formatPrice(Number(invoiceList[index].payment)),
    },
    {
      heading: 'Balance',
      renderColumn: (index) => formatPrice(Number(invoiceList[index].amountDue)),
    },
    {
      heading: 'Action',
      renderColumn: (index) => {
        if (invoiceList[index].pdfPath === '' || invoiceList[index].pdfName === '') return <></>;
        return (
          <img
            className="Invoices-table-download-cell"
            onClick={() => {
              handleDownloadStatement(invoiceList[index].pdfPath, invoiceList[index].pdfName, 'application/pdf');
            }}
            src="/assets/icons/download.svg"
            alt="download"
          />
        );
      },
    },
    {
      heading: 'Status updated',
      renderColumn: (index) => {
        if (invoiceList[index].updatedAt === '') return <></>;
        return dayjs(invoiceList[index].updatedAt).format('DD/MM/YYYY');
      },
    },
  ];

  return (
    <DashboardLayout title="Account Details">
      <Card isLoading={isLoading} className="AccountDetails-container">
        {Object.keys(account).map((key) => (
          <div key={key} className="AccountDetails-information">
            <span className="AccountDetails-information-fieldName">{key}</span>
            <span className="AccountDetails-information-fieldValue">{account[key as keyof DbdAccount]}</span>
          </div>
        ))}
        <div className="AccountDetails-tables">
          <Table
            loadingRows={isLoadingDbdAccountInvoiceList}
            columnsConfig={tableColumnsConfig}
            rowHeight={60}
            itemCount={invoiceList.length}
            getRowKey={useCallback((index) => index.toString(), [])}
          />
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default AccountDetails;
