import classnames from 'classnames';
import React, { forwardRef, memo, useState, useCallback, ReactNode } from 'react';
import './InputDropdownField.scss';

interface Item {
  label: string;
  value: string;
}

interface InputDropdownFieldProps {
  defaultValue?: string;
  value?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  bottomClassName?: string;
  label?: ReactNode;
  status?: 'active' | 'error' | 'inactive' | 'disabled';
  name: string;
  placeholder?: string;
  bottomLabel?: string;
  forceHideLabel?: boolean;
  forceHideBottomLabel?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  items: (string | Item)[];
  disabled?: boolean;
  noShadow?: boolean;
}

export const InputDropdownField = forwardRef(
  (
    {
      defaultValue,
      value,
      className = '',
      labelClassName = '',
      inputClassName = '',
      bottomClassName = '',
      label,
      name,
      status = 'active',
      placeholder,
      bottomLabel,
      forceHideLabel,
      forceHideBottomLabel,
      onChange,
      items,
      disabled,
      noShadow,
    }: InputDropdownFieldProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    // Use this state for uncontrolled form case
    const [hasValue, setHasValue] = useState(false);

    const handleChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
      (e) => {
        setHasValue(!!e.target.value);
        return onChange?.(e);
      },
      [onChange],
    );

    const firstItemLabel = typeof items[0] === 'string' ? items[0] : items[0]?.label;

    return (
      <div
        data-testid="InputDropdownField"
        className={classnames('InputDropdownField', className, {
          'mod-noShadow': noShadow,
        })}
      >
        {!forceHideLabel && <div className={`InputDropdownField-input-label  ${labelClassName}`}>{label}</div>}
        <select
          aria-label={(label as string) || undefined}
          defaultValue={defaultValue || placeholder ? '' : firstItemLabel}
          className={`${
            status === 'disabled'
              ? 'InputDropdownField-input-disabled'
              : status === 'error'
              ? 'InputDropdownField-input-error'
              : 'InputDropdownField-input'
          } ${inputClassName} ${!defaultValue && !hasValue ? 'InputDropdownField-input-placeholder' : ''}`}
          name={name}
          ref={ref}
          onChange={handleChange}
          value={value}
          disabled={disabled}
        >
          <option disabled value="">
            {placeholder}
          </option>
          <option value="">None</option>
          {items.map((x) => {
            const itemLabel = typeof x === 'string' ? x : x.label;
            const itemValue = typeof x === 'string' ? x : x.value;
            return (
              <option key={itemValue} value={itemValue}>
                {itemLabel}
              </option>
            );
          })}
        </select>
        {!forceHideBottomLabel && (
          <div
            className={`${
              status === 'error'
                ? 'InputDropdownField-input-label-bottom-error'
                : 'InputDropdownField-input-label-bottom'
            } ${bottomClassName}`}
          >
            {bottomLabel}
          </div>
        )}
      </div>
    );
  },
);

InputDropdownField.displayName = 'InputDropdownField';

export default memo(InputDropdownField);
