import { getPortalCheckSearchPatient } from 'api';
import dayjs from 'dayjs';
import useDebounceState from 'hooks/useDebounceState';
import { IPortalCheckSearchPatientResponseData, IPortalCheckSearchPatientSuccessResponse } from 'models';
import { useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import getNextPageParam from 'utils/getNextPageParam';
import getPreviousPageParam from 'utils/getPreviousPageParam';

export const useFetchPortalCheckSearchPatient = () => {
  const [debouncedSearchValue, setDebouncedSearchValue, searchValue, setSearchValue] = useDebounceState('', 500);
  const [firstNameValue, setFirstNameValue] = useState<string>();
  const [lastNameValue, setLastNameValue] = useState<string>();
  const [dateOfBirthValue, setDateOfBirthValue] = useState<Date | null>(null);

  const query: UseInfiniteQueryResult<IPortalCheckSearchPatientSuccessResponse> = useInfiniteQuery(
    ['useFetchPortalCheckSearchPatient', { debouncedSearchValue, firstNameValue, lastNameValue, dateOfBirthValue }],
    ({ pageParam = 0 }) => {
      // if dateofbirth or debouncedSearchValue changes then reset skip to 0
      return getPortalCheckSearchPatient({
        skip: pageParam,
        limit: 20,
        query: debouncedSearchValue,
        firstname: firstNameValue,
        lastname: lastNameValue,
        dateofbirth: dateOfBirthValue ? dayjs(dateOfBirthValue).format('YYYYMMDD') : undefined,
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam,
      getPreviousPageParam,
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

  const patients = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.reduce(
      (acc, page) => [...acc, ...page?.data],
      [] as IPortalCheckSearchPatientResponseData[],
    );
  }, [query.data]);

  const totalCount = useMemo(() => {
    if (!query.data) return 0;
    return query.data.pages[0].pagination.totalCount;
  }, [query.data]);

  return {
    query,
    patients,
    totalCount,
    firstNameValue,
    setFirstNameValue,
    lastNameValue,
    setLastNameValue,
    setDateOfBirthValue,
    dateOfBirthValue,
    debouncedSearchValue,
    setDebouncedSearchValue,
    searchValue,
    setSearchValue,
  };
};

export default useFetchPortalCheckSearchPatient;
