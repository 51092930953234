import { getSearchDbdAccounts } from 'api';
import useFetchDbdAccounts from 'api/hooks/useFetchDbdAccounts';
import { Card, ColumnConfig, CTAButton, InputTextField, Table, TableInfiniteScrollWrapper } from 'components';
import { DashboardLayout } from 'layouts';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import formatPrice from 'utils/formatPrice';
import './Accounts.scss';

const Accounts = () => {
  const { register } = useForm();
  const history = useHistory();
  const [isResettingUsers, setIsResettingUsers] = useState(false);
  const { accounts, fetchNextPage, refetch, hasNextPage, isLoading: isLoadingDbdAccounts } = useFetchDbdAccounts();
  const { mutate: searchDbdAccounts, data: searchResponse } = useMutation(getSearchDbdAccounts);

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'id',
      renderColumn: (index) => (searchResponse?.results || accounts)[index]._id,
    },
    {
      heading: 'Name',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].name,
    },
    {
      heading: 'Balance',
      renderColumn: (index) => formatPrice(Number((searchResponse?.results || accounts)[index].balance)),

      textAlign: 'center',
    },
    {
      heading: 'Status',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].status,

      textAlign: 'center',
    },
    {
      heading: 'Invoice Date',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].accountDate,
    },
    {
      heading: 'Branch',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].branch,
    },
    {
      heading: 'Account No',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].accountNo,
    },
    {
      heading: 'Account Date',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].accountDate,
    },
    {
      heading: 'Auto Pay',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].autoPay,
    },
    {
      heading: 'eCheck',
      renderColumn: (index) => (searchResponse?.results || accounts)[index].ccOrECheck,
    },
  ];

  const handleSearch = useCallback(
    (query) => {
      searchDbdAccounts(query);
    },
    [searchDbdAccounts],
  );

  const resetAccounts = useCallback(async () => {
    setIsResettingUsers(true);
    await refetch();
    setIsResettingUsers(false);
  }, [refetch]);

  const handleOnRowClick = (id: string) => {
    history.push(`/dme-billing-dashboard/accounts/${id}`);
  };

  return (
    <DashboardLayout title="Accounts">
      <Card className="Accounts" noPadding>
        <div className="Accounts-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search user..."
            type="text"
            className="Accounts-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch(e.currentTarget.value);
              }
            }}
          />
          <div className="Accounts-header-reset-container">
            <span className="Accounts-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="Accounts-header-reset-container-text">
              1&nbsp; - &nbsp;
              {accounts.length}
            </span>

            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={useCallback(() => resetAccounts(), [resetAccounts])}
              disabled={isResettingUsers}
              className="Accounts-header-reset-container-refresh-button"
            />
          </div>
        </div>
        <Table
          columnWidth={220}
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          itemCount={(searchResponse?.results || accounts).length}
          getRowKey={useCallback(
            (index) => ((searchResponse?.results || accounts) ?? [])[index]?._id,
            [accounts, searchResponse?.results],
          )}
          onRowClick={(index) => handleOnRowClick(index)}
          loadingRows={isLoadingDbdAccounts}
          renderFixedSizeList={(ListComponent) => (
            <TableInfiniteScrollWrapper
              hasNextPage={hasNextPage || false}
              isNextPageLoading={false}
              items={searchResponse?.results || accounts}
              loadNextPage={() => {
                setTimeout(() => {
                  fetchNextPage();
                }, 2000);
              }}
            >
              {({ onItemsRendered, ref }) => <ListComponent ref={ref} onItemsRendered={onItemsRendered} />}
            </TableInfiniteScrollWrapper>
          )}
        />
      </Card>
    </DashboardLayout>
  );
};

export default Accounts;
