import { useFetchPreviewDropboxFile, useFetchSearchProducts } from 'api/hooks';
import { CTAButton, DatePicker, InputDropdownField, InputTextField } from 'components';
import { useContext, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import camelCaseToText from 'utils/camelCaseToText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useDatePicker from 'api/hooks/useDatePicker';
import InputAutoCompleteField from 'components/InputAutoCompleteField/InputAutoCompleteField';
import InitiateAuthorizationOrderContext from '../../context';
import styles from './OrderCreated.module.scss';

const OrderCreated = () => {
  const { currentFile, form, authorizationOrder, setCurrentStep, setIsEscalateModalOpen } = useContext(
    InitiateAuthorizationOrderContext,
  );
  const { previewSrc } = useFetchPreviewDropboxFile(currentFile.path_display);
  const history = useHistory();
  const {
    setSearchValue: setSearchProductValue,
    products,
    setDebouncedSearchValue: setDebouncedSearchProductValue,
    query: { isLoading: isSearchProductLoading },
  } = useFetchSearchProducts();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker(form);
  const watchSearchProductQueries = Array(authorizationOrder.prescriptionDetails.products.length).map((i) =>
    watch(`prescriptionDetails.products.[${i}].hcpcs`),
  );

  useEffect(() => {
    watchSearchProductQueries.forEach((query) => {
      if (query === '') setSearchProductValue('');
      setDebouncedSearchProductValue(query);
    });
  }, [setDebouncedSearchProductValue, setSearchProductValue, watchSearchProductQueries]);

  useEffect(() => {
    setValue('prescriptionDetails.products', authorizationOrder.prescriptionDetails.products);
  }, [authorizationOrder.prescriptionDetails.products, setValue]);

  const authorizationStatus = watch('orderCreated.authorizationStatus');

  useEffect(() => {
    if (authorizationStatus?.toLowerCase() === 'declined') {
      setIsEscalateModalOpen(true);
    }
  }, [setIsEscalateModalOpen, authorizationStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.previewContainer}>
        <a className={`cta-button ${styles.iframeOverlay}`} rel="noreferrer" target="_blank" href={previewSrc}>
          <OpenInNewIcon />
        </a>
        <iframe title="preview" src={previewSrc} />
      </div>
      <div className={styles.formContainer}>
        <span className={styles.sectionTitle}>Order Details</span>
        {Array.from({ length: authorizationOrder.prescriptionDetails.products.length }).map((_, index) => (
          <Fragment key={(index + 1).toString()}>
            <div className={styles.splitInputContainer}>
              <InputDropdownField
                className={styles.inputField}
                noShadow
                items={['Completed', 'Pending', 'Declined']}
                label="Authorization Status"
                defaultValue={watch('orderCreated.authorizationStatus')}
                {...register('orderCreated.authorizationStatus', {
                  required: 'Required field',
                })}
              />
            </div>
            <hr />
            <div className={styles.splitInputContainer}>
              <InputAutoCompleteField
                className={styles.inputField}
                noShadow
                label="HCPCS"
                placeholder="Search HCPCS"
                items={products.map((data: any = []) => {
                  return {
                    label: `${data.hcpcs} - ${data.description}`,
                    value: data.hcpcs || '',
                  };
                })}
                isLoading={isSearchProductLoading}
                defaultValue={watch(`prescriptionDetails.products.[${index}].hcpcs`)}
                {...register(`prescriptionDetails.products.[${index}].hcpcs`)}
                onSelect={(value: string) => {
                  setValue(`prescriptionDetails.products.[${index}].hcpcs`, value);
                  const product = products.find((p) => p.hcpcs === value);
                  setValue(
                    `prescriptionDetails.products.[${index}].productCategory`,
                    `${product?.equipType} - ${product?.subType}`,
                  );
                  setValue(`prescriptionDetails.products.[${index}].productItem`, product?.description);
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  if (e.key === 'Enter') {
                    setSearchProductValue(e.currentTarget.value);
                  }
                }}
                // isLoading={isPhysicianSearchLoading}
                bottomLabel={errors.prescriptionDetails?.products?.[index]?.hcpcs?.message}
                status={errors.prescriptionDetails?.products?.[index]?.hcpcs ? 'error' : 'active'}
              />
            </div>
            <div className={styles.splitInputContainer}>
              <InputTextField
                className={styles.inputField}
                noShadow
                label="Product Category"
                placeholder="Product Category"
                status={errors.prescriptionDetails?.products?.[index]?.productCategory ? 'error' : 'active'}
                bottomLabel={errors.prescriptionDetails?.products?.[index]?.productCategory?.message}
                defaultValue={watch(`prescriptionDetails.products.[${index}].productCategory`)}
                {...register(`prescriptionDetails.products.[${index}].productCategory`, {
                  required: 'Required field',
                })}
              />
              <InputTextField
                className={styles.inputField}
                noShadow
                label="Product Item"
                placeholder="Product Item"
                defaultValue={watch(`prescriptionDetails.products.[${index}].productItem`)}
                {...register(`prescriptionDetails.products.[${index}].productItem`, {
                  required: 'Required field',
                })}
                status={errors.prescriptionDetails?.products?.[index]?.productItem ? 'error' : 'active'}
                bottomLabel={errors.prescriptionDetails?.products?.[index]?.productItem?.message}
              />
            </div>
            <div className={styles.splitInputContainer}>
              <InputTextField
                {...register(`prescriptionDetails.products.[${index}].productQuantity`, {
                  required: 'Required field',
                  validate: (value) => {
                    if (value < 1) {
                      return 'Quantity must be greater than 0';
                    }
                    return true;
                  },
                })}
                className={styles.inputField}
                label=""
                noShadow
                placeholder="Enter quantity"
                type="number"
                status={errors.prescriptionDetails?.products?.[index]?.productQuantity ? 'error' : 'active'}
                bottomLabel={errors.prescriptionDetails?.products?.[index]?.productQuantity?.message}
              />
              <InputDropdownField
                className={styles.inputField}
                noShadow
                placeholder="Unit of measure"
                items={['EA', 'CS', 'BX', 'PK', 'PR']}
                defaultValue={watch(`prescriptionDetails.products.[${index}].uom`)}
                {...register(`prescriptionDetails.products.[${index}].uom`, {
                  required: 'Required field',
                })}
                status={errors.prescriptionDetails?.products?.[index]?.uom ? 'error' : 'active'}
                bottomLabel={errors.prescriptionDetails?.products?.[index]?.uom?.message}
              />
            </div>
          </Fragment>
        ))}
        <div className={styles.splitInputContainer}>
          <InputTextField
            {...register(`orderCreated.totalOrderQuantity`)}
            className={styles.inputField}
            label="Total Quantity"
            noShadow
            type="number"
          />
          <InputTextField
            {...register(`orderCreated.totalProductQuantity`)}
            className={styles.inputField}
            label="Total Product Quantity"
            noShadow
            type="number"
          />
        </div>
        <div className={styles.splitInputContainer}>
          <InputTextField
            {...register(`orderCreated.authorizationNo`)}
            className={styles.inputField}
            label="Authorization #"
            noShadow
          />
        </div>
        <div className={styles.splitInputContainer}>
          <DatePicker
            {...register('orderCreated.authorizationStartDate')}
            placeholder="Select date..."
            label="Authorization Start Date"
            maxDate={handleSetDate('orderCreated.authorizationEndDate') || undefined}
            date={handleSetDate('orderCreated.authorizationStartDate')}
            onTextChange={(e) => handleOnTextChange(e, 'orderCreated.authorizationStartDate')}
            onChange={(date: Date) => handleDateChange(date, 'orderCreated.authorizationStartDate')}
            onClickOutside={
              (date: Date | null) => handleOnDateClickOutside(date, 'orderCreated.authorizationStartDate')
              // eslint-disable-next-line react/jsx-curly-newline
            }
            forceHideBottomLabel
          />
          <DatePicker
            {...register('orderCreated.authorizationEndDate')}
            placeholder="Select date..."
            label="Authorization End Date"
            minDate={handleSetDate('orderCreated.authorizationStartDate') || undefined}
            date={handleSetDate('orderCreated.authorizationEndDate')}
            onTextChange={(e) => handleOnTextChange(e, 'orderCreated.authorizationEndDate')}
            onChange={(date: Date) => handleDateChange(date, 'orderCreated.authorizationEndDate')}
            onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'orderCreated.authorizationEndDate')}
            forceHideBottomLabel
          />
        </div>
        <span className={styles.sectionTitle}>Customer Details</span>
        <div className={styles.details}>
          {Object.keys(authorizationOrder.primaryDetails).map((key) => (
            <div className={styles.detailItem} key={key}>
              <span>{camelCaseToText(key)}</span>
              <span>{authorizationOrder.primaryDetails[key]}</span>
            </div>
          ))}
        </div>
        <div className={styles.ctaContainer}>
          <div>
            <CTAButton rectangled text="Back" onClick={() => setCurrentStep((prev) => prev - 1)} invert />
          </div>
          <div>
            <CTAButton invert type="button" text="Escalate" rectangled onClick={() => setIsEscalateModalOpen(true)} />
            <CTAButton type="button" text="Done" rectangled onClick={() => history.push('/task-manager/all-tasks')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCreated;
