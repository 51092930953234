import { CTAButton, DatePicker, InputAutoCompleteField, InputDropdownField, InputTextField } from 'components';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import PDFMerger from 'pdf-merger-js/browser';
import { useFetchPreviewDropboxFile, useFetchSearchProducts } from 'api/hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'MyTypes';
import formatDate from 'utils/formatDate';
import useFetchPhysician from 'api/hooks/useFetchPhysician';
import useFetchInsurancePayers from 'api/hooks/useFetchInsurancePayers';
import useDatePicker from 'api/hooks/useDatePicker';
import InitiateEmailFaxOrderContext from '../../../context';
import styles from './FaxCoverSection.module.scss';
import authFaxRequestDoc from '../../../../../../../../../assets/GENERAL_AUTH_FAX_REQUEST.pdf';

const FaxCoverSection = () => {
  const { form, currentFile } = useContext(InitiateEmailFaxOrderContext);
  const { previewSrc } = useFetchPreviewDropboxFile(currentFile.path_display);
  const { onPhysicianSearch, physicians, isPhysicianSearchLoading } = useFetchPhysician();
  const { insurancePayers } = useFetchInsurancePayers();
  const currentUserDetails = useSelector((state: RootState) => state.userDetails.currentUserDetails);
  const [productChange, setProductChange] = useState<number>(0);
  const {
    getValues,
    setValue,
    watch,
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = form;
  const { handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker(form);

  const {
    setSearchValue: setSearchProductValue,
    products,
    setDebouncedSearchValue: setDebouncedSearchProductValue,
    query: { isLoading: isSearchProductLoading },
  } = useFetchSearchProducts();
  const [docPath, setDocPath] = useState<string>('');
  const [productsCount, setProductsCount] = useState<number>(getValues('prescriptionDetails.products')?.length || 1);

  let defaultPhysician = watch('prescriptionDetails.physician');
  defaultPhysician = `${defaultPhysician?.split(' - ')[0]} - ${defaultPhysician?.split(' - ')[1]}`;
  const physician = watch('coverPDF.physician');
  const defaultDob = watch('primaryDetails.dateOfBirth');
  const dob = watch('coverPDF.dateOfBirth');
  const defaultRxDate = watch('prescriptionDetails.rxDate');
  const rxDate = watch('coverPDF.rxDate');
  const defaultProducts = watch('prescriptionDetails.products');

  const handleSetDate = (fieldName: string, defaultValue?: string) => {
    const value = watch(fieldName);
    return value ? new Date(value) : defaultValue ? new Date(defaultValue) : null;
  };

  useEffect(() => {
    onPhysicianSearch(physician || defaultPhysician);
  }, [defaultPhysician, physician, onPhysicianSearch]);

  useEffect(() => {
    setValue('coverPDF.authorizationPayer', watch('authorization.payer'));
    defaultProducts.forEach(
      (
        {
          hcpcs,
          productCategory,
          productItem,
          productQuantity,
          uom,
        }: { hcpcs: string; productCategory: string; productItem: string; productQuantity: string; uom: string },
        index: number,
      ) => {
        setValue(`coverPDF.hcpcs.[${index}].hcpcs`, hcpcs);
        setValue(`coverPDF.products.[${index}].productCategory`, productCategory);
        setValue(`coverPDF.products.[${index}].productItem`, productItem);
        setValue(`coverPDF.products.[${index}].productQuantity`, productQuantity);
        setValue(`coverPDF.products.[${index}].uom`, uom);
      },
    );
  }, [defaultProducts, watch, setValue]);

  const setPDFPhysicianValue = useCallback(
    (physicianString = '') => {
      const foundPhysician = physicians.find((p) => p[0] === physicianString.split(' - ')[0]);

      if (foundPhysician) {
        return `${foundPhysician[0]} - ${foundPhysician[3]}`;
      }

      return physician || defaultPhysician;
    },
    [physicians, physician, defaultPhysician],
  );

  const watchSearchProductQueries = Array(productsCount).map((i) => watch(`coverPDF.products.[${i}].hcpcs`));

  useEffect(() => {
    watchSearchProductQueries.forEach((query) => {
      if (query === '') setSearchProductValue('');
      setDebouncedSearchProductValue(query);
    });
  }, [setDebouncedSearchProductValue, setSearchProductValue, watchSearchProductQueries]);

  const generateDocument = useCallback(async () => {
    try {
      const combinedPDF = new PDFMerger();
      const coverDoc = await fetch(authFaxRequestDoc);
      const prescriptionEfaxDoc = await fetch(`${previewSrc}`);

      if (coverDoc?.url) {
        const coverDocArrayBuffer = await coverDoc.arrayBuffer();
        const coverPDF = await PDFDocument.load(coverDocArrayBuffer);
        const coverPDFForm = coverPDF.getForm();
        const productFileds: any[] = [];
        watch('coverPDF.products').forEach(
          (
            {
              hcpcs,
              productCategory,
              productItem,
              productQuantity,
              uom,
            }: { hcpcs: string; productCategory: string; productItem: string; productQuantity: string; uom: string },
            index: number,
          ) => {
            const number: string = index > 0 ? `-${index}` : '';
            productFileds.push(
              {
                fieldName: `ITEM${number}`, // Item
                value: `${productCategory} - ${productItem}`,
              },
              {
                fieldName: `QTY${number}`, // Quantity
                value: `${productQuantity || ''} ${uom || ''}`,
              },
              {
                fieldName: `HCPCS#${number}`, // HCPCS#
                value: hcpcs || defaultProducts[index].hcpcs,
              },
            );
          },
        );
        [
          { fieldName: 'Date', value: formatDate(new Date().toISOString()) }, // Date
          { fieldName: 'Text3', value: '' }, // Send to
          { fieldName: 'Text1', value: watch('coverPDF.authorizationPayer') }, // Company
          { fieldName: 'Text4', value: '' }, // Telephone Number
          { fieldName: 'Text5', value: watch('coverPDF.authorizationFax') }, // Fax number
          { fieldName: 'Text7', value: currentUserDetails?.username }, // From
          { fieldName: 'Member Name', value: `${watch('coverPDF.firstName')} ${watch('coverPDF.lastName')}` }, // Patient
          { fieldName: 'D.O.B', value: dob }, // DOB
          { fieldName: 'ID#', value: watch('coverPDF.customerId') }, // ID#
          { fieldName: 'Prescriber', value: setPDFPhysicianValue(physician || defaultPhysician) }, // Prescriber
          { fieldName: 'Date-1', value: rxDate }, // RX Date
          ...productFileds,
        ].forEach(({ fieldName, value }) => coverPDFForm.getTextField(fieldName).setText(value || ''));
        // coverPDFForm.getCheckBox('Check Box16').uncheck(); // PLEASE COMMENT
        const coverPDFBytes = await coverPDF.save();
        const coverPDFBlob = new Blob([coverPDFBytes], { type: 'application/pdf' });

        // console.log('sssss', setPDFPhysicianValue(physician || defaultPhysician));
        await combinedPDF.add(URL.createObjectURL(coverPDFBlob));

        // const fieldNames = coverPDFForm.getFields().map((field) => field.getName());
        // console.log('fieldNames', fieldNames);
      }

      if (prescriptionEfaxDoc?.url) {
        await combinedPDF.add(prescriptionEfaxDoc.url);
      }

      const combinedPDFBlob = await combinedPDF.saveAsBlob();
      setDocPath(URL.createObjectURL(combinedPDFBlob));
    } catch (error) {
      setDocPath('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUserDetails,
    watch,
    physician,
    defaultPhysician,
    dob,
    rxDate,
    setPDFPhysicianValue,
    previewSrc,
    productChange,
  ]);

  useEffect(() => {
    generateDocument();
  }, [generateDocument]);

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.portalSubmissionInstructionsContainer}>
        <div>
          <span className={styles.sectionTitle}>FAX Preview</span>
          <div className={styles.previewContainer}>
            <iframe title="preview" src={docPath} />
          </div>
        </div>
        <div>
          <span className={styles.sectionTitle}>Cover Letter</span>
          <form className={styles.formContainer} onChange={generateDocument}>
            <div className={styles.splitInputContainer}>
              <InputTextField
                noShadow
                label="First Name"
                className={styles.inputField}
                defaultValue={watch('primaryDetails.firstName')}
                {...register('coverPDF.firstName', {
                  required: 'Required field',
                })}
              />
              <InputTextField
                noShadow
                label="Last Name"
                className={styles.inputField}
                defaultValue={watch('primaryDetails.lastName')}
                {...register('coverPDF.lastName', {
                  required: 'Required field',
                })}
              />
            </div>
            <InputTextField
              noShadow
              label="Customer ID"
              className={styles.inputField}
              type="text"
              defaultValue={watch('primaryDetails.customerId')}
              {...register('coverPDF.customerId', {
                required: 'Required field',
              })}
            />
            <DatePicker
              {...register('coverPDF.dateOfBirth')}
              className={styles.inputField}
              placeholder="Select date..."
              label="Date of Birth"
              date={handleSetDate('coverPDF.dateOfBirth', defaultDob)}
              onTextChange={(e) => handleOnTextChange(e, 'coverPDF.dateOfBirth')}
              onChange={(date: Date) => handleDateChange(date, 'coverPDF.dateOfBirth')}
              onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'coverPDF.dateOfBirth')}
              forceHideBottomLabel
              noShadow
            />
            <hr />
            <InputAutoCompleteField
              className={styles.inputField}
              label="Physician"
              noShadow
              placeholder="Search physician"
              defaultValue={defaultPhysician}
              items={physicians.map((data: any = []) => {
                return {
                  label: `${data[0]} - ${data[3]} - ${data[1]} - ${data[4]}`,
                  value: `${data[0]} - ${data[3]}` || '',
                };
              })}
              {...register('coverPDF.physician')}
              onSelect={(value: string) => {
                if (errors.coverPDF?.physician) {
                  clearErrors('coverPDF.physician');
                }
                setValue('coverPDF.physician', value);
              }}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (e.key === 'Enter') {
                  onPhysicianSearch(e.currentTarget.value);
                }
              }}
              isLoading={isPhysicianSearchLoading}
            />
            <DatePicker
              {...register('coverPDF.rxDate')}
              className={styles.inputField}
              placeholder="Select date..."
              label="RX Date"
              date={handleSetDate('coverPDF.rxDate', defaultRxDate)}
              onTextChange={(e) => handleOnTextChange(e, 'coverPDF.rxDate')}
              onChange={(date: Date) => handleDateChange(date, 'coverPDF.rxDate')}
              onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'coverPDF.rxDate')}
              forceHideBottomLabel
              noShadow
            />
            <InputDropdownField
              defaultValue={watch('authorization.payer')}
              {...register('coverPDF.authorizationPayer', {
                required: 'Required field',
              })}
              className={styles.inputField}
              label="Authorization Payer"
              noShadow
              placeholder="Select an authorization payer"
              items={Array.from(new Set(insurancePayers.map(({ payerName }) => payerName)))}
            />
            <InputTextField
              defaultValue={(watch('authorization.authorizationMethodDetails') || '').split(' or ')[0]}
              {...register('coverPDF.authorizationFax', {
                required: 'Required field',
              })}
              className={styles.inputField}
              label="Authorization  Fax Number"
              noShadow
              placeholder="Authorization payer fax number"
            />
            <hr />
            {Array.from({ length: productsCount }).map((_, index) => (
              <Fragment key={(index + 1).toString()}>
                <div className="InputDropdownField-input-label" style={{ paddingBottom: '0' }}>
                  {`Product ${index + 1}`}
                </div>
                <div className={styles.splitInputContainer}>
                  <InputAutoCompleteField
                    className={styles.inputField}
                    noShadow
                    placeholder="Search HCPCS"
                    defaultValue={defaultProducts[index].hcpcs}
                    items={products.map((data: any = []) => {
                      return {
                        label: `${data.hcpcs} - ${data.description}`,
                        value: data.hcpcs || '',
                      };
                    })}
                    isLoading={isSearchProductLoading}
                    {...register(`coverPDF.products.[${index}].hcpcs`)}
                    onSelect={(value: string) => {
                      if (errors.prescriptionDetails?.products?.[index]?.hcpcs) {
                        clearErrors(`coverPDF.products.[${index}].hcpcs`);
                      }
                      setValue(`coverPDF.products.[${index}].hcpcs`, value);
                      const product = products.find((p) => p.hcpcs === value);
                      setValue(
                        `coverPDF.products.[${index}].productCategory`,
                        `${product?.equipType} - ${product?.subType}`,
                      );
                      setValue(`coverPDF.products.[${index}].productItem`, product?.description);

                      setProductChange((prev) => prev + 1);
                    }}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      if (e.key === 'Enter') {
                        setSearchProductValue(e.currentTarget.value);
                      }
                    }}
                    // isLoading={isPhysicianSearchLoading}
                    bottomLabel={errors.prescriptionDetails?.products?.[index]?.hcpcs?.message}
                    status={errors.prescriptionDetails?.products?.[index]?.hcpcs ? 'error' : 'active'}
                  />
                </div>
                <div className={styles.splitInputContainer}>
                  <InputTextField
                    className={styles.inputField}
                    noShadow
                    placeholder="Product Category"
                    defaultValue={watch(`prescriptionDetails.products.[${index}].productCategory`)}
                    {...register(`coverPDF.products.[${index}].productCategory`, {
                      required: 'Required field',
                    })}
                  />
                  <InputTextField
                    className={styles.inputField}
                    noShadow
                    placeholder="Product Item"
                    defaultValue={watch(`prescriptionDetails.products.[${index}].productItem`)}
                    {...register(`coverPDF.products.[${index}].productItem`, {
                      required: 'Required field',
                    })}
                  />
                </div>
                <div className={styles.splitInputContainer}>
                  <InputTextField
                    {...register(`coverPDF.products.[${index}].productQuantity`, {
                      required: 'Required field',
                      validate: (value) => {
                        if (value < 1) {
                          return 'Quantity must be greater than 0';
                        }
                        return true;
                      },
                    })}
                    className={styles.inputField}
                    label=""
                    noShadow
                    placeholder="Enter quantity"
                    type="number"
                  />
                  <InputDropdownField
                    className={styles.inputField}
                    noShadow
                    placeholder="Unit of measure"
                    items={['EA', 'CS', 'BX', 'PK', 'PR']}
                    defaultValue={watch(`prescriptionDetails.products.[${index}].uom`)}
                    {...register(`coverPDF.products.[${index}].uom`, {
                      required: 'Required field',
                    })}
                  />
                </div>
              </Fragment>
            ))}

            <CTAButton
              className={styles.addAnotherProductCtaButton}
              text="Add another product"
              rectangled
              onClick={() => {
                handleSubmit(() => {
                  setProductsCount((prev) => prev + 1);
                })();
              }}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FaxCoverSection;
