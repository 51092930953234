import dayjs from 'dayjs';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import ReactCalendar, { CalendarTileProperties } from 'react-calendar';
import './DatePicker.scss';
import { InputTextField, InputTextFieldProps } from '../InputTextField/InputTextField';

interface Props extends Partial<Omit<InputTextFieldProps, 'onChange'>> {
  name: string;
  minDate?: Date;
  maxDate?: Date;
  date: Date | null;
  enabledDates?: Date[];
  onTextChange?: (text: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (date: Date) => void;
  onClickOutside?: (date: Date | null) => void;
  /** Default 'right' */
  calendarAlign?: 'left' | 'right';
  defaultView?: 'month' | 'year' | 'decade' | 'century';
}

export const DatePicker = forwardRef(
  (
    {
      name,
      minDate,
      maxDate,
      date,
      enabledDates,
      onTextChange,
      onChange,
      onClickOutside,
      calendarAlign = 'right',
      defaultView,
      ...restProps
    }: Props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    const [openCalendar, setOpenCalendar] = useState(false);

    const isValid = (value: Date | null) => {
      return value?.toString() !== 'Invalid Date';
    };

    const removeTimeZone = (value: Date) => {
      return new Date(value.valueOf() + value.getTimezoneOffset() * 60 * 1000);
    };

    const disableTile = useCallback(
      (props: CalendarTileProperties) => {
        const { date: tileDate, view } = props;
        if (enabledDates && view === 'month') {
          return !enabledDates.some(
            (enabledDate) =>
              tileDate.getFullYear() === enabledDate.getFullYear() &&
              tileDate.getMonth() === enabledDate.getMonth() &&
              tileDate.getDate() === enabledDate.getDate(),
          );
        }
        return false;
      },
      [enabledDates],
    );

    const handleOnChange = useCallback(
      (newDate: Date) => {
        onChange(newDate);
        setOpenCalendar(false);
      },
      [onChange],
    );

    const handleOnBlur = useCallback(() => {
      setOpenCalendar(false);

      const isInLimit = (value: Date | null) => {
        if (value !== null) {
          if (minDate) {
            return value >= removeTimeZone(minDate);
          }
          if (maxDate) {
            return value <= removeTimeZone(maxDate);
          }
        }

        return true;
      };

      onClickOutside?.(isValid(date) && isInLimit(date) ? date : null);
    }, [onClickOutside, date, minDate, maxDate]);

    return (
      <div className={`DatePicker ${calendarAlign === 'left' ? 'mod-align-start' : ''}`}>
        <div style={{ width: '100%' }} onBlur={() => handleOnBlur()}>
          <InputTextField
            ref={ref}
            type="text"
            value={date && isValid(date) ? dayjs(date).format('MM/DD/YYYY') : ''}
            name={name}
            readOnly
            onChange={onTextChange}
            {...restProps}
          />
        </div>
        <div className="Calendar-button" onClick={() => setOpenCalendar(!openCalendar)} />
        {openCalendar && (
          <div className="DatePicker-calendar">
            <ReactCalendar
              minDate={minDate ? removeTimeZone(minDate) : undefined}
              maxDate={maxDate ? removeTimeZone(maxDate) : undefined}
              value={date && isValid(date) ? removeTimeZone(date) : null}
              onChange={handleOnChange}
              className="DatePicker"
              tileDisabled={disableTile}
              defaultView={defaultView}
            />
          </div>
        )}
      </div>
    );
  },
);

DatePicker.displayName = 'DatePicker';

export default memo(DatePicker);
