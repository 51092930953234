import { IPaginatedResponseBody } from 'models';

const getPreviousPageParam = ({
  pagination: { skip, limit },
}: {
  pagination: IPaginatedResponseBody<undefined>['pagination'];
}): number | null => {
  if (skip === 0) {
    return null;
  }

  const previousParam = skip - limit;
  return previousParam;
};

export default getPreviousPageParam;
