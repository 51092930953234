import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import { MenuItem } from '../../models';
import './MenuGrid.scss';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  menuItems: MenuItem[];
  loading?: boolean;
}

export const MenuGrid = memo<Props>(({ loading, menuItems }) => {
  return (
    <div className="MenuGrid">
      {!loading ? (
        menuItems.map((x) => (
          <Link
            key={x.title}
            to={x.path.startsWith('http') ? { pathname: x.path } : x.path}
            target={x.path.startsWith('http') ? '_blank' : undefined}
            className={classnames({
              'MenuGrid-container': true,
              'mod-disabled': x.disabled,
            })}
          >
            <div className="MenuGrid-container-content">
              <div
                className="MenuGrid-container-content-icon"
                style={{
                  mask: `url(${x.icon}) no-repeat center`,
                  WebkitMask: `url(${x.icon}) no-repeat center`,
                  maskSize: '100%',
                  WebkitMaskSize: '100%',
                  backgroundColor: x.iconColor,
                }}
              />
              <span className="MenuGrid-container-content-title">{x.title}</span>
              <span
                dangerouslySetInnerHTML={{ __html: x.description }}
                className="MenuGrid-container-content-description"
              />
            </div>
          </Link>
        ))
      ) : (
        <>
          {menuItems.map((x, index) => (
            <div style={{ backgroundColor: 'transparent' }} key={`${index + 1}`} className="MenuGrid-container">
              <Skeleton count={1} />
            </div>
          ))}
        </>
      )}
    </div>
  );
});

MenuGrid.displayName = 'MenuGrid';

export default MenuGrid;
