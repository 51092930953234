import { CTAButton } from 'components';
import CheckOrdersContext from 'pages/TaskManager/pages/MyTasks/context/CheckOrdersContext';
import React, { memo, useContext } from 'react';

interface Props {
  isNewOrders: boolean | null;
  setIsNewOrders: (value: boolean) => void;
}

const Header = memo(({ isNewOrders, setIsNewOrders }: Props) => {
  const {
    newOrderModalConfig: { payerDetails },
  } = useContext(CheckOrdersContext);

  return (
    <div className="MyTasks-CheckOrders-modal-new-orders-header">
      <span className="MyTasks-CheckOrders-modal-title">
        Are there new orders from
        <>
          <span className="MyTasks-CheckOrders-modal-title-highlight">{payerDetails?.payerName}</span>
        </>
        ?
      </span>
      <div className="MyTasks-CheckOrders-modal-new-orders-header-cta-container">
        <CTAButton invert={isNewOrders !== true} text="Yes" onClick={() => setIsNewOrders(true)} />
        <CTAButton invert={isNewOrders !== false} text="No" onClick={() => setIsNewOrders(false)} />
      </div>
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
