import { getDbdTotalInvoiceSummary } from 'api';
import { DbdTotalInvoiceSummaryResponse } from 'models';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

type Options = Omit<
  UseQueryOptions<DbdTotalInvoiceSummaryResponse, Error, DbdTotalInvoiceSummaryResponse, 'getDbdTotalInvoiceSummary'>,
  'queryKey' | 'queryFn'
>;

export const useFetchDbdTotalInvoiceSummary = (
  options?: Options,
): UseQueryResult<DbdTotalInvoiceSummaryResponse, Error> => {
  const query = useQuery('getDbdTotalInvoiceSummary', getDbdTotalInvoiceSummary, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

  return query;
};

export default useFetchDbdTotalInvoiceSummary;
