import { useFetchDbdTotalInvoiceSummary } from 'api/hooks';
import { Card, ColumnConfig, MenuGrid, Table } from 'components';
import { DashboardLayout } from 'layouts';
import { MenuItem } from 'models';
import React, { useCallback } from 'react';
import formatPrice from 'utils/formatPrice';
import './DmeBillingDashboard.scss';

const DmeBillingDashboard = () => {
  const { data, isLoading } = useFetchDbdTotalInvoiceSummary();

  const menuItems: MenuItem[] = [
    {
      path: '/dme-billing-dashboard/invoices',
      icon: '/assets/icons/users.svg',
      title: 'Invoices',
      description: 'Upload, generate billing invoices and accounts',
    },
    {
      path: '/dme-billing-dashboard/accounts',
      icon: '/assets/icons/calendar.svg',
      title: 'Accounts',
      description: 'View and manage accounts',
    },
    {
      path: '/dme-billing-dashboard/payment',
      icon: '/assets/icons/clock.svg',
      title: 'Payment Details',
      description: 'View and manage account payments',
      disabled: true,
    },
  ];

  const rows = [
    {
      category: 'AR total',
      value: data?.results.amountDue,
    },
  ];

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Category',
      renderColumn: (index) => <b>{rows[index].category}</b>,
    },
    {
      heading: 'Mannings Pharmacy',
      renderColumn: (index) => `${formatPrice(Number(rows[index].value))}`,
    },
  ];

  return (
    <DashboardLayout title="DME Billing Dashboard">
      <MenuGrid menuItems={menuItems} />
      <Card noPadding>
        <Table
          loadingRows={isLoading}
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          itemCount={rows.length}
          getRowKey={useCallback((index) => index.toString(), [])}
        />
      </Card>
    </DashboardLayout>
  );
};

export default DmeBillingDashboard;
