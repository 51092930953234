import { useFetchPreviewDropboxFile } from 'api/hooks';
import { CTAButton, DatePicker, InputTextField, SearchPatient } from 'components';
import { ChangeEvent, useCallback, useContext } from 'react';
import formatDate from 'utils/formatDate';
import formatUSPhoneNumber from 'utils/formatUSPhoneNumber';
import validatePhone from 'utils/validatePhone';
import useDatePicker from 'api/hooks/useDatePicker';
import InitiateEmailFaxOrderContext from '../../context';
import styles from './PrimaryDetails.module.scss';

const PrimaryDetails = () => {
  const { setCurrentStep, form, currentFile } = useContext(InitiateEmailFaxOrderContext);
  const { previewSrc } = useFetchPreviewDropboxFile(currentFile.path_display);
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    handleSubmit,
    watch,
  } = form;
  const { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker(form);
  const primaryDetails = watch('primaryDetails');

  const handleNext = useCallback(() => {
    setCurrentStep((prev) => prev + 1);
  }, [setCurrentStep]);
  return (
    <div className={styles.container}>
      <div className={styles.previewContainer}>
        <iframe title="preview" src={previewSrc} />
      </div>
      <div className={styles.formContainer}>
        <span className={styles.title}>Primary details</span>
        <hr />
        <SearchPatient
          handleSetPatient={({
            firstname,
            lastname,
            dateofbirth,
            address,
            address2,
            city,
            county,
            state,
            zip,
            customerid,
            phone,
          }) => {
            setValue('primaryDetails', {
              firstName: firstname,
              lastName: lastname,
              customerId: customerid,
              dateOfBirth: formatDate(dateofbirth),
              mobileNo: phone,
              address: [address, address2, city, county, state, zip].filter((x) => x).join(', '),
            });
          }}
        />

        {primaryDetails?.customerId && (
          <>
            <div className={styles.splitInputContainer}>
              <InputTextField
                noShadow
                label="First Name"
                className={styles.inputField}
                status={errors.primaryDetails?.firstName ? 'error' : 'active'}
                bottomLabel={errors.primaryDetails?.firstName?.message}
                {...register('primaryDetails.firstName', {
                  required: 'Required field',
                })}
              />
              <InputTextField
                noShadow
                label="Last Name"
                className={styles.inputField}
                status={errors.primaryDetails?.lastName ? 'error' : 'active'}
                bottomLabel={errors.primaryDetails?.lastName?.message}
                {...register('primaryDetails.lastName', {
                  required: 'Required field',
                })}
              />
            </div>
            <InputTextField
              disabled
              noShadow
              label="Customer ID"
              className={styles.inputField}
              type="number"
              status={errors.primaryDetails?.customerId ? 'error' : 'active'}
              bottomLabel={errors.primaryDetails?.customerId?.message}
              {...register('primaryDetails.customerId', {
                required: 'Required field',
              })}
            />

            <DatePicker
              {...register('primaryDetails.dateOfBirth')}
              className={styles.inputField}
              placeholder="Select date..."
              label="Date of Birth"
              name="date"
              date={handleSetDate('primaryDetails.dateOfBirth')}
              onTextChange={(e) => handleOnTextChange(e, 'primaryDetails.dateOfBirth')}
              onChange={(date: Date) => handleDateChange(date, 'primaryDetails.dateOfBirth')}
              onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'primaryDetails.dateOfBirth')}
              forceHideBottomLabel
              status={errors.primaryDetails?.dateOfBirth ? 'error' : 'active'}
              bottomLabel={errors.primaryDetails?.dateOfBirth?.message}
              noShadow
            />
            <InputTextField
              noShadow
              label="Address"
              className={styles.inputField}
              type="text"
              {...register('primaryDetails.address')}
            />
            <InputTextField
              {...register(`primaryDetails.mobileNo`, {
                validate:
                  watch('primaryDetails.mobileNo')?.length && watch('primaryDetails.mobileNo').length > 0
                    ? validatePhone
                    : undefined,
              })}
              noShadow
              className={styles.inputField}
              label="Phone"
              placeholder="(201) 555-5555"
              type="text"
              status={errors.primaryDetails?.mobileNo ? 'error' : 'active'}
              bottomLabel={errors.primaryDetails?.mobileNo?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (errors.primaryDetails?.mobileNo) {
                  clearErrors(`primaryDetails.mobileNo`);
                }
                setValue(`primaryDetails.mobileNo`, formatUSPhoneNumber(e.target.value));
              }}
              onBlur={(e) => {
                setValue(`primaryDetails.mobileNo`, formatUSPhoneNumber(e.target.value));
              }}
            />
            <div className={styles.ctaContainer}>
              <CTAButton onClick={handleSubmit(handleNext)} rectangled text="Next" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PrimaryDetails;
