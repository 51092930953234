import { useFetchPortalCheckSearchPatient } from 'api/hooks';
import { InputTextField, CTAButton, Table, ColumnConfig, TableInfiniteScrollWrapper } from 'components';
import dayjs from 'dayjs';
import CheckOrdersContext from 'pages/TaskManager/pages/MyTasks/context/CheckOrdersContext';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import formatFullName from 'utils/formatFullName';

interface Props {
  handleSetPatient: (patient: any) => void;
}

const SearchPatient = memo(({ handleSetPatient }: Props) => {
  const {
    newOrderModalConfig: { payerDetails, handleCreatePatient },
  } = useContext(CheckOrdersContext);
  const [selectedPatientTableIndex, setSelectedPatientIndex] = useState('');
  const [displayDobValue, setDisplayDobValue] = useState<string | null>(null);
  const { register, watch } = useForm();

  const {
    patients,
    setSearchValue,
    setDebouncedSearchValue,
    setDateOfBirthValue,
    query: { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage },
  } = useFetchPortalCheckSearchPatient();

  const watchSearchQuery = watch('patientSearch');

  useEffect(() => {
    if (watchSearchQuery === '') setSearchValue('');
    setDebouncedSearchValue(watchSearchQuery);
  }, [setDebouncedSearchValue, setSearchValue, watchSearchQuery]);

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Name',
      renderColumn: (index) =>
        formatFullName(patients[index].firstname, patients[index].middleinitial, patients[index].lastname),
    },
    {
      heading: 'Date of Birth',
      renderColumn: (index) => patients[index].dateofbirth,
    },
    {
      heading: 'Address',
      renderColumn: (index) => patients[index].address,
    },
    {
      heading: '',
      flex: '0 0 150px',
      renderColumn: (index) => (
        <CTAButton
          text="Select"
          onClick={() => {
            handleSetPatient(patients[index]);
            setSelectedPatientIndex(patients[index]._id);
          }}
        />
      ),
    },
  ];

  return (
    <div className="MyTasks-CheckOrders-modal-new-orders-search-patient">
      <span className="MyTasks-CheckOrders-modal-title">
        Add new orders from
        <>
          <span className="MyTasks-CheckOrders-modal-title-highlight">{payerDetails?.payerName}</span>
        </>
        ?
      </span>
      <div className="MyTasks-CheckOrders-modal-new-orders-search-patient-container">
        <div className="MyTasks-CheckOrders-modal-new-orders-search-patient-container-form">
          <InputTextField {...register('patientSearch')} type="text" label="Search Patient" />
          <InputTextField
            name="date"
            type="text"
            label="Date of Birth"
            placeholder="YYYY-MM-DD"
            value={displayDobValue || ''}
            onChange={(e) => {
              if (e.currentTarget.value === '') {
                setDateOfBirthValue(null);
                setDisplayDobValue(null);
                return;
              }
              const displayDate = e.currentTarget.value;
              const date = dayjs(displayDate).toDate();

              setDisplayDobValue(displayDate);
              setDateOfBirthValue(date);
            }}
          />
          <CTAButton
            text="Create new patient"
            onClick={() => {
              handleCreatePatient({
                isOpen: true,
                patientDetails: {
                  name: watch('patientSearch'),
                  dob: watch('date'),
                },
              });
            }}
          />
        </div>

        <div className="MyTasks-CheckOrders-modal-new-orders-search-patient-container-table">
          <Table
            loadingRows={isLoading}
            columnsConfig={tableColumnsConfig}
            rowHeight={80}
            itemCount={patients.length}
            getRowKey={useCallback((index) => patients[index]?._id, [patients])}
            selectedRowKeys={[selectedPatientTableIndex]}
            onRowClick={(id) => {
              const patient = patients.find((p) => p._id === id);
              handleSetPatient(patient);
              setSelectedPatientIndex(id);
            }}
            renderFixedSizeList={(ListComponent) => (
              <TableInfiniteScrollWrapper
                hasNextPage={hasNextPage || false}
                isNextPageLoading={isFetchingNextPage}
                items={patients}
                loadNextPage={() => {
                  setTimeout(() => {
                    fetchNextPage();
                  }, 1000);
                }}
              >
                {({ onItemsRendered, ref, itemCount, renderRow }) => (
                  <ListComponent
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    itemCount={itemCount}
                    renderRow={renderRow}
                  />
                )}
              </TableInfiniteScrollWrapper>
            )}
          />
        </div>
      </div>
    </div>
  );
});

SearchPatient.displayName = 'SearchPatient';

export default SearchPatient;
