import { getPortalCheckPatient } from 'api';
import { useQuery } from 'react-query';

export const useFetchCustomer = (id: string) => {
  const query = useQuery(['useFetchCustomer', id], () => getPortalCheckPatient(id));

  return { query, patient: query.data };
};

export default useFetchCustomer;
