import { IPortalCheckListResponseData } from 'models';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface INewOrderModalConfig {
  isOpen: boolean;
  handleCreatePatient: (patientDetails: any) => void;
  payerDetails: IPortalCheckListResponseData | null;
}

export type ICheckOrdersContextProps = {
  newOrderModalConfig: INewOrderModalConfig;
  setNewOrderModalConfig: Dispatch<SetStateAction<INewOrderModalConfig>>;
};

const CheckOrdersContext = createContext<ICheckOrdersContextProps>({} as ICheckOrdersContextProps);

export default CheckOrdersContext;
