import publicAxios, { AxiosResponse } from 'axios';
import Config from 'Config';

const axios = publicAxios.create({
  baseURL: 'https://api.dropboxapi.com/2',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${Config.dropboxAuthToken}`,
  },
});

export const fetchCurrentAccount = (): Promise<AxiosResponse> =>
  axios.post('/users/get_current_account', JSON.stringify(null)).then((res) => res);

export const fetchNameSpaceId = async (): Promise<string> => {
  try {
    return (await fetchCurrentAccount()).data.root_info.root_namespace_id;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const fetchPreviewPdf = async (path: string) => {
  const namespaceId = await fetchNameSpaceId();

  return axios
    .post('https://content.dropboxapi.com/2/files/download', null, {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${Config.dropboxAuthToken}`,
        'Dropbox-API-Path-Root': `{".tag": "namespace_id", "namespace_id": "${namespaceId}"}`,
        'Dropbox-API-Arg': JSON.stringify({ path }),
      },
      responseType: 'arraybuffer',
    })
    .then(({ data: arrayBuffer }) => {
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);
      return previewUrl;
    });
};
