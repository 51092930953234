import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import { MenuItem } from '../../models';
import './Settings.scss';

const Settings = (): JSX.Element => {
  const menuItems: MenuItem[] = [
    {
      path: '/settings/change-user-password',
      icon: '/assets/icons/reset.svg',
      title: 'Change User Password',
      description: 'Change your login password',
    },
    {
      path: '/',
      icon: '/assets/icons/log-out.svg',
      title: 'Logout',
      description: 'Logout your current session',
    },
    {
      path: '/settings/icd-10-cm-directory',
      icon: '/assets/icons/search.svg',
      title: 'ICD-10-CM Directory',
      description: 'View and search ICD-10-CM codes',
    },
    {
      path: '/settings/npi-directory',
      icon: '/assets/icons/search.svg',
      title: 'NPI Directory',
      description: 'View and search NPI records',
    },
  ];

  return (
    <DashboardLayout title="Settings">
      <MenuGrid menuItems={menuItems} />
    </DashboardLayout>
  );
};

export default Settings;
