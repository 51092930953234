import { putUpdateTask } from 'api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ICreateTaskErrorResponse, ICreateTaskPayload, ICreateTaskSuccessResponse } from 'models';

interface Props {
  onSuccess?: (res: ICreateTaskSuccessResponse) => void;
  onError?: (err: ICreateTaskErrorResponse) => void;
}

export const usePutUpdatePortalTask = ({
  onSuccess,
  onError,
}: Props): UseMutationResult<
  ICreateTaskSuccessResponse,
  ICreateTaskErrorResponse,
  Omit<ICreateTaskPayload, '_id'> & { id: string }
> => {
  const queryClient = useQueryClient();

  const mutation = useMutation((props: Omit<ICreateTaskPayload, '_id'> & { id: string }) => putUpdateTask(props), {
    onSuccess: (res) => {
      if (res.status === 'ok') {
        queryClient.invalidateQueries('useFetchPortalCheckTasks');
        if (onSuccess) onSuccess(res);
        return;
      }

      alert(res.message);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      }

      alert(err.response.message);
    },
  });

  return mutation;
};

export default usePutUpdatePortalTask;
