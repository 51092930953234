import { DbdAccountInvoiceListPayload, DbdAccountInvoiceListResponse } from 'models';
import { useMutation, UseMutationResult } from 'react-query';
import { getDbdAccountInvoiceList } from '..';

interface Props {
  onSuccess?: (res: DbdAccountInvoiceListResponse) => void;
  onError?: (err: Error) => void;
}

export const useFetchDbdAccountInvoiceList = ({
  onSuccess,
  onError,
}: Props): UseMutationResult<DbdAccountInvoiceListResponse, Error, DbdAccountInvoiceListPayload, unknown> => {
  const mutation = useMutation((props: DbdAccountInvoiceListPayload) => getDbdAccountInvoiceList(props), {
    onSuccess,
    onError,
  });

  return mutation;
};

export default useFetchDbdAccountInvoiceList;
