import { getEscalationDetails } from 'api';
import { useQuery } from 'react-query';

export const useFetchEscalationById = (id: string) => {
  const query = useQuery(['useFetchEscalationById', id], () => getEscalationDetails(id), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { query, details: query.data?.data };
};

export default useFetchEscalationById;
