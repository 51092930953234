import { getS3ContentBuffer } from 'api';
import { useMutation } from 'react-query';

export const useDownloadInvoiceFiles = (fileName: string, blobType: 'application/pdf' | 'text/csv') => {
  const mutate = useMutation(getS3ContentBuffer, {
    onSuccess: (buffer) => {
      const blob = new Blob([buffer], { type: blobType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    },
  });

  return mutate;
};

export default useDownloadInvoiceFiles;
