import React, { forwardRef, memo, ReactNode, useState, useEffect } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Accordion.scss';

interface Props {
  children: ReactNode;
  defaultExpanded?: true;
  expanded?: boolean;
  title: string;
  subtitle?: string;
  error?: boolean;
  className?: string;
}

export const Accordion = forwardRef(
  ({ children, title, subtitle, defaultExpanded, expanded, error, className }: Props, ref: any) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    useEffect(() => {
      setIsExpanded(expanded);
    }, [expanded]);

    return (
      <MuiAccordion
        ref={ref}
        className={`Accordion ${error ? 'mod-error' : 'mod-no-error'} ${className || ''} `}
        expanded={isExpanded}
        defaultExpanded={defaultExpanded}
        square
        disableGutters
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="Accordion-title-container">
            <span className="Accordion-title-container-title">{title}</span>
            <span className="Accordion-title-container-subtitle">{subtitle}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MuiAccordion>
    );
  },
);

Accordion.displayName = 'Accordion';

export default memo(Accordion);
