import React from 'react';
import {
  useDownloadInvoiceFiles as useDownloadGeneratedInvoices,
  useDownloadInvoiceFiles as useDownloadStatementOfAccount,
  useFetchDbdStatementUploadDetails,
} from 'api/hooks';
import { Card, CTAButton } from 'components';
import { DashboardLayout } from 'layouts';
import { DbdStatement } from 'models';
import { useParams } from 'react-router';
import './InvoiceDetails.scss';

interface Params {
  id: string;
}

const InvoiceDetails = () => {
  const { id } = useParams() as Params;
  const { isLoading, statement } = useFetchDbdStatementUploadDetails(id);
  const { mutate: downloadStatementOfAccount, isLoading: downloadingStatement } = useDownloadStatementOfAccount(
    statement.csvName,
    'text/csv',
  );
  const { mutate: downloadGeneratedInvoices, isLoading: downloadingGeneratedInvoices } = useDownloadGeneratedInvoices(
    statement.pdfName,
    'application/pdf',
  );

  const ToolBar = () => {
    return (
      <div className="InvoiceDetails-toolbar">
        <CTAButton
          isLoading={downloadingStatement}
          onClick={() => downloadStatementOfAccount(statement.csvPath)}
          text="Download Statement of Account"
        />
        <CTAButton
          isLoading={downloadingGeneratedInvoices}
          onClick={() => downloadGeneratedInvoices(statement.pdfPath)}
          text="Download Generated Invoices"
        />
      </div>
    );
  };

  return (
    <DashboardLayout title="Invoice Details" toolbar={<ToolBar />}>
      <Card isLoading={isLoading} className="InvoiceDetails-container">
        {Object.keys(statement).map((key) => (
          <div key={key} className="InvoiceDetails-information">
            <span className="InvoiceDetails-information-fieldName">{key}</span>
            <span className="InvoiceDetails-information-fieldValue">{statement[key as keyof DbdStatement]}</span>
          </div>
        ))}
      </Card>
    </DashboardLayout>
  );
};

export default InvoiceDetails;
