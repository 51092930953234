import { getDbdAccountDetails } from 'api';
import { DbdAccount, DbdAccountDetailsResponse } from 'models';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

type Options = Omit<
  UseQueryOptions<DbdAccountDetailsResponse, Error, DbdAccountDetailsResponse, string[]>,
  'queryKey' | 'queryFn'
>;

export const useFetchDbdAccountDetails = (
  id: string,
  options?: Options,
): UseQueryResult<DbdAccountDetailsResponse, Error> & { account: DbdAccount } => {
  const query = useQuery(['getDbdAccountDetails', id], () => getDbdAccountDetails(id), {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

  const account = useMemo(() => {
    return query.data?.results || ({} as DbdAccount);
  }, [query.data?.results]);

  return { ...query, account };
};

export default useFetchDbdAccountDetails;
