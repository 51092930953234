import { fetchPreviewPdf } from 'api/dropbox';
import { useQuery } from 'react-query';

export const useFetchPreviewDropboxFile = (remotePath: string) => {
  const query = useQuery(['getEmailFaxDocuments', remotePath], () => fetchPreviewPdf(remotePath), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { query, previewSrc: query.data };
};

export default useFetchPreviewDropboxFile;
