const formatDate = (value: string): string => {
  const pureNumValue = value.replace(/\D/g, '');
  const year = pureNumValue.slice(0, 4);
  const month = pureNumValue.slice(4, 6);
  const date = pureNumValue.slice(6, 8);

  let str = '';

  if (month.length > 0) str += `${month}`;
  if (date.length > 0) str += `/${date}`;
  if (year.length > 0) str += `/${year}`;

  return str;
};

export default formatDate;
