import { RootState } from 'MyTypes';
import { getEscalationDetails, getMyEscalationTasks } from 'api';
import { IEscalationTasksSuccessResponse, IEscalationTasksResponseData } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import getNextPageParam from 'utils/getNextPageParam';
import getPreviousPageParam from 'utils/getPreviousPageParam';

const PAGE_SIZE = 20;

export const useFetchMyEscalationTasks = () => {
  const currentUserDetails = useSelector((state: RootState) => state.userDetails.currentUserDetails);
  const [unreadNotes, setUnreadNotes] = useState<boolean[]>([]);
  const query: UseInfiniteQueryResult<IEscalationTasksSuccessResponse> = useInfiniteQuery(
    'useFetchMyEscalationTasks',
    ({ pageParam = 1 }) => getMyEscalationTasks({ limit: PAGE_SIZE, skip: (pageParam - 1) * PAGE_SIZE }),
    {
      keepPreviousData: true,
      getNextPageParam,
      getPreviousPageParam,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const myEscalationTasks = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages
      .reduce((acc, page) => [...acc, ...page?.data], [] as IEscalationTasksResponseData[])
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [query.data]);

  const totalCount = useMemo(() => {
    if (!query.data) return 0;
    return query.data.pages[0].pagination.totalCount;
  }, [query.data]);

  useEffect(() => {
    const findUnreadNotes = async () => {
      const latestNotesFromCurrentUser = await Promise.all(
        myEscalationTasks.map(async (task) => {
          try {
            const {
              data: { createdBy, thread },
            } = await getEscalationDetails(task._id);

            const latestNoteCreator = thread[thread.length - 1]?.createdBy || createdBy;
            return latestNoteCreator.toLowerCase() !== currentUserDetails?.username.toLowerCase();
          } catch (error) {
            return false;
          }
        }),
      );
      setUnreadNotes(latestNotesFromCurrentUser);
    };

    findUnreadNotes();
  }, [myEscalationTasks, currentUserDetails?.username]);

  return { query, myEscalationTasks, totalCount, unreadNotes };
};

export default useFetchMyEscalationTasks;
