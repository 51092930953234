import { useFetchEmailFaxDocuments } from 'api/hooks';
import { Card, ColumnConfig, InputTextField, Table } from 'components';
import { DashboardLayout } from 'layouts';
import { useCallback, useEffect } from 'react';
import Modal, { Styles } from 'react-modal';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import InitiatePrescriptionEfaxOrder from './journey/InitiatePrescriptionEfaxOrder/InitiatePrescriptionEfaxOrder';
import InitiateAuthorizationEfaxOrder from './journey/InitiateAuthorizationEfaxOrder/InitiateAuthorizationEfaxOrder';
import styles from './AllTasks.module.scss';

const AllTasks = () => {
  const history = useHistory();
  const { register, watch } = useForm();

  const {
    columnsConfig: prescriptionEfaxOrdersColumns,
    results: prescriptionEfaxOrders,
    isLoading: isLoadingPrescriptionEfaxOrders,
    setFilterQuery: setPrescriptionFilterQuery,
  } = useFetchEmailFaxDocuments('prescriptions');

  const {
    columnsConfig: authorizationsEfaxOrdersColumns,
    results: authorizationsEfaxOrders,
    isLoading: isLoadingAuthorizationsEfaxOrders,
    setFilterQuery: setAuthorizationFilterQuery,
  } = useFetchEmailFaxDocuments('authorizations');

  const prescriptionFilterQuery = watch('prescriptionFilter');
  const authorizationFilterQuery = watch('authorizationFilter');

  useEffect(() => {
    setPrescriptionFilterQuery(prescriptionFilterQuery);
  }, [setPrescriptionFilterQuery, prescriptionFilterQuery]);

  useEffect(() => {
    setAuthorizationFilterQuery(authorizationFilterQuery);
  }, [setAuthorizationFilterQuery, authorizationFilterQuery]);

  const prescriptionEfaxOrdersColumnsConfig: ColumnConfig[] = [...prescriptionEfaxOrdersColumns];

  const authorizationsEfaxOrdersColumnsConfig: ColumnConfig[] = [...authorizationsEfaxOrdersColumns];

  const modalStyle: Styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1600,
      width: '85%',
      height: '85%',
      borderRadius: 20,
      margin: 'auto',
      padding: '2rem',
      backgroundColor: '#f1f5f7',
    },
    overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  };
  return (
    <>
      {history.location.pathname.startsWith('/task-manager/all-tasks/initiate-prescriptions') && (
        <Modal isOpen onRequestClose={() => history.goBack()} style={modalStyle}>
          <InitiatePrescriptionEfaxOrder />
        </Modal>
      )}
      {history.location.pathname.startsWith('/task-manager/all-tasks/initiate-authorizations') && (
        <Modal isOpen onRequestClose={() => history.goBack()} style={modalStyle}>
          <InitiateAuthorizationEfaxOrder />
        </Modal>
      )}
      <DashboardLayout title="All Tasks">
        <div className="MyTasks">
          <div className="MyTasks-new-orders">
            <Card
              title={`Prescriptions - eFax (${prescriptionEfaxOrders.length})`}
              className={styles.taskManagerOrdersTableContainer}
              noPadding
            >
              <InputTextField
                className={styles.filterInputField}
                {...register('prescriptionFilter')}
                placeholder="Search File Name"
                noShadow
              />
              <Table
                loadingRows={isLoadingPrescriptionEfaxOrders}
                columnsConfig={prescriptionEfaxOrdersColumnsConfig}
                rowHeight={100}
                itemCount={prescriptionEfaxOrders.length}
                getRowKey={useCallback((index) => prescriptionEfaxOrders?.[index].id, [prescriptionEfaxOrders])}
                onRowClick={(id) => {
                  history.push(`/task-manager/all-tasks/initiate-prescriptions/${id}`);
                }}
              />
            </Card>
            <Card
              title={`Authorizations - eFax (${authorizationsEfaxOrders.length})`}
              className={styles.taskManagerOrdersTableContainer}
              noPadding
            >
              <InputTextField
                className={styles.filterInputField}
                {...register('authorizationFilter')}
                placeholder="Search File Name"
                noShadow
              />
              <Table
                loadingRows={isLoadingAuthorizationsEfaxOrders}
                columnsConfig={authorizationsEfaxOrdersColumnsConfig}
                rowHeight={100}
                itemCount={authorizationsEfaxOrders.length}
                getRowKey={useCallback((index) => authorizationsEfaxOrders?.[index].id, [authorizationsEfaxOrders])}
                onRowClick={(id) => {
                  history.push(`/task-manager/all-tasks/initiate-authorizations/${id}`);
                }}
              />
            </Card>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default AllTasks;
