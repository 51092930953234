import { postCreateEscalation } from 'api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { IEscalationTasksPayload, IEscalationTasksErrorResponse, ICreateEscalationTasksSuccessResponse } from 'models';

interface Props {
  onSuccess?: (res: ICreateEscalationTasksSuccessResponse) => void;
  onError?: (err: IEscalationTasksErrorResponse) => void;
}

export const usePostCreateEscalationTask = ({
  onSuccess,
  onError,
}: Props): UseMutationResult<
  ICreateEscalationTasksSuccessResponse,
  IEscalationTasksErrorResponse,
  IEscalationTasksPayload
> => {
  const queryClient = useQueryClient();
  const mutation = useMutation((props: IEscalationTasksPayload) => postCreateEscalation(props), {
    onSuccess: (res) => {
      if (res.status === 'ok') {
        if (onSuccess) onSuccess(res);

        queryClient.invalidateQueries({
          queryKey: ['useFetchMyEscalationTasks'],
        });

        return;
      }

      alert(res.message);
    },
    onError: (err: any) => {
      if (onError) {
        onError(err);
      }

      alert(err.response.message);
    },
  });

  return mutation;
};

export default usePostCreateEscalationTask;
