import { IPaginatedResponseBody } from 'models';

const getNextPageParam = ({
  pagination: { skip, limit, count },
}: {
  pagination: IPaginatedResponseBody<undefined>['pagination'];
}): number | null => {
  if (count < limit) {
    return null;
  }

  const nextParam = skip + limit;
  return nextParam;
};

export default getNextPageParam;
