import { memo, ReactNode } from 'react';
import { ClipLoader } from 'react-spinners';
import './Card.scss';

interface Props {
  children: ReactNode;
  noPadding?: boolean;
  className?: string;
  isLoading?: boolean;
  title?: string;
  onClick?: () => void;
}

export const Card = memo<Props>(({ children, onClick, noPadding = false, className = '', isLoading, title }) => {
  return (
    <div onClick={onClick} className={`Card ${noPadding ? 'mod-no-padding' : ''} ${className}`}>
      {title && (
        <div className="Card-header">
          <p className="Card-header-title">{title}</p>
        </div>
      )}
      {isLoading ? (
        <div className="Card-loading">
          <ClipLoader size={48} color="#06bbc9" />
        </div>
      ) : (
        children
      )}
    </div>
  );
});

Card.displayName = 'Card';

export default Card;
