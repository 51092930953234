import { getS3ContentBuffer, getSearchDbdStatementUploads, uploadDbdStatement } from 'api';
import { useFetchStatementUploads } from 'api/hooks';
import { Card, ColumnConfig, CTAButton, InputTextField, Table, TableInfiniteScrollWrapper } from 'components';
import dayjs from 'dayjs';
import { DashboardLayout } from 'layouts';
import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import './Invoices.scss';

const Invoices = () => {
  const { register } = useForm();
  const history = useHistory();
  const [isResettingUsers, setIsResettingUsers] = useState(false);
  const {
    statements,
    fetchNextPage,
    refetch,
    hasNextPage,
    isLoading: isLoadingStatementUploads,
  } = useFetchStatementUploads();
  const uploadStatementRef = useRef<HTMLInputElement | null>(null);
  const { mutate: searchDbdStatements, data: searchResponse } = useMutation(getSearchDbdStatementUploads);
  const { mutate, isLoading } = useMutation(uploadDbdStatement, {
    onSuccess: (data) => {
      if (data.status === 'ok') {
        refetch();
      } else {
        // eslint-disable-next-line no-alert
        alert(data.error);
      }
    },
  });

  const handleDownloadStatement = useCallback(
    (s3Path: string, fileName: string, blobType: 'text/csv' | 'application/pdf') => {
      getS3ContentBuffer(s3Path).then((buffer) => {
        const blob = new Blob([buffer], { type: blobType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      });
    },
    [],
  );

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'id',
      renderColumn: (index) => (searchResponse?.results || statements)[index].csvId,
    },
    {
      heading: 'Upload Date',
      renderColumn: (index) =>
        dayjs((searchResponse?.results || statements)[index].createdAt).format('MM-DD-YYYY HH:mm:ss'),
    },
    {
      heading: 'File',
      renderColumn: (index) => (searchResponse?.results || statements)[index].csvName,
    },
    {
      heading: 'Invoice',
      renderColumn: (index) => (
        <img
          className="Invoices-table-download-cell"
          onClick={() => {
            handleDownloadStatement(
              (searchResponse?.results || statements)[index].pdfPath,
              (searchResponse?.results || statements)[index].pdfName,
              'application/pdf',
            );
          }}
          src="/assets/icons/download.svg"
          alt="download"
        />
      ),
      flex: '0 0 120px',
      textAlign: 'center',
    },
    {
      heading: 'Input File',
      renderColumn: (index) => (
        <img
          className="Invoices-table-download-cell"
          onClick={() => {
            handleDownloadStatement(
              (searchResponse?.results || statements)[index].csvPath,
              (searchResponse?.results || statements)[index].csvName,
              'text/csv',
            );
          }}
          src="/assets/icons/download.svg"
          alt="download"
        />
      ),
      flex: '0 0 120px',
      textAlign: 'center',
    },
  ];

  const handleSearch = useCallback(
    (query) => {
      searchDbdStatements(query);
    },
    [searchDbdStatements],
  );

  const resetStatements = useCallback(async () => {
    setIsResettingUsers(true);
    await refetch();
    setIsResettingUsers(false);
  }, [refetch]);

  const handleOnRowClick = (id: string) => {
    history.push(`/dme-billing-dashboard/invoices/${id}`);
  };

  const handleUploadStatementClick = useCallback(() => {
    if (!uploadStatementRef.current) return;
    uploadStatementRef.current.click();
  }, []);

  const handleUploadStatementChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      mutate(formData);
    },
    [mutate],
  );
  return (
    <DashboardLayout
      title="Invoices"
      toolbar={
        <CTAButton
          isLoading={isLoading}
          onClick={handleUploadStatementClick}
          className="Invoices-cta-upload-statement"
          text="Upload Statement"
        />
      }
    >
      <input
        className="Invoices-upload-statement-input"
        ref={uploadStatementRef}
        onChange={handleUploadStatementChange}
        type="file"
        id="myFile"
        name="filename"
        accept=".csv"
      />
      <Card className="Invoices" noPadding>
        <div className="Invoices-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search user..."
            type="text"
            className="Invoices-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch(e.currentTarget.value);
              }
            }}
          />
          <div className="Invoices-header-reset-container">
            <span className="Invoices-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="Invoices-header-reset-container-text">
              1&nbsp; - &nbsp;
              {(searchResponse?.results || statements).length}
            </span>

            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={useCallback(() => resetStatements(), [resetStatements])}
              disabled={isResettingUsers}
              className="Invoices-header-reset-container-refresh-button"
            />
          </div>
        </div>
        <Table
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          itemCount={(searchResponse?.results || statements).length}
          getRowKey={useCallback(
            (index) => ((searchResponse?.results || statements) ?? [])[index]?._id,
            [searchResponse?.results, statements],
          )}
          onRowClick={(index) => handleOnRowClick(index)}
          loadingRows={isLoadingStatementUploads}
          renderFixedSizeList={(ListComponent) => (
            <TableInfiniteScrollWrapper
              hasNextPage={hasNextPage || false}
              isNextPageLoading={false}
              items={searchResponse?.results || statements}
              loadNextPage={() => {
                setTimeout(() => {
                  fetchNextPage();
                }, 2000);
              }}
            >
              {({ onItemsRendered, ref }) => <ListComponent ref={ref} onItemsRendered={onItemsRendered} />}
            </TableInfiniteScrollWrapper>
          )}
        />
      </Card>
    </DashboardLayout>
  );
};

export default Invoices;
