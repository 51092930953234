import { getPayerByInsuranceName } from 'api';
import { IInsurancePayer, IInsurancePayersSuccessResponse } from 'models';
import { useQuery, UseQueryResult } from 'react-query';

interface ReturnType {
  query: UseQueryResult<IInsurancePayersSuccessResponse, unknown>;
  payer: IInsurancePayer | undefined;
}

export const useFetchPayerByInsurance = (insuranceName: string): ReturnType => {
  const query = useQuery(['useFetchPayerByInsurance', insuranceName], () => getPayerByInsuranceName(insuranceName), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const payer = query.data?.data[0];

  return { query, payer };
};
export default useFetchPayerByInsurance;
