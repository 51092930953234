import { getDbdStatementUploads } from 'api';
import { DbdStatementsResponse, DbdStatement } from 'models';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

const PAGE_SIZE = 50;

export const useFetchStatementUploads = () => {
  const query: UseInfiniteQueryResult<DbdStatementsResponse> = useInfiniteQuery(
    'getDbdStatementUploads',
    ({ pageParam = 1 }) => getDbdStatementUploads(PAGE_SIZE, (pageParam - 1) * PAGE_SIZE),
    {
      staleTime: 120000,
      cacheTime: 120000,
      keepPreviousData: true,
      getNextPageParam: (_, lastPage) => lastPage.length + 1,
    },
  );

  const statements = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.reduce((acc, page) => [...acc, ...page.results], [] as DbdStatement[]);
  }, [query.data]);

  return { ...query, statements };
};

export default useFetchStatementUploads;
