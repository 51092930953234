import { getManualTasks } from 'api';
import { IManualTaskData } from 'models/ManualTask';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export const useFetchManualTasks = () => {
  const query = useQuery('useFetchManualTasks', getManualTasks);

  const manualTasks = useMemo(() => (query.data?.data || []) as IManualTaskData[], [query.data?.data]).sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return { manualTasks, query };
};

export default useFetchManualTasks;
