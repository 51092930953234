import { CTAButton, Accordion } from 'components';
import { useCallback, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import './CheckOrders.scss';
import { useForm } from 'react-hook-form';
import formatFullName from 'utils/formatFullName';
import { IPortalCheckSearchPatientResponseData } from 'models';
import { useFetchPortalCheckList, usePutPortalCheckComplete } from 'api/hooks';
import Header from './components/Header';
import SearchPatient from './components/SearchPatient';
import NewOrderForm from './components/NewOrderForm';
import CheckOrdersFormContext from '../../context/CheckOrdersFormContext';
import OrderFormContext from '../../context/OrderFormContext';
import CheckOrdersContext from '../../context/CheckOrdersContext';

const Order = ({ index, id, error, setHandleSubmitActions, handleSubmitActions, expanded, handleRemoveOrder }: any) => {
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [orderCount, setOrderCount] = useState(1);

  return (
    <div className="MyTasks-CheckOrders-modal-order-form">
      {index !== 0 && (
        <button
          onClick={() => handleRemoveOrder(id)}
          className="MyTasks-CheckOrders-modal-order-form-delete-btn"
          type="button"
        >
          x
        </button>
      )}
      <Accordion
        className="mod-first"
        expanded={expanded}
        error={error}
        defaultExpanded
        title={`Existing order for ${
          formatFullName(selectedPatient?.firstname, selectedPatient?.middleinitial, selectedPatient?.lastname) || '...'
        }`}
        subtitle={`(${orderCount})`}
        key={id}
      >
        <SearchPatient handleSetPatient={(patient) => setSelectedPatient(patient)} />
        {selectedPatient && (
          <OrderFormContext.Provider value={{ selectedPatient }}>
            <NewOrderForm
              setOrderCount={setOrderCount}
              key={id}
              id={id}
              passHandleSubmitFn={(handleSubmit: any) => {
                if (handleSubmitActions[id]) return;
                setHandleSubmitActions((prev: any) => ({ ...prev, [id]: handleSubmit }));
              }}
            />
          </OrderFormContext.Provider>
        )}
      </Accordion>
    </div>
  );
};

const CheckOrders = () => {
  const [isNewOrders, setIsNewOrders] = useState<boolean | null>(null);
  const [orderForms, setOrderForms] = useState([uuid()]);
  const [handleSubmitActions, setHandleSubmitActions] = useState<any>({});
  const [expandAllAccordions, setExpandAllAccordions] = useState<boolean>(true);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const form = useForm();

  const { newOrderModalConfig, setNewOrderModalConfig } = useContext(CheckOrdersContext);

  const {
    query: { refetch: refetchPortalCheckList },
  } = useFetchPortalCheckList();

  const { mutate, isLoading: isCompletingPortalCheck } = usePutPortalCheckComplete({
    onSuccess: (res) => {
      if (res.status === 'ok') {
        setNewOrderModalConfig((prev) => ({ ...prev, isOpen: false, payerDetails: null }));
        refetchPortalCheckList();
      }
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = form;

  const handleAddOrder = useCallback(() => {
    setOrderForms([...orderForms, uuid()]);
    setExpandAllAccordions(false);
    setConfirmSubmit(false);
  }, [orderForms]);

  const handleConfirmSubmit = useCallback(
    (data: any) => {
      // Post body
      const transformedData = Object.entries(data).map(([, value]: any) => {
        const selectedPatient = (Object.entries(value).find(([property]) => property === 'selectedPatient')?.[1] ||
          {}) as IPortalCheckSearchPatientResponseData;

        return {
          ...selectedPatient,
          orderDetails: Object.entries(value)
            .filter(([property]) => property !== 'selectedPatient')
            .map(([, formData]) => formData),
        };
      });

      // eslint-disable-next-line no-console
      console.log(transformedData);

      // @TODO: Post data to API then complete portal check
      if (newOrderModalConfig.payerDetails?._id) mutate({ id: newOrderModalConfig.payerDetails?._id });
    },
    [mutate, newOrderModalConfig.payerDetails?._id],
  );

  const handleRemoveOrder = useCallback(
    (formId: string) => {
      setValue(formId, undefined);
      clearErrors();
      setOrderForms(orderForms.filter((id) => id !== formId));
      setConfirmSubmit(false);
      setHandleSubmitActions((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [formId]: _, ...rest } = prev;
        return rest;
      });
    },
    [clearErrors, orderForms, setValue],
  );

  return (
    <div className="MyTasks-CheckOrders-modal">
      <Header setIsNewOrders={(newOrder) => setIsNewOrders(newOrder)} isNewOrders={isNewOrders} />
      {isNewOrders === false && (
        <CTAButton
          className="MyTasks-CheckOrders-modal-cta-container-no-orders-update-btn"
          text="Confirm"
          isLoading={isCompletingPortalCheck}
          onClick={() => {
            if (newOrderModalConfig.payerDetails?._id) mutate({ id: newOrderModalConfig.payerDetails?._id });
          }}
        />
      )}
      <CheckOrdersFormContext.Provider value={form}>
        {isNewOrders &&
          orderForms.map((id, index) => {
            return (
              <Order
                handleRemoveOrder={handleRemoveOrder}
                index={index}
                expanded={orderForms.length - 1 === index ? true : expandAllAccordions}
                key={id}
                id={id}
                error={errors[id] !== undefined}
                setHandleSubmitActions={setHandleSubmitActions}
                handleSubmitActions={handleSubmitActions}
              />
            );
          })}
      </CheckOrdersFormContext.Provider>
      {isNewOrders &&
        Object.values(handleSubmitActions).length > 0 &&
        Object.values(handleSubmitActions).length === orderForms.length && (
          <div className="MyTasks-CheckOrders-modal-cta-container">
            <CTAButton
              className="MyTasks-CheckOrders-modal-cta-container-add-patient-btn"
              text="+ Add additional orders for another patient"
              onClick={handleSubmit(handleAddOrder, () => setConfirmSubmit(false))}
            />
            <CTAButton
              className="MyTasks-CheckOrders-modal-cta-container-update-btn"
              text={confirmSubmit ? 'Confirm' : 'Update'}
              isLoading={isCompletingPortalCheck}
              onClick={() => {
                if (!confirmSubmit) {
                  handleSubmit(() => setConfirmSubmit(true))();
                  return;
                }

                handleSubmit(handleConfirmSubmit, () => setConfirmSubmit(false))();
              }}
            />
          </div>
        )}
    </div>
  );
};

export default CheckOrders;
