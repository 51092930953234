import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import './CTAButton.scss';

interface Props {
  text: string;
  to?: string;
  disabled?: boolean;
  fill?: true;
  invert?: boolean;
  iconSrc?: string;
  className?: string;
  type?: 'button' | 'submit';
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  isLoading?: boolean;
  rectangled?: boolean;
}

// forwardRef memo
export const CTAButton = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  ({ type, text, to, disabled, fill, invert, iconSrc, className, onClick, isLoading, rectangled }, ref) => {
    // export const CTAButton = React.memo<Props>(
    // ({ type, text, to, disabled, fill, invert, iconSrc, className, onClick, isLoading }) => {
    if (to) {
      return (
        <Link
          ref={ref as RefObject<HTMLAnchorElement>}
          onClick={onClick}
          className={`cta-button ${fill ? 'mod-fill' : ''} ${invert ? 'mod-invert' : ''}`}
          to={to}
        >
          {text}
        </Link>
      );
    }
    return (
      <button
        ref={ref as RefObject<HTMLButtonElement>}
        disabled={disabled || isLoading}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={onClick}
        className={`cta-button ${fill ? 'mod-fill' : ''}  ${invert ? 'mod-invert' : ''} ${className || ''} ${
          disabled ? 'mod-disabled' : ''
        } 
        ${rectangled ? 'mod-rectangled' : ''}`}
      >
        <span className="cta-button-label">
          {isLoading && (
            <div className="cta-button-label-loader">
              <ClipLoader size={24} color="white" />
            </div>
          )}
          <span className={`cta-button-label-text ${isLoading ? 'mod-loading' : ''}`}>{text}</span>
        </span>
        {iconSrc && <img alt="" src={iconSrc} />}
      </button>
    );
  },
);

CTAButton.displayName = 'CTAButton';

export default CTAButton;
