import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import { MenuItem } from '../../models';
import './TaskManager.scss';

const TaskManager = (): JSX.Element => {
  const menuItems: MenuItem[] = [
    {
      path: '/task-manager/my-tasks',
      icon: '/assets/icons/task-manager.svg',
      title: 'My Tasks',
      description: 'View and manage my tasks',
    },
    {
      path: '/task-manager/all-tasks',
      icon: '/assets/icons/package.svg',
      title: 'Orders',
      description: 'View and manage order tasks',
    },
    {
      path: '/task-manager/product-catalog',
      icon: '/assets/icons/package.svg',
      title: 'Product Catalog',
      description: 'View product catalog',
    },
  ];

  return (
    <DashboardLayout title="Task Manager">
      <MenuGrid menuItems={menuItems} />
    </DashboardLayout>
  );
};

export default TaskManager;
