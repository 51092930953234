import { useFetchCustomer } from 'api/hooks';
import { Card, InputTextField } from 'components';
import { DashboardLayout } from 'layouts';
import { IPortalCheckSearchPatientResponseData } from 'models';
import { useParams } from 'react-router';
import formatFullName from 'utils/formatFullName';
import styles from './CustomerDetails.module.scss';

const CustomerDetails = () => {
  const params = useParams() as { id: string };
  const {
    patient,
    query: { isLoading, isError },
  } = useFetchCustomer(params.id);

  if (isError)
    return (
      <DashboardLayout title="Unable to fetch data">
        <Card>
          <div>Oops! Something went wrong.</div>
        </Card>
      </DashboardLayout>
    );

  return (
    <DashboardLayout title={formatFullName(patient?.firstname, patient?.middleinitial, patient?.lastname)}>
      <Card isLoading={isLoading}>
        <div className={styles.container}>
          {patient &&
            Object.keys(patient).map((key) => (
              <InputTextField
                className={styles.inputField}
                key={key}
                name={key}
                label={key}
                value={patient[key as keyof IPortalCheckSearchPatientResponseData]}
                noShadow
                onKeyPress={(e) => e.preventDefault()}
              />
            ))}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default CustomerDetails;
