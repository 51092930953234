import classnames from 'classnames';
import { forwardRef, memo, useState, ReactNode, ChangeEvent, useEffect, useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import './InputAutoCompleteField.scss';

interface Item {
  label: string;
  value: string;
}

interface InputAutoCompleteFieldProps {
  defaultValue?: string;
  value?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  bottomClassName?: string;
  label?: ReactNode;
  status?: 'active' | 'error' | 'inactive' | 'disabled';
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSelect: (value: string) => void;
  placeholder?: string;
  isLoading?: boolean;
  bottomLabel?: string;
  forceHideLabel?: boolean;
  forceHideBottomLabel?: boolean;
  items: Item[];
  disabled?: boolean;
  noShadow?: boolean;
}

export const InputAutoCompleteField = forwardRef(
  (
    {
      className = '',
      labelClassName = '',
      inputClassName = '',
      bottomClassName = '',
      label,
      onKeyPress,
      onSelect,
      status = 'active',
      defaultValue = '',
      bottomLabel,
      forceHideLabel,
      forceHideBottomLabel,
      noShadow,
      placeholder,
      isLoading,
      items = [],
      ...restProps
    }: InputAutoCompleteFieldProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    const containerRef = useRef<any>(null);
    const [value, setValue] = useState<string | undefined>(defaultValue);
    const [showItemsList, setShowItemsList] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target?.value;
      setValue(newValue);
      setShowItemsList(!!newValue);
      setIsTyping(true);
    };

    const handleClick = (itemValue: string) => {
      setValue(itemValue);
      onSelect(itemValue);
      setShowItemsList(false);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      setIsTyping(false);
      onKeyPress?.(e);
    };

    useEffect(() => {
      const handleOnClick = (e: MouseEvent) => {
        if (!containerRef.current.contains(e.target)) {
          setShowItemsList(false);
        }
      };

      document.addEventListener('click', handleOnClick);
      return () => {
        document.removeEventListener('click', handleOnClick);
      };
    });

    return (
      <div
        data-testid="InputAutoCompleteField"
        className={classnames('InputAutoCompleteField', className, {
          'mod-noShadow': noShadow,
        })}
        ref={containerRef}
      >
        {!forceHideLabel && <div className={`InputAutoCompleteField-input-label  ${labelClassName}`}>{label}</div>}
        <input
          {...restProps}
          type="text"
          aria-label={(label as string) || undefined}
          placeholder={placeholder}
          className={`${
            status === 'disabled'
              ? 'InputAutoCompleteField-input-disabled'
              : status === 'error'
              ? 'InputAutoCompleteField-input-error'
              : 'InputAutoCompleteField-input'
          } ${inputClassName} ${!value ? 'InputAutoCompleteField-input-placeholder' : ''}`}
          ref={ref}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          value={items.find((item) => item.value === value)?.value}
          readOnly={status === 'disabled'}
        />
        {showItemsList && (
          <ul>
            {isLoading ? (
              <li>
                <ClipLoader color="#06bbc9" />
              </li>
            ) : isTyping ? (
              <li style={{ cursor: 'default', backgroundColor: 'transparent' }}>Press Enter to Search</li>
            ) : items.length ? (
              items.map((item, i) => {
                const itemLabel = typeof item === 'string' ? item : item.label;
                const itemValue = typeof item === 'string' ? item : item.value;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${itemValue}-${i}`} onClick={() => handleClick(itemValue)}>
                    {itemLabel}
                  </li>
                );
              })
            ) : (
              <li style={{ cursor: 'default', backgroundColor: 'transparent' }}>no results</li>
            )}
          </ul>
        )}
        {!forceHideBottomLabel && (
          <div
            className={`${
              status === 'error'
                ? 'InputAutoCompleteField-input-label-bottom-error'
                : 'InputAutoCompleteField-input-label-bottom'
            } ${bottomClassName}`}
          >
            {bottomLabel}
          </div>
        )}
      </div>
    );
  },
);

InputAutoCompleteField.displayName = 'InputAutoCompleteField';

export default memo(InputAutoCompleteField);
