import { getEmailFaxDocuments } from 'api';
import { ColumnConfig } from 'components';
import dayjs from 'dayjs';
import { DropboxFile, IEmailFaxDocumentsPayload, IEmailFaxDocumentsSuccessResponse } from 'models';
import { useMemo, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

type Options = Omit<
  UseQueryOptions<IEmailFaxDocumentsSuccessResponse, Error, IEmailFaxDocumentsSuccessResponse, string[]>,
  'queryKey' | 'queryFn'
>;

export const useFetchEmailFaxDocuments = (type: IEmailFaxDocumentsPayload, options?: Options) => {
  const [sortBy, setSortBy] = useState<'directory asc' | 'directory desc' | 'date asc' | 'date desc'>('date asc');
  const [filterQuery, setFilterQuery] = useState<string>('');
  const query = useQuery(['getEmailFaxDocuments', type], () => getEmailFaxDocuments(type), {
    staleTime: 10000,
    cacheTime: 10000,
    ...options,
  });

  const formatDirectory = (pathDirectory: string) => pathDirectory.split('/')[2];

  const results = useMemo(() => {
    const sortByDate = (resultList: DropboxFile[]) =>
      resultList.sort((a, b) => {
        const dateA: Date = new Date(a.client_modified);
        const dateB: Date = new Date(b.client_modified);

        if (sortBy === 'date asc') return dateA.getTime() - dateB.getTime();
        return dateB.getTime() - dateA.getTime();
      });

    const sortByDirectory = (resultList: DropboxFile[]) =>
      resultList.sort((a, b) => {
        const directoryA: string = formatDirectory(a.path_display);
        const directoryB: string = formatDirectory(b.path_display);

        if (sortBy === 'directory asc') return directoryA.localeCompare(directoryB);
        return directoryB.localeCompare(directoryA);
      });

    const filterByFileName = (resultList: DropboxFile[]) =>
      resultList.filter((result) => {
        return result.name?.toLowerCase().includes(filterQuery?.toLowerCase());
      });

    const resultList: DropboxFile[] = filterByFileName([...(query?.data?.data || [])]);

    if (sortBy.includes('directory')) {
      return sortByDirectory(resultList);
    }
    if (sortBy.includes('date')) {
      return sortByDate(resultList);
    }

    return sortByDate(resultList);
  }, [query?.data?.data, sortBy, filterQuery]);

  const columnsConfig: ColumnConfig[] = [
    {
      heading: 'File Name',
      renderColumn: (index) => results?.[index]?.name,
    },
    {
      heading: 'Directory',
      renderColumn: (index) => formatDirectory(results?.[index]?.path_display),
      onColumnClick: () => setSortBy(sortBy === 'directory asc' ? 'directory desc' : 'directory asc'),
    },
    {
      heading: 'Date',
      renderColumn: (index) => dayjs(results?.[index]?.client_modified).format('MM/DD/YYYY HH:mm:ss'),
      onColumnClick: () => setSortBy(sortBy === 'date asc' ? 'date desc' : 'date asc'),
    },
  ];

  return { ...query, results, columnsConfig, setFilterQuery };
};

export default useFetchEmailFaxDocuments;
