import { getDbdStatementUploadsDetails } from 'api';
import { DbdStatement, DbdStatementDetailsResponse } from 'models';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

type Options = Omit<
  UseQueryOptions<DbdStatementDetailsResponse, Error, DbdStatementDetailsResponse, string[]>,
  'queryKey' | 'queryFn'
>;

export const useFetchDbdStatementUploadDetails = (
  id: string,
  options?: Options,
): UseQueryResult<DbdStatementDetailsResponse, Error> & { statement: DbdStatement } => {
  const query = useQuery(['getDbdStatementUploadsDetails', id], () => getDbdStatementUploadsDetails(id), {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

  const statement = useMemo(() => {
    return query.data?.results || ({} as DbdStatement);
  }, [query.data?.results]);

  return { ...query, statement };
};

export default useFetchDbdStatementUploadDetails;
