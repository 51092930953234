import { usePostCreateEscalationTask } from 'api/hooks';
import { CTAButton, InputDropdownField, InputTextField } from 'components';
import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import InitiateEmailFaxOrderContext from '../../context';
import styles from './Escalate.module.scss';

const modalStyles: Modal.Styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1600,
    width: '50%',
    height: '50%',
    borderRadius: 20,
    margin: 'auto',
    padding: '2rem',
    backgroundColor: '#f1f5f7',
  },
  overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
};
const Escalate = () => {
  const history = useHistory();
  const { setIsEscalateModalOpen, form: formDetails, currentFile } = useContext(InitiateEmailFaxOrderContext);
  const { getValues: getFormDetailsValues } = formDetails;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm();

  const { mutate, isLoading: isCreatingEscalationTask } = usePostCreateEscalationTask({
    onSuccess: () => {
      history.push('/task-manager/all-tasks');
    },
  });

  const users = [
    {
      value: 'admin@nymannings.com',
      label: 'Admin Account - Admin',
    },
    {
      value: 'test@nymannings.com',
      label: 'Test Account - User',
    },
  ];

  const escalationTypes = [
    {
      value: 'authRequestHandover',
      label: 'Auth Request Handover',
    },
    {
      value: 'missingInfo',
      label: 'Missing Info',
    },
    {
      value: 'l2Products',
      label: 'L2 Products',
    },
    {
      value: 'unknownLogic',
      label: 'Unknown Logic/Rules',
    },
    {
      value: 'declinedAuthorization',
      label: 'Declined Authorization',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  useEffect(() => {
    if (getFormDetailsValues('orderCreated.authorizationStatus') === 'Declined') {
      setValue('type', 'declinedAuthorization');
    }
  }, [setValue, getFormDetailsValues]);

  const onSubmit = useCallback(() => {
    mutate({
      escalateType: 'Authorizations',
      taskType: getValues('type'),
      status: '1st Esc',
      notes: getValues('notes'),
      formDetails: getFormDetailsValues(),
      assignTo: getValues('assignee'),
      fileId: currentFile.id,
    });
  }, [currentFile.id, getFormDetailsValues, getValues, mutate]);

  return (
    <Modal isOpen onRequestClose={() => setIsEscalateModalOpen(false)} style={modalStyles}>
      <h1 className={styles.title}>Escalate</h1>
      <div className={styles.container}>
        <InputDropdownField
          className={styles.inputField}
          placeholder="Select Escalate To"
          label="Escalate To"
          items={users}
          status={errors.assignee ? 'error' : 'active'}
          bottomLabel={errors.assignee?.message}
          {...register('assignee', {
            required: 'Escalate To is required',
          })}
        />
        <InputDropdownField
          className={styles.inputField}
          placeholder="Select Escalation Type"
          label="Type"
          status={errors.type ? 'error' : 'active'}
          bottomLabel={errors.type?.message}
          items={escalationTypes}
          defaultValue={watch('type')}
          {...register('type', {
            required: 'Type is required',
          })}
        />
        <InputTextField
          className={styles.inputTextAreaField}
          placeholder="Add a note..."
          label="Notes"
          type="textarea"
          {...register('notes')}
        />
        <div className={styles.ctaContainer}>
          <CTAButton
            onClick={() => setIsEscalateModalOpen(false)}
            rectangled
            className={styles.ctaButton}
            text="Cancel"
            invert
          />
          <CTAButton
            isLoading={isCreatingEscalationTask}
            onClick={handleSubmit(onSubmit)}
            rectangled
            className={styles.ctaButton}
            text="Escalate"
          />
        </div>
      </div>
    </Modal>
  );
};

export default Escalate;
