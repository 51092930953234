import { getNPIDirectoryList } from 'api';
import { Card, ColumnConfig, CTAButton, InputTextField, Table } from 'components';
import { DashboardLayout } from 'layouts';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import './NPIDirectory.scss';

const NPIDirectory = () => {
  const { register } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [isResettingUsers, setIsResettingUsers] = useState(false);

  const {
    data: searchData,
    refetch,
    isLoading,
  } = useQuery(['searchNPIDirectory', searchTerm], () => getNPIDirectoryList(searchTerm));

  const searchResults = useMemo(() => {
    if (searchData && searchData.length > 3) {
      return searchData[3].map((data: any) => {
        const [
          npi,
          providerType,
          gender,
          fullName,
          addressPracticeFull,
          licenses,
          taxonomoy,
          grouping,
          classification,
          specialization,
          medicare,
          medicareSpcCode,
          medicareType,
          name,
          lastName,
          firstName,
          middleName,
          credential,
          prefix,
          suffix,
          addressPractice,
          addressPracticeLine1,
          addressPracticeLine2,
          addressPracticeCity,
          addressPracticeState,
          addressPracticeZip,
          addressPracticePhone,
          addressPracticeFax,
          addressPracticeCountry,
          addressMailing,
          addressMailingFull,
          otherIds,
          otherIdsId,
          otherIdsType,
          otherIdsIssuer,
          otherIdsState,
        ] = data;
        return {
          npi,
          providerType,
          gender,
          fullName,
          addressPracticeFull,
          licenses,
          taxonomoy,
          grouping,
          classification,
          specialization,
          medicare,
          medicareSpcCode,
          medicareType,
          name,
          lastName,
          firstName,
          middleName,
          credential,
          prefix,
          suffix,
          addressPractice,
          addressPracticeLine1,
          addressPracticeLine2,
          addressPracticeCity,
          addressPracticeState,
          addressPracticeZip,
          addressPracticePhone,
          addressPracticeFax,
          addressPracticeCountry,
          addressMailing,
          addressMailingFull,
          otherIds,
          otherIdsId,
          otherIdsType,
          otherIdsIssuer,
          otherIdsState,
        };
      });
    }
    return [];
  }, [searchData]);

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'NPI',
      renderColumn: (index) => searchResults[index]?.npi,
      flex: '0 0 100px',
    },
    {
      heading: 'Provider Type',
      renderColumn: (index) => searchResults[index]?.providerType,
      flex: '0 0 100px',
    },
    {
      heading: 'Gender',
      renderColumn: (index) => searchResults[index]?.gender,
      flex: '0 0 100px',
    },
    {
      heading: 'Full Name',
      renderColumn: (index) => searchResults[index]?.fullName,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Full',
      renderColumn: (index) => searchResults[index]?.addressPracticeFull,
      flex: '0 0 100px',
    },
    {
      heading: 'Licenses',
      renderColumn: (index) => searchResults[index]?.licenses,
      flex: '0 0 100px',
    },
    {
      heading: 'Taxonomoy',
      renderColumn: (index) => searchResults[index]?.taxonomoy,
      flex: '0 0 100px',
    },
    {
      heading: 'Grouping',
      renderColumn: (index) => searchResults[index]?.grouping,
      flex: '0 0 100px',
    },
    {
      heading: 'Classification',
      renderColumn: (index) => searchResults[index]?.classification,
      flex: '0 0 100px',
    },
    {
      heading: 'Specialization',
      renderColumn: (index) => searchResults[index]?.specialization,
      flex: '0 0 100px',
    },
    {
      heading: 'Medicare',
      renderColumn: (index) => searchResults[index]?.medicare,
      flex: '0 0 100px',
    },
    {
      heading: 'Medicare Spc Code',
      renderColumn: (index) => searchResults[index]?.medicareSpcCode,
      flex: '0 0 100px',
    },
    {
      heading: 'Medicare Type',
      renderColumn: (index) => searchResults[index]?.medicareType,
      flex: '0 0 100px',
    },
    {
      heading: 'Name',
      renderColumn: (index) => searchResults[index]?.name,
      flex: '0 0 100px',
    },
    {
      heading: 'Last Name',
      renderColumn: (index) => searchResults[index]?.lastName,
      flex: '0 0 100px',
    },
    {
      heading: 'First Name',
      renderColumn: (index) => searchResults[index]?.firstName,
      flex: '0 0 100px',
    },
    {
      heading: 'Middle Name',
      renderColumn: (index) => searchResults[index]?.middleName,
      flex: '0 0 100px',
    },
    {
      heading: 'Credential',
      renderColumn: (index) => searchResults[index]?.credential,
      flex: '0 0 100px',
    },
    {
      heading: 'Prefix',
      renderColumn: (index) => searchResults[index]?.prefix,
      flex: '0 0 100px',
    },
    {
      heading: 'Suffix',
      renderColumn: (index) => searchResults[index]?.suffix,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice',
      renderColumn: (index) => searchResults[index]?.addressPractice,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Line 1',
      renderColumn: (index) => searchResults[index]?.addressPracticeLine1,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Line 2',
      renderColumn: (index) => searchResults[index]?.addressPracticeLine2,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice City',
      renderColumn: (index) => searchResults[index]?.addressPracticeCity,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice State',
      renderColumn: (index) => searchResults[index]?.addressPracticeState,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Zip',
      renderColumn: (index) => searchResults[index]?.addressPracticeZip,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Phone',
      renderColumn: (index) => searchResults[index]?.addressPracticePhone,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Fax',
      renderColumn: (index) => searchResults[index]?.addressPracticeFax,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Practice Country',
      renderColumn: (index) => searchResults[index]?.addressPracticeCountry,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Mailing',
      renderColumn: (index) => searchResults[index]?.addressMailing,
      flex: '0 0 100px',
    },
    {
      heading: 'Address Mailing Full',
      renderColumn: (index) => searchResults[index]?.addressMailingFull,
      flex: '0 0 100px',
    },
    {
      heading: 'Other Ids',
      renderColumn: (index) => searchResults[index]?.otherIds,
      flex: '0 0 100px',
    },
    {
      heading: 'Other Ids Id',
      renderColumn: (index) => searchResults[index]?.otherIdsId,
      flex: '0 0 100px',
    },
    {
      heading: 'Other Ids Type',
      renderColumn: (index) => searchResults[index]?.otherIdsType,
      flex: '0 0 100px',
    },
    {
      heading: 'Other Ids Issuer',
      renderColumn: (index) => searchResults[index]?.otherIdsIssuer,
      flex: '0 0 100px',
    },

    {
      heading: 'Other Ids State',
      renderColumn: (index) => searchResults[index]?.otherIdsState,
      flex: '0 0 100px',
    },
  ];

  const handleSearch = useCallback((query) => {
    setSearchTerm(query);
  }, []);

  const resetStatements = useCallback(async () => {
    setIsResettingUsers(true);
    await refetch();
    setIsResettingUsers(false);
  }, [refetch]);

  return (
    <DashboardLayout title="NPI Directory">
      <Card className="NPIDirectory" noPadding>
        <div className="NPIDirectory-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search NPI information..."
            type="text"
            className="NPIDirectory-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch(e.currentTarget.value);
              }
            }}
          />
          <div className="NPIDirectory-header-reset-container">
            <span className="NPIDirectory-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="NPIDirectory-header-reset-container-text">
              1&nbsp; - &nbsp;
              {searchResults.length}
            </span>

            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={useCallback(() => resetStatements(), [resetStatements])}
              disabled={isResettingUsers}
              className="NPIDirectory-header-reset-container-refresh-button"
            />
          </div>
        </div>
        <Table
          columnsConfig={tableColumnsConfig}
          rowHeight={100}
          columnWidth={280}
          itemCount={searchResults.length}
          getRowKey={useCallback((index) => searchResults[index]?.npi, [searchResults])}
          loadingRows={isLoading}
        />
      </Card>
    </DashboardLayout>
  );
};

export default NPIDirectory;
