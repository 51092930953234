import { IPortalCheckSearchPatientResponseData } from 'models';
import { createContext } from 'react';

export type IOrderFormContextProps = {
  selectedPatient: IPortalCheckSearchPatientResponseData | null;
};

const OrderFormContext = createContext<IOrderFormContextProps>({} as IOrderFormContextProps);

export default OrderFormContext;
