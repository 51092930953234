import { usePostCreateEscalationTask } from 'api/hooks';
import { CTAButton, InputDropdownField, InputTextField } from 'components';
import { RootState } from 'MyTypes';
import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import InitiateEmailFaxOrderContext from '../../context';
import styles from './SaveDraft.module.scss';

const modalStyles: Modal.Styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1600,
    width: '50%',
    height: '50%',
    borderRadius: 20,
    margin: 'auto',
    padding: '2rem',
    backgroundColor: '#f1f5f7',
  },
  overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
};
const SaveDraft = () => {
  const currentUserDetails = useSelector((state: RootState) => state.userDetails.currentUserDetails);
  const history = useHistory();
  const { setIsSaveDraftModalOpen, form: formDetails, currentFile } = useContext(InitiateEmailFaxOrderContext);
  const { getValues: getFormDetailsValues } = formDetails;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm();
  const { mutate, isLoading: isCreatingEscalationTask } = usePostCreateEscalationTask({
    onSuccess: () => {
      history.push('/task-manager/all-tasks');
    },
  });

  const users = [
    {
      value: 'admin@nymannings.com',
      label: 'Admin Account - Admin',
    },
    {
      value: 'test@nymannings.com',
      label: 'Test Account - User',
    },
  ];

  if (currentUserDetails?.username) {
    const index = users.findIndex((user) => user.value === currentUserDetails.username);
    const me = {
      value: currentUserDetails.username,
      label: 'Myself',
    };
    if (index > -1) {
      users[index] = me;
    } else {
      users.unshift(me);
    }
  }

  const escalationTypes = [
    {
      value: 'authRequestHandover',
      label: 'Auth Request Handover',
    },
    {
      value: 'missingInfo',
      label: 'Missing Info',
    },
    {
      value: 'l2Products',
      label: 'L2 Products',
    },
    {
      value: 'unknownLogic',
      label: 'Unknown Logic/Rules',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  useEffect(() => {
    setValue('assignee', currentUserDetails?.username);
    setValue('type', 'missingInfo');
  }, [currentUserDetails?.username, setValue]);

  const onSubmit = useCallback(() => {
    mutate({
      escalateType: 'Prescriptions',
      taskType: getValues('type'),
      status: '1st Esc',
      notes: getValues('notes'),
      formDetails: getFormDetailsValues(),
      assignTo: getValues('assignee'),
      fileId: currentFile.id,
    });
  }, [currentFile.id, getFormDetailsValues, getValues, mutate]);
  return (
    <Modal isOpen onRequestClose={() => setIsSaveDraftModalOpen(false)} style={modalStyles}>
      <h1 className={styles.title}>Save Draft</h1>
      <div className={styles.container}>
        <InputDropdownField
          className={styles.inputField}
          placeholder="Select Assign To"
          label="Assign To"
          items={users}
          status={errors.assignee ? 'error' : 'active'}
          bottomLabel={errors.assignee?.message}
          defaultValue={watch('assignee')}
          {...register('assignee', {
            required: 'Assign To is required',
          })}
        />
        <InputDropdownField
          className={styles.inputField}
          placeholder="Select Escalation Type"
          label="Type"
          status={errors.type ? 'error' : 'active'}
          bottomLabel={errors.type?.message}
          items={escalationTypes}
          defaultValue={watch('type')}
          {...register('type', {
            required: 'Type is required',
          })}
        />
        <InputTextField
          className={styles.inputTextAreaField}
          placeholder="Add a note..."
          label="Notes"
          type="textarea"
          {...register('notes')}
        />
        <div className={styles.ctaContainer}>
          <CTAButton
            onClick={() => setIsSaveDraftModalOpen(false)}
            rectangled
            className={styles.ctaButton}
            text="Cancel"
            invert
          />
          <CTAButton
            isLoading={isCreatingEscalationTask}
            onClick={handleSubmit(onSubmit)}
            rectangled
            className={styles.ctaButton}
            text="Save Draft"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveDraft;
