import { useFetchPortalCheckSearchPatient } from 'api/hooks';
import classnames from 'classnames';
import { Card, ColumnConfig, InputTextField, Table, TableInfiniteScrollWrapper } from 'components';
import dayjs from 'dayjs';
import { DashboardLayout } from 'layouts';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import formatFullName from 'utils/formatFullName';
import { format, parse } from 'date-fns';
import styles from './Customers.module.scss';

const Customers = () => {
  const [displayDobValue, setDisplayDobValue] = useState<string | null>(null);

  const { register, watch } = useForm();
  const history = useHistory();

  const watchSearchQuery = watch('patientSearch');

  const {
    patients,
    setSearchValue,
    setDebouncedSearchValue,
    setDateOfBirthValue,
    query: { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage },
  } = useFetchPortalCheckSearchPatient();

  useEffect(() => {
    if (watchSearchQuery === '') setSearchValue('');
    setDebouncedSearchValue(watchSearchQuery);
  }, [setDebouncedSearchValue, setSearchValue, watchSearchQuery]);

  const tableColumnsConfig: ColumnConfig[] = [
    { heading: 'Customer ID', renderColumn: (index) => patients[index].customerid },
    {
      heading: 'Name',
      renderColumn: (index) =>
        formatFullName(patients[index].firstname, patients[index].middleinitial, patients[index].lastname),
    },
    {
      heading: 'Date of Birth',
      renderColumn: (index) =>
        patients[index]?.dateofbirth
          ? format(parse(patients[index].dateofbirth, 'yyyyMMdd', new Date()), 'MM/dd/yyyy')
          : '',
    },
    {
      heading: 'Address',
      renderColumn: (index) => patients[index].address,
    },
    {
      heading: 'Phone',
      renderColumn: (index) => patients[index].phone,
    },
  ];

  return (
    <DashboardLayout title="Customers">
      <Card noPadding>
        <div className={classnames('MyTasks-CheckOrders-modal-new-orders-search-patient', styles.container)}>
          <div className="MyTasks-CheckOrders-modal-new-orders-search-patient-container">
            <div
              className={classnames(
                'MyTasks-CheckOrders-modal-new-orders-search-patient-container-form',
                styles.inputSearchContainer,
              )}
            >
              <InputTextField {...register('patientSearch')} type="text" label="Search Patient" />
              <InputTextField
                name="date"
                type="text"
                label="Date of Birth"
                placeholder="YYYY-MM-DD"
                value={displayDobValue || ''}
                onChange={(e) => {
                  if (e.currentTarget.value === '') {
                    setDateOfBirthValue(null);
                    setDisplayDobValue(null);
                    return;
                  }
                  const displayDate = e.currentTarget.value;
                  const date = dayjs(displayDate).toDate();

                  setDisplayDobValue(displayDate);
                  setDateOfBirthValue(date);
                }}
              />
            </div>

            <div className={styles.table}>
              <Table
                loadingRows={isLoading}
                columnsConfig={tableColumnsConfig}
                rowHeight={80}
                itemCount={patients.length}
                getRowKey={useCallback((index) => patients[index]?._id, [patients])}
                onRowClick={(id) => history.push(`/customers/${id}`)}
                renderFixedSizeList={(ListComponent) => (
                  <TableInfiniteScrollWrapper
                    hasNextPage={hasNextPage || false}
                    isNextPageLoading={isFetchingNextPage}
                    items={patients}
                    loadNextPage={() => {
                      setTimeout(() => {
                        fetchNextPage();
                      }, 1000);
                    }}
                  >
                    {({ onItemsRendered, ref, itemCount, renderRow }) => (
                      <ListComponent
                        ref={ref}
                        onItemsRendered={onItemsRendered}
                        itemCount={itemCount}
                        renderRow={renderRow}
                      />
                    )}
                  </TableInfiniteScrollWrapper>
                )}
              />
            </div>
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Customers;
