import { createContext } from 'react';

export interface ICreatePatientModalConfig {
  isOpen: boolean;
  patientDetails: {
    name: string;
    dob: string;
  } | null;
}

export type ICreateNewPatientContextProps = {
  createPatientModalConfig: ICreatePatientModalConfig;
  setCreatePatientModalConfig: React.Dispatch<React.SetStateAction<ICreatePatientModalConfig>>;
};

const CreateNewPatientContext = createContext<ICreateNewPatientContextProps>({} as ICreateNewPatientContextProps);

export default CreateNewPatientContext;
