import {
  useFetchMyEscalationTasks,
  useFetchPortalCheckList,
  useFetchPortalCheckTasks,
  useFetchSubmittedTasks,
  usePutPortalCheckStart,
  usePutPortalCheckStop,
  useFetchManualTasks,
  usePutUpdatePortalTask,
} from 'api/hooks';
import { Card, ColumnConfig, CTAButton, Table, TableInfiniteScrollWrapper } from 'components';
import isToday from 'utils/isToday';
import dayjs from 'dayjs';
import { DashboardLayout } from 'layouts';
import { useCallback, useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import Modal, { Styles } from 'react-modal';
import { Prompt, useHistory } from 'react-router';
import { deleteEscalationById } from 'api';
import escalationTypeToLabel from 'utils/escalationTypeToLabel';
import formatFullName from 'utils/formatFullName';
import { Delete } from '@mui/icons-material';
import CheckOrdersContext, { INewOrderModalConfig } from './context/CheckOrdersContext';
import CreateNewPatientContext, { ICreatePatientModalConfig } from './context/CreateNewPatientContext';
import CheckOrders from './journey/CheckOrders/CheckOrders';
import CreateNewPatient from './journey/CreateNewPatient/CreateNewPatient';
import './MyTasks.scss';
import RespondEscalation from './journey/RespondEscalation/RespondEscalation';
import CreateManualTask from './journey/CreateManualTask/CreateManualTask';

const MyTasks = () => {
  const history = useHistory();
  useBeforeunload(() => 'Are you sure to close this tab?');
  const [deleteTaskClickedIndex, setDeleteTaskClickedIndex] = useState<number | null>(null);

  const {
    checkList,
    notificationsCount,
    query: { isLoading: isLoadingPortalCheckList, refetch: refetchPortalCheckList },
  } = useFetchPortalCheckList();

  const {
    portalCheckTasks,
    totalUncompletedTasksCount,
    query: { isLoading: isLoadingPortalCheckTasks, hasNextPage, fetchNextPage, isFetchingNextPage },
  } = useFetchPortalCheckTasks();

  const {
    myEscalationTasks,
    totalCount: myEscalationTasksCount,
    query: {
      isLoading: isLoadingMyEscalationTasks,
      hasNextPage: hasNextPageMyEscalationTasks,
      fetchNextPage: fetchNextPageMyEscalationTasks,
      isFetchingNextPage: isFetchingNextPageMyEscalationTasks,
      refetch: refetchMyEscalationTasks,
    },
    unreadNotes,
  } = useFetchMyEscalationTasks();

  const {
    tasks,
    totalCount: submittedTasksCount,
    query: {
      isLoading: isLoadingSubmittedTasks,
      hasNextPage: hasNextPageSubmittedTasks,
      fetchNextPage: fetchNextPageSubmittedTasks,
      isFetchingNextPage: isFetchingNextPageSubmittedTasks,
    },
  } = useFetchSubmittedTasks('pending');

  const {
    query: { isLoading: isLoadingManualTasks },
    manualTasks,
  } = useFetchManualTasks();

  const { mutateAsync } = usePutPortalCheckStart({});
  const { mutate } = usePutPortalCheckStop({});
  const { mutate: mutatePutUpdatePortalTask, isLoading: isPutUpdatingPortalTask } = usePutUpdatePortalTask({});

  const [createPatientModalConfig, setCreatePatientModalConfig] = useState<ICreatePatientModalConfig>({
    isOpen: false,
    patientDetails: null,
  });

  const [newOrderModalConfig, setNewOrderModalConfig] = useState<INewOrderModalConfig>({
    isOpen: false,
    payerDetails: null,
    handleCreatePatient: (patientDetails) => {
      setCreatePatientModalConfig({ isOpen: true, patientDetails });
    },
  });

  const handleDeleteTaskConfirmation = useCallback(
    async (index) => {
      await deleteEscalationById(myEscalationTasks[index]?._id);
      refetchMyEscalationTasks();
    },
    [myEscalationTasks, refetchMyEscalationTasks],
  );

  useEffect(() => {
    refetchMyEscalationTasks();
  }, [history.location.pathname, refetchMyEscalationTasks]);

  const newOrdersColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Payer',
      renderColumn: (index) => checkList[index].payerName,
      flex: 1,
    },
    {
      heading: 'Last Checked',
      renderColumn: (index) => dayjs(new Date(checkList[index].lastChecked)).format('MM/DD/YYYY'),
      flex: 1,
    },
    {
      heading: 'Last Checked By',
      renderColumn: (index) => checkList[index].lastCheckedBy,
      flex: 1,
    },
    {
      heading: 'Website',
      renderColumn: (index) => checkList[index].portalLink,
      flex: 1,
    },
    {
      heading: '',
      renderColumn: (index) => (
        <CTAButton
          disabled={isToday(checkList[index].lastChecked)}
          onClick={() => {
            mutateAsync({ id: checkList[index]._id })
              .then((res) => {
                if (res.status === 'ok') {
                  setNewOrderModalConfig({
                    ...newOrderModalConfig,
                    payerDetails: checkList[index],
                    isOpen: true,
                  });
                }

                refetchPortalCheckList();
              })
              .catch(() => {
                refetchPortalCheckList();
              });
          }}
          text="Start"
        />
      ),
      flex: '0 0 120px',
    },
  ];

  const otherTasksColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Date Assigned',
      renderColumn: (index) => dayjs(portalCheckTasks[index].createdAt).format('MM/DD/YYYY HH:mm A'),
      flex: 1,
    },
    { heading: 'Task Title', renderColumn: (index) => portalCheckTasks[index].taskTitle, flex: 1 },
    {
      heading: 'Task Description',
      renderColumn: (index) => portalCheckTasks[index].taskNotes,
      flex: 1,
    },
    {
      heading: 'Assigned By',
      renderColumn: (index) => portalCheckTasks[index].creatorUsername,
      flex: 1,
    },
    {
      heading: '',
      renderColumn: (index) => {
        const isCompleted = portalCheckTasks[index].currentStatus === 'completed';
        const buttonLabel = isCompleted ? 'Completed' : 'Mark as Done';
        return (
          <CTAButton
            invert={isCompleted}
            disabled={isCompleted}
            text={buttonLabel}
            isLoading={isPutUpdatingPortalTask}
            onClick={() => {
              mutatePutUpdatePortalTask({
                ...portalCheckTasks[index],
                id: portalCheckTasks[index]._id,
                currentStatus: 'completed',
              });
            }}
          />
        );
      },
      flex: '0 0 220px',
    },
  ];

  const escalationTasksColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Task Type',
      renderColumn: (index) => escalationTypeToLabel(myEscalationTasks[index].taskType),
    },
    {
      heading: 'Notes',
      renderColumn: (index) => (
        <div style={{ position: 'relative' }}>
          <button
            className="cta-button mod-invert cta-button-note"
            type="button"
            onClick={() => {
              history.push(
                `/task-manager/my-tasks/respond-escalation/${myEscalationTasks[index]._id}?escalationId=${myEscalationTasks[index]._id}`,
              );
            }}
          >
            {myEscalationTasks[index].notes}
          </button>
          {unreadNotes[index] && <div className="cta-button-notification" />}
        </div>
      ),
    },
    {
      heading: 'Escalated date',
      renderColumn: (index) => dayjs(myEscalationTasks[index].createdAt).format('MM/DD/YYYY'),
    },
    { heading: 'Escalated by', renderColumn: (index) => myEscalationTasks[index].createdBy, flex: '0 0 200px' },
    {
      heading: 'Type',
      renderColumn: (index) => myEscalationTasks[index].escalateType,
    },
    {
      heading: 'Escalated to',
      renderColumn: (index) => myEscalationTasks[index].assignTo,
    },
    {
      heading: 'Status',
      renderColumn: (index) => myEscalationTasks[index].status,
    },
    {
      heading: 'Customer ID',
      renderColumn: (index) => myEscalationTasks[index].formDetails?.primaryDetails.customerId,
    },
    {
      heading: 'Product',
      renderColumn: (index) =>
        myEscalationTasks[index].formDetails?.prescriptionDetails?.products
          ? myEscalationTasks[index].formDetails?.prescriptionDetails?.products
              .map((x: any) => `${x.productCategory} - ${x.productItem}`)
              .join(', ')
          : '',
    },
    {
      heading: 'Payer name',
      renderColumn: (index) => myEscalationTasks[index].formDetails?.prescriptionDetails?.primaryPayer || '',
    },
    {
      heading: '',
      renderColumn: (index) =>
        deleteTaskClickedIndex === index ? (
          <div className="Table-row-inline">
            <button
              className="cta-button cta-button-confirm"
              type="button"
              onClick={() => {
                setDeleteTaskClickedIndex(null);
                handleDeleteTaskConfirmation(index);
              }}
              disabled={myEscalationTasks[index].status === 'Completed'}
            >
              Confirm
            </button>
            <button
              className="cta-button mod-invert cta-button-confirm"
              type="button"
              onClick={() => {
                setDeleteTaskClickedIndex(null);
              }}
              disabled={myEscalationTasks[index].status === 'Completed'}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="cta-button mod-invert cta-button-icon"
            type="button"
            onClick={() => {
              setDeleteTaskClickedIndex(index);
            }}
            disabled={myEscalationTasks[index].status === 'Completed'}
          >
            <Delete />
          </button>
        ),
    },
  ];

  const pendingAuthorizationsColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Customer ID',
      renderColumn: (index) => tasks[index]?.primaryDetails.customerId,
    },
    {
      heading: 'Customer Name',
      renderColumn: (index) =>
        formatFullName(tasks[index]?.primaryDetails.firstName, undefined, tasks[index].primaryDetails.lastName),
    },
    {
      heading: 'Payer',
      renderColumn: (index) => tasks[index]?.prescriptionDetails.primaryPayer,
    },
    {
      heading: 'Last Updated',
      renderColumn: (index) => dayjs(new Date(tasks[index]?.updatedAt)).format('MM/DD/YYYY'),
    },
  ];

  const manualTasksColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Title',
      renderColumn: (index) => manualTasks[index]?.title,
    },
    {
      heading: 'Notes',
      renderColumn: (index) => manualTasks[index]?.notes,
    },
    {
      heading: 'Status',
      renderColumn: (index) => manualTasks[index]?.status,
    },
    {
      heading: 'Created At',
      renderColumn: (index) => dayjs(new Date(manualTasks[index]?.createdAt)).format('MM/DD/YYYY hh:mm A'),
    },
    {
      heading: 'Updated At',
      renderColumn: (index) => dayjs(new Date(manualTasks[index]?.updatedAt)).format('MM/DD/YYYY hh:mm A'),
    },
  ];

  const modalStyle: Styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1600,
      width: '85%',
      height: '85%',
      borderRadius: 20,
      margin: 'auto',
      padding: '2rem',
    },
    overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  };

  return (
    <>
      {(newOrderModalConfig.isOpen || createPatientModalConfig.isOpen) && (
        <Prompt message="Are you sure you want to leave this page?" />
      )}
      {/* -------------- Modals -------------- */}
      <CheckOrdersContext.Provider value={{ newOrderModalConfig, setNewOrderModalConfig }}>
        <Modal
          isOpen={newOrderModalConfig.isOpen}
          onRequestClose={() => {
            if (window.confirm('Are you sure you want to stop checking this portal?')) {
              setNewOrderModalConfig({ ...newOrderModalConfig, isOpen: false });
              if (newOrderModalConfig?.payerDetails?._id) mutate({ id: newOrderModalConfig?.payerDetails?._id });
            }
          }}
          style={modalStyle}
        >
          <CheckOrders />
        </Modal>
        <Modal
          isOpen={createPatientModalConfig.isOpen}
          onRequestClose={() => setCreatePatientModalConfig({ ...createPatientModalConfig, isOpen: false })}
          style={modalStyle}
        >
          <CreateNewPatientContext.Provider value={{ createPatientModalConfig, setCreatePatientModalConfig }}>
            <CreateNewPatient />
          </CreateNewPatientContext.Provider>
        </Modal>
      </CheckOrdersContext.Provider>

      <Modal
        isOpen={history.location.pathname.startsWith('/task-manager/my-tasks/respond-escalation/')}
        onRequestClose={() => history.push('/task-manager/my-tasks')}
        style={{ ...modalStyle, content: { ...modalStyle.content, maxWidth: '500px', maxHeight: '700px' } }}
      >
        <RespondEscalation />
      </Modal>

      <Modal
        isOpen={
          history.location.pathname.startsWith('/task-manager/my-tasks/create-task') ||
          history.location.pathname.startsWith('/task-manager/my-tasks/update-task')
        }
        onRequestClose={() => history.push('/task-manager/my-tasks')}
        style={{ ...modalStyle, content: { ...modalStyle.content, maxWidth: '500px', maxHeight: '700px' } }}
      >
        <CreateManualTask isUpdate={history.location.pathname.startsWith('/task-manager/my-tasks/update-task')} />
      </Modal>

      {/* ---------------------------------------- */}
      <DashboardLayout title="My Tasks">
        <div className="MyTasks">
          <div className="MyTasks-new-orders">
            <Card className="MyTasks-new-orders-content" noPadding>
              <div className="MyTasks-new-orders-content-header">
                <p className="MyTasks-new-orders-content-title">{`Escalations (${myEscalationTasksCount})`}</p>
              </div>
              <Table
                loadingRows={isLoadingMyEscalationTasks}
                columnsConfig={escalationTasksColumnsConfig}
                columnWidth={260}
                rowHeight={60}
                itemCount={myEscalationTasks.length}
                getRowKey={useCallback((index) => myEscalationTasks[index]._id, [myEscalationTasks])}
                onRowClick={(id) => {
                  const { escalateType, fileId } = myEscalationTasks.find((task) => task._id === id) || {};
                  history.push(
                    `/task-manager/all-tasks/initiate-${escalateType?.toLowerCase()}/${fileId}?escalationId=${id}`,
                  );
                }}
                renderFixedSizeList={(ListComponent) => (
                  <TableInfiniteScrollWrapper
                    hasNextPage={hasNextPageMyEscalationTasks || false}
                    isNextPageLoading={isFetchingNextPageMyEscalationTasks}
                    items={myEscalationTasks}
                    loadNextPage={() => {
                      setTimeout(() => {
                        fetchNextPageMyEscalationTasks();
                      }, 1000);
                    }}
                  >
                    {({ onItemsRendered, ref, itemCount, renderRow }) => (
                      <ListComponent
                        ref={ref}
                        onItemsRendered={onItemsRendered}
                        itemCount={itemCount}
                        renderRow={renderRow}
                      />
                    )}
                  </TableInfiniteScrollWrapper>
                )}
              />
            </Card>
          </div>
          <div className="MyTasks-new-orders">
            <Card className="MyTasks-new-orders-content" noPadding>
              <div className="MyTasks-new-orders-content-header">
                <p className="MyTasks-new-orders-content-title">{`Pending Authorizations (${submittedTasksCount})`}</p>
              </div>
              <Table
                loadingRows={isLoadingSubmittedTasks}
                columnsConfig={pendingAuthorizationsColumnsConfig}
                rowHeight={100}
                itemCount={tasks.length}
                getRowKey={useCallback((index) => tasks[index]._id, [tasks])}
                renderFixedSizeList={(ListComponent) => (
                  <TableInfiniteScrollWrapper
                    hasNextPage={hasNextPageSubmittedTasks || false}
                    isNextPageLoading={isFetchingNextPageSubmittedTasks}
                    items={tasks}
                    loadNextPage={() => {
                      setTimeout(() => {
                        fetchNextPageSubmittedTasks();
                      }, 1000);
                    }}
                  >
                    {({ onItemsRendered, ref, itemCount, renderRow }) => (
                      <ListComponent
                        ref={ref}
                        onItemsRendered={onItemsRendered}
                        itemCount={itemCount}
                        renderRow={renderRow}
                      />
                    )}
                  </TableInfiniteScrollWrapper>
                )}
              />
            </Card>
          </div>
          <div className="MyTasks-new-orders">
            <Card className="MyTasks-new-orders-content" noPadding>
              <div className="MyTasks-new-orders-content-header">
                <p className="MyTasks-new-orders-content-title">{`Manual Tasks (${manualTasks.length})`}</p>
                <div className="MyTasks-manual-tasks-toolbar">
                  <CTAButton
                    text="Create Task"
                    onClick={() => history.push('/task-manager/my-tasks/create-task')}
                    rectangled
                  />
                </div>
              </div>
              <Table
                loadingRows={isLoadingManualTasks}
                columnsConfig={manualTasksColumnsConfig}
                rowHeight={60}
                itemCount={manualTasks.length}
                getRowKey={useCallback((index) => manualTasks[index]._id, [manualTasks])}
                onRowClick={(id) => history.push(`/task-manager/my-tasks/update-task/${id}`)}
              />
            </Card>
          </div>

          <div className="MyTasks-new-orders">
            <Card className="MyTasks-new-orders-content" noPadding>
              <div className="MyTasks-new-orders-content-header">
                <p className="MyTasks-new-orders-content-title">{`Portal check for new orders (${notificationsCount})`}</p>
              </div>
              <Table
                loadingRows={isLoadingPortalCheckList}
                columnsConfig={newOrdersColumnsConfig}
                rowHeight={60}
                itemCount={checkList.length}
                getRowKey={useCallback((index) => checkList[index]._id, [checkList])}
              />
            </Card>
          </div>
          <div className="MyTasks-other-tasks">
            <Card
              title={`Other tasks (${totalUncompletedTasksCount})`}
              className="MyTasks-other-tasks-content"
              noPadding
            >
              <Table
                loadingRows={isLoadingPortalCheckTasks}
                columnsConfig={otherTasksColumnsConfig}
                rowHeight={100}
                itemCount={portalCheckTasks.length}
                getRowKey={useCallback((index) => portalCheckTasks[index]._id, [portalCheckTasks])}
                renderFixedSizeList={(ListComponent) => (
                  <TableInfiniteScrollWrapper
                    hasNextPage={hasNextPage || false}
                    isNextPageLoading={isFetchingNextPage}
                    items={portalCheckTasks}
                    loadNextPage={() => {
                      setTimeout(() => {
                        fetchNextPage();
                      }, 1000);
                    }}
                  >
                    {({ onItemsRendered, ref, itemCount, renderRow }) => (
                      <ListComponent
                        ref={ref}
                        onItemsRendered={onItemsRendered}
                        itemCount={itemCount}
                        renderRow={renderRow}
                      />
                    )}
                  </TableInfiniteScrollWrapper>
                )}
              />
            </Card>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default MyTasks;
