/* eslint-disable react/jsx-one-expression-per-line */
import useWindowDimensions from 'hooks/useWindowDimensions';
import { RootState } from 'MyTypes';
import React, { Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { getCurrentSspUserDetails, getS3ContentBuffer, getUserFormDetails } from '../../../../../../api';
import { Card, NavigationTabs } from '../../../../../../components';
import { DashboardLayout } from '../../../../../../layouts';
import { ViewSSPUserDetails } from '../../../../../../models';
import { ViewAllUsersFormsInterface } from '../../../../../../models/ViewAllUsersFormsInterface';
import { selectCurrentUserForm } from '../../../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import { selectSspUserByCustomerId } from '../../../../../../store/SspUsersDuck/duck/selector';
import Details from './components/Details';
import SSPForm from './components/SSPForm/SSPForm';
import FormDetailsContext from './context/FormDetailsContext';
import './FormDetails.scss';
import i18n from './i18n';

interface Props {
  id: string;
  currentForm?: ViewAllUsersFormsInterface | null;
  currentCustomer?: ViewSSPUserDetails | null;
}

const FormDetails = ({
  id,
  currentForm: stateCurrentForm,
  currentCustomer: stateCurrentCustomer,
}: Props): JSX.Element => {
  const [currentForm, setCurrentForm] = useState(stateCurrentForm);
  const [currentCustomer, setCurrentCustomer] = useState(stateCurrentCustomer);
  const [currentPdf, setCurrentPdf] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const { device } = useWindowDimensions();
  const [showPreview, setShowPreview] = useState(device === 'desktop');

  /* ---------- Get User Information ---------- */
  useEffect(() => {
    if (!currentForm) getUserFormDetails(id).then((res) => setCurrentForm(res));
    if (!currentCustomer && currentForm?.customerId)
      getCurrentSspUserDetails(undefined, currentForm?.customerId).then((res) => {
        if (res) setCurrentCustomer(res);
      });
  }, [currentCustomer, currentForm, id]);

  /* ---------- Get Base64 PDF via content-proxy ---------- */
  useEffect(() => {
    if (!currentForm?.formPdf) return;
    if (currentForm.formPdf.startsWith('https://')) {
      setCurrentPdf(currentForm.formPdf);
      return;
    }
    getS3ContentBuffer(currentForm?.formPdf).then((res) => {
      const blob = res.slice(0, res.size, 'application/pdf');
      const dataUrl = URL.createObjectURL(blob);
      setCurrentPdf(dataUrl);
    });
  }, [currentForm]);

  /* ---------- Content ---------- */
  const title = currentForm ? `${currentForm?.formTitle} - ${currentForm?.username}` : '';

  const userInfoDetails = currentCustomer
    ? Object.keys(currentCustomer).map((key) => ({
        key,
        value: `${currentCustomer?.[key as keyof ViewSSPUserDetails]}`,
      }))
    : [];

  const currentFormDetails = currentForm
    ? Object.keys(currentForm).map((key) => ({
        key,
        value: `${currentForm?.[key as keyof ViewAllUsersFormsInterface]}`,
      }))
    : [];

  const renderTabsList = () => {
    const tabList: string[] = ['Details'];
    if (currentForm?.adminPrefillRequired && !currentForm?.signed && !currentForm?.adminPrefilled) {
      tabList.push('Internal Pre-fill');
    }
    if (!currentForm?.signed) {
      tabList.push('Sign For Patient');
    }
    if (currentForm?.adminReviewRequired && currentForm?.signed && !currentForm?.adminSigned) {
      tabList.push('Internal/Witness Review');
    }
    return tabList;
  };
  const tabsList = renderTabsList();

  const renderFormLoading = () => (
    <div className="FormDetails-loading">
      <ClipLoader color="#06bbc9" />
    </div>
  );

  return (
    <I18nextProvider i18n={i18n}>
      <DashboardLayout title={title}>
        <Card noPadding className="FormDetails">
          <FormDetailsContext.Provider
            value={{
              currentCustomer: currentCustomer || ({} as ViewSSPUserDetails),
              currentForm: currentForm || ({} as ViewAllUsersFormsInterface),
            }}
          >
            {!currentForm || !currentCustomer ? (
              renderFormLoading()
            ) : (
              <div className="FormDetails-container">
                <div
                  className={`FormDetails-container-information ${
                    tabIndex === 1 || tabIndex === 2 ? 'mod-SSPForms' : ''
                  } ${showPreview ? '' : 'mod-preview-hidden'}`}
                >
                  {tabsList.length > 1 && (
                    <NavigationTabs tabs={tabsList} tabIndex={tabIndex} onChange={(e) => setTabIndex(e)} flexAllTabs />
                  )}
                  <Suspense fallback={renderFormLoading()}>
                    {(() => {
                      switch (tabsList[tabIndex]) {
                        case 'Details':
                          return <Details currentFormDetails={currentFormDetails} userInfoDetails={userInfoDetails} />;
                        case 'Sign For Patient':
                          return <SSPForm mode="all" />;
                        case 'Internal Pre-fill':
                          return <SSPForm mode="prefill" />;
                        case 'Internal/Witness Review':
                          return <SSPForm mode="admin" />;
                        default:
                          return <></>;
                      }
                    })()}
                  </Suspense>
                </div>
                <div className={`FormDetails-container-preview ${showPreview ? '' : 'mod-hidden'}`}>
                  <button
                    type="button"
                    className="FormDetails-container-preview-expand-button"
                    onClick={() => setShowPreview((v) => !v)}
                  >
                    {showPreview ? 'Hide' : 'Show'}&nbsp;Original Form
                  </button>
                  <iframe
                    className="FormDetails-container-preview-pdf"
                    title="preview-pdf "
                    src={currentPdf || undefined}
                  />
                  {showPreview && (
                    <div className={`FormDetails-loading mod-preview ${showPreview ? '' : 'mod-hidden'}`}>
                      <ClipLoader color="#06bbc9" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </FormDetailsContext.Provider>
        </Card>
      </DashboardLayout>
    </I18nextProvider>
  );
};

const mapStateToProps = (state: RootState, props: any): Pick<Props, 'id' | 'currentForm' | 'currentCustomer'> => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return {
    id,
    currentForm: selectCurrentUserForm(state, id),
    currentCustomer: selectSspUserByCustomerId(state, selectCurrentUserForm(state, id)?.customerId || ''),
  };
};

export default connect(mapStateToProps)(FormDetails);
