import { CTAButton } from 'components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CheckOrdersFormContext from '../../../context/CheckOrdersFormContext';

interface Props {
  formId: string;
  orderId: string;
  handleDeleteDocument: (orderId: string, documentName: string) => void;
}
const ViewDocuments = ({ formId, orderId, handleDeleteDocument }: Props) => {
  const { watch } = useContext(CheckOrdersFormContext);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);

  const documents: FileList = watch(`${formId}.${orderId}.uploadDocuments`);

  const selectedDocumentBlob = useMemo(() => {
    const selectedDocument = documents[selectedDocumentIndex];
    if (selectedDocument) {
      return URL.createObjectURL(selectedDocument);
    }
    return null;
  }, [documents, selectedDocumentIndex]);

  const handleDocumentChange = (index: number) => {
    setSelectedDocumentIndex(index);
  };

  useEffect(() => {
    if (selectedDocumentBlob) return;
    setSelectedDocumentIndex(0);
  }, [selectedDocumentBlob]);

  return (
    <div className="MyTasks-CheckOrders-modal-new-orders-view-documents">
      <iframe className="preview-container" title="preview-container" src={selectedDocumentBlob || ''} />
      <div className="MyTasks-CheckOrders-modal-new-orders-view-documents-list-container">
        <div className="MyTasks-CheckOrders-modal-new-orders-view-documents-list-container-list">
          {Array.from(documents).map((document, index) => (
            <div
              key={`${document.name}-${index.toString()}`}
              className="MyTasks-CheckOrders-modal-new-orders-view-documents-list-container-list-item"
            >
              <button
                onClick={() => handleDeleteDocument(orderId, document.name)}
                type="button"
                className="MyTasks-CheckOrders-modal-new-orders-new-order-form-remove-btn"
              >
                x
              </button>
              <CTAButton
                text={`${document.name}`}
                onClick={() => handleDocumentChange(index)}
                invert={selectedDocumentIndex !== index}
              />
            </div>
          ))}
        </div>
        <div className="MyTasks-CheckOrders-modal-new-orders-view-documents-list-container-upload-btn-container">
          <CTAButton
            onClick={() => {
              const input = document.getElementById(`${formId}.${orderId}.uploadDocuments`) as HTMLInputElement;
              input.click();
            }}
            text="Upload"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewDocuments;
