import { Card, ColumnConfig, Table } from 'components';
import { DashboardLayout } from 'layouts';

import { useCallback } from 'react';
import useFetchInsurancePayers from 'api/hooks/useFetchInsurancePayers';

const Authorizations = () => {
  const {
    insurancePayers,
    query: { isLoading },
  } = useFetchInsurancePayers();
  const columnConfig: ColumnConfig[] = [
    {
      heading: 'Payer name',
      flex: '0 0 200px',
      renderColumn: (index) => insurancePayers[index].payerName,
    },
    {
      heading: 'Request auth',
      flex: '0 0 200px',
      renderColumn: (index) => insurancePayers[index].requestAuth,
    },
    {
      heading: 'Receive auth',
      flex: '0 0 200px',
      renderColumn: (index) => insurancePayers[index].receiveAuth,
    },
    {
      heading: 'Check auth',
      flex: '0 0 200px',
      renderColumn: (index) =>
        Array.isArray(insurancePayers[index].checkAuth)
          ? (insurancePayers[index].checkAuth as string[]).join('/')
          : insurancePayers[index].checkAuth,
    },
    {
      heading: 'Portal link',
      renderColumn: (index) => insurancePayers[index].portalLink,
      flex: '0 0 520px',
    },
  ];

  return (
    <DashboardLayout title="Authorizations">
      <Card noPadding>
        <Table
          loadingRows={isLoading}
          rowHeight={80}
          itemCount={insurancePayers.length}
          columnsConfig={columnConfig}
          getRowKey={useCallback((index) => insurancePayers[index]?.payerName, [insurancePayers])}
        />
      </Card>
    </DashboardLayout>
  );
};

export default Authorizations;
