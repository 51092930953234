import { useFetchPortalCheckSearchPatient } from 'api/hooks';
import { ColumnConfig, InputTextField, Table, TableInfiniteScrollWrapper } from 'components';
import dayjs from 'dayjs';
import { IPortalCheckSearchPatientResponseData } from 'models';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import formatFullName from 'utils/formatFullName';
import { Info } from '@mui/icons-material';
import { format, parse } from 'date-fns';

interface Props {
  handleSetPatient: (patient: IPortalCheckSearchPatientResponseData) => void;
}

export const SearchPatient = memo<Props>(({ handleSetPatient }) => {
  const [selectedPatientTableIndex, setSelectedPatientIndex] = useState('');
  const [displayDobValue, setDisplayDobValue] = useState<string | null>(null);

  const { register, getValues } = useForm();

  const {
    patients,
    setFirstNameValue,
    setLastNameValue,
    setDateOfBirthValue,
    query: { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage },
  } = useFetchPortalCheckSearchPatient();

  const handleDateOfBirthValue = (dateOfBirthValue: string) => {
    let nextDateOfBirthValue: Date | null = null;
    if (dateOfBirthValue) {
      const displayDateOfBirthValue = dateOfBirthValue;
      setDisplayDobValue(displayDateOfBirthValue);

      nextDateOfBirthValue = dayjs(displayDateOfBirthValue).toDate();
    }

    setDateOfBirthValue(nextDateOfBirthValue);
  };

  const handleFormChange = () => {
    const values = getValues();
    setFirstNameValue(values.firstName);
    setLastNameValue(values.lastName);
    handleDateOfBirthValue(values.dateOfBirth);
  };

  const tableColumnsConfig: ColumnConfig[] = [
    { heading: 'Customer ID', renderColumn: (index) => patients[index].customerid },
    {
      heading: 'First Name',
      renderColumn: (index) => formatFullName(patients[index].firstname, patients[index].middleinitial),
    },
    {
      heading: 'Last Name',
      renderColumn: (index) => patients[index].lastname,
    },
    {
      heading: 'Date of Birth',
      renderColumn: (index) =>
        patients[index]?.dateofbirth
          ? format(parse(patients[index].dateofbirth, 'yyyyMMdd', new Date()), 'MM/dd/yyyy')
          : '',
    },
    {
      heading: '',
      renderColumn: (index) => (
        <Info
          onClick={() => {
            window.open(`/customers/${patients[index]._id}`);
          }}
        />
      ),
    },
  ];

  return (
    <div className="MyTasks-CheckOrders-modal-new-orders-search-patient">
      <div className="MyTasks-CheckOrders-modal-new-orders-search-patient-container">
        <form
          className="MyTasks-CheckOrders-modal-new-orders-search-patient-container-form"
          onChange={handleFormChange}
        >
          <InputTextField {...register('firstName')} type="text" label="First Name" />
          <InputTextField {...register('lastName')} type="text" label="Last Name" />
          <InputTextField
            {...register('dateOfBirth')}
            type="text"
            label="Date of Birth"
            placeholder="YYYY-MM-DD"
            value={displayDobValue || ''}
          />
        </form>

        <div
          className="MyTasks-CheckOrders-modal-new-orders-search-patient-container-table"
          style={{ maxHeight: selectedPatientTableIndex ? 200 : undefined }}
        >
          <Table
            loadingRows={isLoading}
            columnsConfig={tableColumnsConfig}
            columnWidth={200}
            rowHeight={80}
            itemCount={patients.length}
            getRowKey={useCallback((index) => patients[index]?._id, [patients])}
            selectedRowKeys={[selectedPatientTableIndex]}
            onRowClick={(id) => {
              const index = patients.findIndex((patient) => patient._id === id);
              handleSetPatient(patients[index]);
              setSelectedPatientIndex(patients[index]._id);
            }}
            renderFixedSizeList={(ListComponent) => (
              <TableInfiniteScrollWrapper
                hasNextPage={hasNextPage || false}
                isNextPageLoading={isFetchingNextPage}
                items={patients}
                loadNextPage={() => {
                  setTimeout(() => {
                    fetchNextPage();
                  }, 1000);
                }}
              >
                {({ onItemsRendered, ref, itemCount, renderRow }) => (
                  <ListComponent
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    itemCount={itemCount}
                    renderRow={renderRow}
                  />
                )}
              </TableInfiniteScrollWrapper>
            )}
          />
        </div>
      </div>
    </div>
  );
});

SearchPatient.displayName = 'SearchPatient';

export default SearchPatient;
