import { Card } from 'components';
import { DashboardLayout } from 'layouts';
import styles from './OrderDetails.module.scss';

const OrderDetails = () => {
  const data = {
    id: 1,
    payerName: 'CIGNA',
    ordersCount: 2,
    checkedBy: 'John Doe',
  };

  const details = [
    { label: 'ID', value: data.id },
    {
      label: 'Payer Name',
      value: data.payerName,
    },
    {
      label: 'Orders',
      value: data.ordersCount,
    },
    {
      label: 'Checked By',
      value: data.checkedBy,
    },
  ];

  return (
    <DashboardLayout>
      <Card>
        <p className={styles.title}>Details</p>
        <div className={styles.container}>
          {details.map(({ label, value }) => (
            <div className={styles.details} key={label}>
              <span>{label}</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default OrderDetails;
