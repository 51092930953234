import { getIcd10CmList } from 'api';
import { Card, ColumnConfig, CTAButton, InputTextField, Table } from 'components';
import { DashboardLayout } from 'layouts';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import './ICD10CM.scss';

const ICD10CM = () => {
  const { register } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [isResettingUsers, setIsResettingUsers] = useState(false);

  const {
    data: searchData,
    refetch,
    isLoading,
  } = useQuery(['searchIcd10Cm', searchTerm], () => getIcd10CmList(searchTerm));

  const searchResults = useMemo(() => {
    if (searchData && searchData.length > 3) {
      return searchData[3].map(([code, name]: any) => ({
        code,
        name,
      }));
    }
    return [];
  }, [searchData]);

  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Code',
      renderColumn: (index) => searchResults[index]?.code,
      flex: '0 0 100px',
    },
    {
      heading: 'Name',
      renderColumn: (index) => searchResults[index]?.name,
      flex: 1,
    },
  ];

  const handleSearch = useCallback((query) => {
    setSearchTerm(query);
  }, []);

  const resetStatements = useCallback(async () => {
    setIsResettingUsers(true);
    await refetch();
    setIsResettingUsers(false);
  }, [refetch]);

  return (
    <DashboardLayout title="ICD-10-CM Directory">
      <Card className="ICD10CM" noPadding>
        <div className="ICD10CM-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search ICD-10-CM code..."
            type="text"
            className="ICD10CM-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch(e.currentTarget.value);
              }
            }}
          />
          <div className="ICD10CM-header-reset-container">
            <span className="ICD10CM-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="ICD10CM-header-reset-container-text">
              1&nbsp; - &nbsp;
              {searchResults.length}
            </span>

            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={useCallback(() => resetStatements(), [resetStatements])}
              disabled={isResettingUsers}
              className="ICD10CM-header-reset-container-refresh-button"
            />
          </div>
        </div>
        <Table
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          itemCount={searchResults.length}
          getRowKey={useCallback((index) => searchResults[index]?.code, [searchResults])}
          loadingRows={isLoading}
        />
      </Card>
    </DashboardLayout>
  );
};

export default ICD10CM;
