import { getAllInsurancePayers } from 'api';
import { IInsurancePayer, IInsurancePayersSuccessResponse } from 'models';
import { useQuery, UseQueryResult } from 'react-query';

interface ReturnType {
  query: UseQueryResult<IInsurancePayersSuccessResponse, unknown>;
  insurancePayers: IInsurancePayer[];
}

export const useFetchInsurancePayers = (): ReturnType => {
  const query = useQuery('useFetchInsurancePayers', getAllInsurancePayers, {
    refetchOnWindowFocus: false,
  });

  const insurancePayers = query.data?.data || [];

  return { query, insurancePayers };
};
export default useFetchInsurancePayers;
