/* eslint-disable no-param-reassign */
import React, { CSSProperties, forwardRef, memo, ReactNode, Ref, useMemo, useRef } from 'react';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';
import AutoSizer, { AutoSizerProps } from 'react-virtualized-auto-sizer';
import './Table.scss';
import { ClipLoader } from 'react-spinners';
import useWindowDimensions from 'hooks/useWindowDimensions';

export interface ColumnConfig {
  heading: ReactNode;
  renderColumn: (index: number) => string | ReactNode;
  flex?: CSSProperties['flex'];
  textAlign?: CSSProperties['textAlign'];
  className?: string;
  headingClassName?: string;
  onColumnClick?: () => void;
}

export interface OverrideRowRendererProps extends ListChildComponentProps {
  renderDefault: () => ReactNode;
}
export type OverrideRowRenderer = (rowProps: OverrideRowRendererProps) => ReactNode;

export interface RenderFixedSizeListProps extends Partial<FixedSizeListProps> {
  ref?: Ref<any>;
  // Override normal renderer from columnsConfig
  renderRow?: OverrideRowRenderer;
}

export interface Props extends Partial<Omit<FixedSizeListProps, 'ref'>> {
  itemCount: number;
  rowHeight: number;
  columnsConfig: ColumnConfig[];
  getRowKey: (index: number) => string | number;
  columnWidth?: number;
  // Override normal renderer from columnsConfig
  renderRow?: OverrideRowRenderer;
  selectedRowKeys?: (string | number)[];
  loadingRows?: boolean;
  onRowClick?: (rowProps: any) => void;
  renderFixedSizeList?: (ListComponent: React.FC<RenderFixedSizeListProps>) => ReactNode;
}

export const Table = memo<Props>(
  ({
    loadingRows,
    itemCount: propItemCount,
    getRowKey,
    selectedRowKeys,
    rowHeight,
    columnsConfig,
    overscanCount = 10,
    columnWidth: initialColumnWidthProp,
    renderRow: propRenderRow,
    onRowClick,
    renderFixedSizeList,
    ...restFixedSizeListProps
  }) => {
    const { device } = useWindowDimensions();

    const columnWidth = useMemo(() => {
      if (device === 'mobile' || device === 'tablet') return 250;

      if (initialColumnWidthProp) return initialColumnWidthProp;

      return undefined;
    }, [device, initialColumnWidthProp]);

    const renderRow = (rowProps: ListChildComponentProps, overrideRenderRow?: OverrideRowRenderer) => {
      const { index, style } = rowProps;
      const key = getRowKey(index);

      const handleRowClick = () => {
        if (!onRowClick) return;
        onRowClick(getRowKey(index));
      };

      const fetchRowClassName = (value: string | boolean) => {
        switch (typeof value) {
          case 'boolean':
            if (value) return 'mod-boolean-true';
            return 'mod-boolean-false';
          case 'string':
            if (value.startsWith('http')) return 'mod-link';
            return '';
          default:
            return '';
        }
      };

      const renderDefault = () =>
        columnsConfig.map(({ flex, heading, renderColumn, textAlign, className = '' }) => {
          if (`${renderColumn(index)}`.startsWith('http')) {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${renderColumn(index)}`}
                key={`${key}-${heading}`}
                className={`Table-body-row-content-column ${className} ${fetchRowClassName(
                  renderColumn(index) as boolean | string,
                )}`}
                style={{ flex, textAlign, minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}
              >
                {`${renderColumn(index)}`}
              </a>
            );
          }

          return (
            <div
              onClick={typeof renderColumn(index) === 'object' ? undefined : handleRowClick}
              key={`${key}-${heading}`}
              className={`Table-body-row-content-column ${className} ${fetchRowClassName(
                renderColumn(index) as boolean | string,
              )}`}
              style={{ flex, textAlign, minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}
            >
              {typeof renderColumn(index) === 'string' || typeof renderColumn(index) === 'boolean'
                ? `${renderColumn(index)}`
                : renderColumn(index)}
            </div>
          );
        });

      return (
        <div
          key={key}
          style={{ ...style, cursor: onRowClick ? 'pointer' : undefined }}
          className={`Table-body-row ${key && selectedRowKeys?.includes(key) ? 'mod-active' : ''}`}
        >
          <div className="Table-body-row-content" key={key}>
            {(() => {
              if (overrideRenderRow) return overrideRenderRow({ ...rowProps, renderDefault });
              if (propRenderRow) return propRenderRow({ ...rowProps, renderDefault });
              return renderDefault();
            })()}
          </div>
        </div>
      );
    };

    const renderHeader = () => (
      <div className="Table-header" style={columnWidth ? { width: 'unset', alignSelf: 'flex-start' } : undefined}>
        {columnsConfig.map(({ flex, heading, textAlign, onColumnClick, headingClassName = '' }) => (
          <div
            key={`${heading}`}
            className={`Table-header-column ${headingClassName} ${onColumnClick ? 'mod-clickable' : ''}`}
            onClick={onColumnClick}
            style={{
              flex,
              textAlign,
              maxWidth: columnWidth ? `${columnWidth}px` : undefined,
              minWidth: columnWidth ? `${columnWidth}px` : undefined,
            }}
          >
            {heading}
          </div>
        ))}
      </div>
    );

    const renderLoading = () => (
      <div className="Table-loading-rows">
        <ClipLoader color="#06bbc9" />
      </div>
    );

    const listRef = useRef<HTMLDivElement | null>(null);

    const renderBody = () => {
      if (propItemCount === 0) {
        return <p className="Table-no-records">No records found</p>;
      }

      const renderList: AutoSizerProps['children'] = ({ width, height }) => {
        const ListComponent = forwardRef<any, RenderFixedSizeListProps>(
          ({ renderRow: overrideRenderRow, ...extraProps }, ref) => {
            return (
              <FixedSizeList
                {...restFixedSizeListProps}
                ref={ref}
                outerRef={listRef}
                width={width}
                height={height}
                overscanCount={overscanCount}
                itemSize={rowHeight}
                itemCount={propItemCount}
                // @TODO: Prevent list re-mount instead
                // Fix scroll always reset to top after list re-mount
                initialScrollOffset={listRef.current?.scrollTop}
                {...extraProps}
              >
                {(rowProps) => renderRow(rowProps, overrideRenderRow)}
              </FixedSizeList>
            );
          },
        );
        ListComponent.displayName = 'Table.ListComponent';
        return renderFixedSizeList ? renderFixedSizeList(ListComponent) : <ListComponent />;
      };
      return (
        <div
          className="Table-body"
          style={
            columnWidth
              ? { width: `calc(${columnsConfig.length * columnWidth}px + 4rem)`, overflowY: 'scroll' }
              : undefined
          }
        >
          <AutoSizer>{renderList}</AutoSizer>
        </div>
      );
    };

    return (
      <div className="Table" style={columnWidth ? { overflowX: 'scroll', overflowY: 'hidden' } : undefined}>
        {renderHeader()}
        {loadingRows ? renderLoading() : renderBody()}
      </div>
    );
  },
);

Table.displayName = 'Table';

export default Table;
