import { DropboxFile } from 'models';
import { createContext, Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export type InitiateEmailFaxOrderContextProps = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  form: UseFormReturn<FieldValues, any>;
  currentFile: DropboxFile;
  isEscalateModalOpen: boolean;
  setIsEscalateModalOpen: Dispatch<SetStateAction<boolean>>;
  isSaveDraftModalOpen: boolean;
  setIsSaveDraftModalOpen: Dispatch<SetStateAction<boolean>>;
};

const InitiateEmailFaxOrderContext = createContext<InitiateEmailFaxOrderContextProps>(
  {} as InitiateEmailFaxOrderContextProps,
);

export default InitiateEmailFaxOrderContext;
