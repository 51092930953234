import { getAllPortalCheckTasks } from 'api';
import { IPortalCheckTasksResponseData, IPortalCheckTasksSuccessResponse } from 'models';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import getNextPageParam from 'utils/getNextPageParam';
import getPreviousPageParam from 'utils/getPreviousPageParam';

const PAGE_SIZE = 20;

export const useFetchPortalCheckTasks = () => {
  const query: UseInfiniteQueryResult<IPortalCheckTasksSuccessResponse> = useInfiniteQuery(
    'useFetchPortalCheckTasks',
    ({ pageParam = 1 }) => getAllPortalCheckTasks({ limit: PAGE_SIZE, skip: (pageParam - 1) * PAGE_SIZE }),
    {
      keepPreviousData: true,
      getNextPageParam,
      getPreviousPageParam,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const portalCheckTasks = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.reduce((acc, page) => [...acc, ...page?.data], [] as IPortalCheckTasksResponseData[]);
  }, [query.data]);

  const totalCount = useMemo(() => {
    if (!query.data) return 0;
    return query.data.pages[0].pagination.totalCount;
  }, [query.data]);

  const totalUncompletedTasksCount = useMemo(() => {
    if (!query.data) return 0;
    return query.data.pages[0].count.totalUncompletedTasksCount;
  }, [query.data]);

  return { query, portalCheckTasks, totalCount, totalUncompletedTasksCount };
};

export default useFetchPortalCheckTasks;
