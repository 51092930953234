import { getSearchProducts } from 'api';
import useDebounceState from 'hooks/useDebounceState';
import { Product, ProductsSuccessResponse } from 'models';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import getNextPageParam from 'utils/getNextPageParam';
import getPreviousPageParam from 'utils/getPreviousPageParam';

export const useFetchSearchProducts = () => {
  const [debouncedSearchValue, setDebouncedSearchValue, searchValue, setSearchValue] = useDebounceState('', 500);
  const query: UseInfiniteQueryResult<ProductsSuccessResponse> = useInfiniteQuery(
    ['useFetchSearchProducts', { debouncedSearchValue }],
    ({ pageParam = 0 }) =>
      getSearchProducts({
        limit: 20,
        skip: pageParam,
        query: debouncedSearchValue,
      }),
    {
      keepPreviousData: true,
      getNextPageParam,
      getPreviousPageParam,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const products = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.reduce((acc, page) => [...acc, ...page?.data], [] as Product[]);
  }, [query.data]);

  return { query, products, debouncedSearchValue, setDebouncedSearchValue, searchValue, setSearchValue };
};
export default useFetchSearchProducts;
