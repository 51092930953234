import { Edit, Launch } from '@mui/icons-material';
import usePostCreateSubmitTask from 'api/hooks/usePostCreateSubmitTask';
import { CTAButton } from 'components';
import { useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useHistory } from 'react-router';
import camelCaseToText from 'utils/camelCaseToText';
import useFetchPhysician from 'api/hooks/useFetchPhysician';
import InitiateEmailFaxOrderContext from '../../context';
import styles from './Overview.module.scss';
import FaxCoverSection from './FaxCoverSection/FaxCoverSection';

const Overview = () => {
  const history = useHistory();
  const { form, setCurrentStep, setIsEscalateModalOpen, setIsSaveDraftModalOpen } =
    useContext(InitiateEmailFaxOrderContext);
  const { onPhysicianSearch, physicians } = useFetchPhysician();
  const { getValues, setValue, watch } = form;

  const overviewType = watch('authorization.authorizationMethod').toUpperCase();

  const { isLoading, mutate } = usePostCreateSubmitTask({
    onSuccess: () => {
      history.push('/task-manager/all-tasks/');
    },
  });

  const [physicianValue, setPhysicianValue] = useState<string>('');
  const physician = watch('prescriptionDetails.physician');

  useEffect(() => {
    onPhysicianSearch(physician);
    setPhysicianValue(
      physicians[0] ? `${physicians[0][0]} - ${physicians[0][3]} - ${physicians[0][1]} - ${physicians[0][4]}` : '',
    );
  }, [physician, physicians, onPhysicianSearch]);

  return (
    <div className={styles.container}>
      <span className={styles.containerTitle}>
        Overview
        <CTAButton text={overviewType} rectangled />
      </span>
      {Object.keys(getValues()).map((formKey, formIndex) => {
        if (formKey === 'portalOverview' || formKey === 'coverPDF') return null;

        return (
          <div key={formKey} className={styles.detailsContainer}>
            <span className={styles.sectionTitle}>
              {camelCaseToText(formKey)}
              <Edit onClick={() => setCurrentStep(formIndex)} className={styles.editButton} />
            </span>
            <div className={styles.detailsTable}>
              {Object.keys(getValues()[formKey]).map((key) => {
                const title = `${camelCaseToText(key)}:`;
                let value = getValues()[formKey][key];

                if (!value || value === '') {
                  return <></>;
                }

                if (Array.isArray(value)) {
                  // check if value is an array of objects
                  return (
                    <div key={key} className={styles.details}>
                      <span className={styles.title}>{title}</span>
                      <div className={styles.value}>
                        {value.map((item, index) => (
                          <>
                            <span>{(index + 1).toString()}</span>
                            <div className={styles.valueItem} key={(index + 1).toString()}>
                              {Object.keys(item).map((itemKey) => (
                                <div key={itemKey}>
                                  <span>{`${camelCaseToText(itemKey)}: `}</span>
                                  <span>{item[itemKey]}</span>
                                </div>
                              ))}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  );
                }

                if (value instanceof FileList) {
                  return (
                    <div key={key} className={styles.details}>
                      <span className={styles.title}>{title}</span>
                      <div className={styles.value}>
                        {Array.from(value).map((file, index) => (
                          <div key={file.name} className={styles.valueItem}>
                            <span>{(index + 1).toString()}</span>
                            <span>{file.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }

                if (title?.toUpperCase() === 'PHYSICIAN:') {
                  value = physicianValue || value;
                }

                return (
                  <div key={key} className={styles.details}>
                    <span className={styles.title}>{title}</span>
                    <span className={styles.value}>{value.toString()}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {overviewType === 'PORTAL' && (
        <div className={styles.detailsContainer}>
          <span className={styles.sectionTitle}>Submission Instructions</span>
          <div className={styles.portalSubmissionInstructionsContainer}>
            <div>
              <CTAButton text="STEP 1" invert rectangled onClick={() => null} />
              <span className={styles.instructionsDescription}>
                Initiate the authorization request form by going to:
              </span>
              <a
                href={getValues('authorization.authorizationMethodDetails')}
                className={styles.portalLink}
                target="_blank"
                rel="noreferrer"
              >
                <Launch className={styles.launchBtn} />
                {getValues('authorization.authorizationMethodDetails')}
              </a>
            </div>
            <div>
              <CTAButton text="STEP 2" invert rectangled onClick={() => null} />
              <span className={styles.instructionsDescription}>
                Once you have completed the request submission, take a screenshot and upload here.
              </span>
              <div className={styles.fileUploader}>
                <FileUploader
                  multiple
                  handleChange={(files: FileList) => setValue('portalOverview.screenshots', files)}
                  name="file"
                  types={['JPG', 'JPEG', 'PNG']}
                  styles={{ backgroundColor: 'red' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {overviewType === 'FAX' && <FaxCoverSection />}

      {/* {overviewType === 'PHONE' && (
        <div className={styles.detailsContainer}>
          <span className={styles.sectionTitle}>Submission Instructions</span>
          <div className={styles.portalSubmissionInstructionsContainer}>
            <div>
              <CTAButton text="STEP 1" invert rectangled onClick={() => null} />
              <span className={styles.instructionsDescription}>
                Initiate the authorization request form by calling:
              </span>
              <div className={styles.portalLink}>{getValues('authorization.authorizationMethodDetails')}</div>
            </div>
            <div>
              <CTAButton text="STEP 2" invert rectangled onClick={() => null} />
              <div className={styles.checkboxContainer}>
                <div className={styles.inputField}>
                  <Checkbox
                    {...register('portalOverview.callComplete', {
                      required: true,
                    })}
                    checked={watch('portalOverview.callComplete')}
                    onChange={(e) => {
                      setValue('portalOverview.callComplete', e.target.checked || false);
                    }}
                  />
                  <span>Yes, the call is complete</span>
                </div>
              </div>
              <InputTextField
                className={styles.inputField}
                {...register('portalOverview.callNotes')}
                label="Notes"
                type="textarea"
                maxLength={240}
              />
            </div>
          </div>
        </div>
      )} */}

      <div className={styles.ctaContainer}>
        <div>
          <CTAButton rectangled text="Back" onClick={() => setCurrentStep((prev) => prev - 1)} invert />
        </div>
        <div>
          <CTAButton text="Escalate" onClick={() => setIsEscalateModalOpen(true)} invert rectangled />
          <CTAButton text="Save Draft" onClick={() => setIsSaveDraftModalOpen(true)} invert rectangled />
          <CTAButton
            text={overviewType === 'FAX' ? 'Send' : 'Finish'}
            onClick={() => {
              const formData = new FormData();

              Object.keys(getValues()).forEach((formKey) => {
                if (formKey === 'portalOverview') {
                  const screenshots: FileList = watch('portalOverview.screenshots');

                  if (screenshots) {
                    Array.from(screenshots).forEach((file: File) => {
                      formData.append('screenshots', file);
                    });
                  }
                } else {
                  formData.append(formKey, JSON.stringify(getValues()[formKey]));
                }
              });

              mutate(formData);
            }}
            isLoading={isLoading}
            rectangled
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
