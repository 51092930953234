import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PatientOrdersHeatmap from 'pages/Analytics/pages/PatientOrdersHeatmap/PatientOrdersHeatmap';
import Reports from 'pages/Reports/Reports';
import SSPUsersMetrics from 'pages/Analytics/pages/SSPUsersMetrics/SSPUsersMetrics';
import DmeBillingDashboard from 'pages/DmeBillingDashboard/DmeBillingDashboard';
import Invoices from 'pages/DmeBillingDashboard/pages/Invoices/Invoices';
import Accounts from 'pages/DmeBillingDashboard/pages/Accounts/Accounts';
import Payment from 'pages/DmeBillingDashboard/pages/Payment/Payment';
import InvoiceDetails from 'pages/DmeBillingDashboard/pages/Invoices/pages/InvoiceDetails';
import AccountDetails from 'pages/DmeBillingDashboard/pages/Accounts/pages/AccountDetails';
import TaskManager from 'pages/TaskManager/TaskManager';
import Login from 'pages/Login/Login';
import Home from 'pages/Home/Home';
import Settings from 'pages/Settings/Settings';
import Analytics from 'pages/Analytics/Analytics';
import Users from 'pages/Users/Users';
import RegisterUser from 'pages/Users/pages/RegisterUser/RegisterUser';
import ResetUserPassword from 'pages/Users/pages/ResetUserPassword/ResetUserPassword';
import ViewAllUsers from 'pages/Users/pages/ViewAllUsers/ViewAllUsers';
import ChangeUserPassword from 'pages/Settings/pages/ChangeUserPassword/ChangeUserPassword';
import SelfServicePortal from 'pages/SelfServicePortal/SelfServicePortal';
import SSPViewAllUsers from 'pages/SelfServicePortal/pages/SSPViewAllUsers/SSPViewAllUsers';
import Appointments from 'pages/SelfServicePortal/pages/Appointments/Appointments';
import FormsAndDocuments from 'pages/SelfServicePortal/pages/FormsAndDocuments/FormsAndDocuments';
import SSPUserDetail from 'pages/SelfServicePortal/pages/SSPViewAllUsers/pages/SSPUserDetail/SSPUserDetail';
import SendFormsAndDocumentsSelectPatient from 'pages/SelfServicePortal/pages/SendFormsAndDocuments/SelectPatient/SelectPatient';
import SendFormsAndDocumentsSelectDocument from 'pages/SelfServicePortal/pages/SendFormsAndDocuments/SelectDocument/SelectDocument';
import SendFormsAndDocumentsCompleted from 'pages/SelfServicePortal/pages/SendFormsAndDocuments/Completed/Completed';
import SendFormsAndDocumentsReview from 'pages/SelfServicePortal/pages/SendFormsAndDocuments/Review/Review';
import FormDetails from 'pages/SelfServicePortal/pages/FormsAndDocuments/pages/FormDetails/FormDetails';
import CreateAppointmentSelectPatient from 'pages/SelfServicePortal/pages/CreateAppointment/SelectPatient/SelectPatient';
import CreateAppointmentSelectDate from 'pages/SelfServicePortal/pages/CreateAppointment/SelectDate/SelectDate';
import CreateAppointmentReview from 'pages/SelfServicePortal/pages/CreateAppointment/Review/Review';
import CreateAppointmentCompleted from 'pages/SelfServicePortal/pages/CreateAppointment/Completed/Completed';
import AppointmentDetails from 'pages/SelfServicePortal/pages/Appointments/pages/AppointmentDetails/AppointmentDetails';
import ManageAppointmentTimeslots from 'pages/SelfServicePortal/pages/ManageAppointmentTimeslots/ManageAppointmentTimeslots';
import ICD10CM from 'pages/Settings/pages/ICD10CM/ICD10CM';
import NPIDirectory from 'pages/Settings/pages/NPIDirectory/NPIDirectory';
import MyTasks from 'pages/TaskManager/pages/MyTasks/MyTasks';
import AllTasks from 'pages/TaskManager/pages/AllTasks/AllTasks';
import OrderDetails from 'pages/TaskManager/pages/AllTasks/pages/OrderDetails';
import Customers from 'pages/Customers/Customers';
import CustomerDetails from 'pages/Customers/pages/CustomerDetails/CustomerDetails';
import Authorizations from 'pages/Settings/pages/Authorizations/Authorizations';
import ProductCatalog from 'pages/TaskManager/pages/ProductCatalog/ProductCatalog';
import { PrivateRoute } from './components';

const MainRouter = (): JSX.Element => (
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/users" accessLevels={['admin']} component={Users} />
      <PrivateRoute exact path="/users/register-user" accessLevels={['admin']} component={RegisterUser} />
      <PrivateRoute exact path="/users/reset-user-password" accessLevels={['admin']} component={ResetUserPassword} />
      <PrivateRoute exact path="/users/all-users" accessLevels={['admin']} component={ViewAllUsers} />

      {/* ============ Self Service Portal ============ */}
      <PrivateRoute exact path="/self-service-portal" accessLevels={['admin', 'user']} component={SelfServicePortal} />
      <PrivateRoute
        exact
        path="/self-service-portal/all-users"
        accessLevels={['admin', 'user']}
        component={SSPViewAllUsers}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/all-users/:id"
        accessLevels={['admin', 'user']}
        component={SSPUserDetail}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/appointments"
        accessLevels={['admin', 'user']}
        component={Appointments}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/appointments/:id"
        accessLevels={['admin', 'user']}
        component={AppointmentDetails}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/create-appointment/select-patient"
        accessLevels={['admin', 'user']}
        component={CreateAppointmentSelectPatient}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/create-appointment/select-date"
        accessLevels={['admin', 'user']}
        component={CreateAppointmentSelectDate}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/create-appointment/review"
        accessLevels={['admin', 'user']}
        component={CreateAppointmentReview}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/create-appointment/completed"
        accessLevels={['admin', 'user']}
        component={CreateAppointmentCompleted}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/manage-appointment-timeslots"
        accessLevels={['admin', 'user']}
        component={ManageAppointmentTimeslots}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/forms-and-documents"
        accessLevels={['admin', 'user']}
        component={FormsAndDocuments}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/forms-and-documents/:id"
        accessLevels={['admin', 'user']}
        component={FormDetails}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/send-forms-and-documents/select-patient"
        accessLevels={['admin', 'user']}
        component={SendFormsAndDocumentsSelectPatient}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/send-forms-and-documents/select-document"
        accessLevels={['admin', 'user']}
        component={SendFormsAndDocumentsSelectDocument}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/send-forms-and-documents/review"
        accessLevels={['admin', 'user']}
        component={SendFormsAndDocumentsReview}
      />
      <PrivateRoute
        exact
        path="/self-service-portal/send-forms-and-documents/completed"
        accessLevels={['admin', 'user']}
        component={SendFormsAndDocumentsCompleted}
      />
      <PrivateRoute exact path="/customers" accessLevels={['admin', 'user']} component={Customers} />
      <PrivateRoute exact path="/customers/:id" accessLevels={['admin', 'user']} component={CustomerDetails} />
      {/* ============ DME Billing Dashboard ============ */}
      <PrivateRoute exact path="/dme-billing-dashboard" accessLevels={['admin']} component={DmeBillingDashboard} />
      <PrivateRoute exact path="/dme-billing-dashboard/invoices" accessLevels={['admin']} component={Invoices} />
      <PrivateRoute
        exact
        path="/dme-billing-dashboard/invoices/:id"
        accessLevels={['admin']}
        component={InvoiceDetails}
      />
      <PrivateRoute exact path="/dme-billing-dashboard/accounts" accessLevels={['admin']} component={Accounts} />
      <PrivateRoute
        exact
        path="/dme-billing-dashboard/accounts/:id"
        accessLevels={['admin']}
        component={AccountDetails}
      />
      <PrivateRoute exact path="/dme-billing-dashboard/payment" accessLevels={[]} component={Payment} />
      {/* ============ Reports ============ */}
      <PrivateRoute exact path="/reports" component={Reports} />
      {/* ============ Analytics ============ */}
      <PrivateRoute exact path="/analytics" component={Analytics} />
      <PrivateRoute exact path="/analytics/patient-orders-heatmap" component={PatientOrdersHeatmap} />
      <PrivateRoute exact path="/analytics/ssp-users-metrics" component={SSPUsersMetrics} />
      {/* ============ Task Manager ============ */}
      <PrivateRoute exact path="/task-manager" component={TaskManager} />
      <PrivateRoute exact path="/task-manager/my-tasks" component={MyTasks} />
      <PrivateRoute exact path="/task-manager/my-tasks/respond-escalation/:id" component={MyTasks} />
      <PrivateRoute exact path="/task-manager/all-tasks" component={AllTasks} />
      <PrivateRoute exact path="/task-manager/all-tasks/:id" component={OrderDetails} />
      <PrivateRoute exact path="/task-manager/all-tasks/initiate-prescriptions/:id" component={AllTasks} />
      <PrivateRoute exact path="/task-manager/all-tasks/initiate-authorizations/:id" component={AllTasks} />
      <PrivateRoute exact path="/task-manager/product-catalog" component={ProductCatalog} />
      <PrivateRoute exact path="/task-manager/my-tasks/create-task" component={MyTasks} />
      <PrivateRoute exact path="/task-manager/my-tasks/update-task/:id" component={MyTasks} />
      {/* ============ Settings ============ */}
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/settings/change-user-password" component={ChangeUserPassword} />
      <PrivateRoute exact path="/settings/authorizations" component={Authorizations} />
      <PrivateRoute exact path="/settings/authorizations/:id" component={OrderDetails} />
      <PrivateRoute exact path="/settings/icd-10-cm-directory" component={ICD10CM} />
      <PrivateRoute exact path="/settings/npi-directory" component={NPIDirectory} />
      <PrivateRoute
        component={(routeProps) => <Redirect to={{ pathname: '/home', state: { from: routeProps.location } }} />}
      />
    </Switch>
  </Router>
);

export default MainRouter;
