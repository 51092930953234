import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getAllServiceStatus } from '../../../api';
import Config from '../../../Config';
import { MenuItem } from '../../../models';

interface ReturnType {
  menuItems: MenuItem[];
  loadingStatus: boolean;
}

const useMenuItems = (): ReturnType => {
  const { data, isLoading } = useQuery('allServiceStatus', getAllServiceStatus);

  const trueGreen = '#7abd91';
  const falseRed = '#ff6962';

  const barChartIcon = '/assets/icons/bar-chart.svg';
  const errorCircleIcon = '/assets/icons/error-circle.svg';

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        path: Config.baseUrl,
        icon: data?.iportal.status === 'ok' ? barChartIcon : errorCircleIcon,
        iconColor: data?.iportal.status === 'ok' ? trueGreen : falseRed,
        title: 'iPortal',
        description: `Version: ${data?.iportal.version}`,
      },
      {
        path: Config.sspBaseUrl,
        icon: data?.ssp.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'Self Service Portal',
        description: `Version: ${data?.ssp.version}`,
        iconColor: data?.ssp.status === 'ok' ? trueGreen : falseRed,
      },
      {
        path: Config.cdnProxyBaseUrl,
        icon: data?.cdn.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'CDN Proxy',
        description: `Version: ${data?.cdn.version}`,
        iconColor: data?.cdn.status === 'ok' ? trueGreen : falseRed,
      },

      {
        path: Config.contentUrl,
        icon: data?.content.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'Content Service',
        description: `Version: ${data?.content.version}`,
        iconColor: data?.content.status === 'ok' ? trueGreen : falseRed,
      },

      {
        path: Config.pdfBaseUrl,
        icon: data?.pdf.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'PDF Service',
        description: `Version: ${data?.pdf.version}`,
        iconColor: data?.pdf.status === 'ok' ? trueGreen : falseRed,
      },
    ],
    [
      data?.cdn.status,
      data?.cdn.version,
      data?.content.status,
      data?.content.version,
      data?.iportal.status,
      data?.iportal.version,
      data?.pdf.status,
      data?.pdf.version,
      data?.ssp.status,
      data?.ssp.version,
    ],
  );

  return { menuItems, loadingStatus: isLoading };
};

export default useMenuItems;
