import { CTAButton, InputTextField, InputDropdownField, Accordion } from 'components';
import { useState, useEffect, ChangeEvent, useContext, useRef, Fragment } from 'react';
import Modal, { Styles } from 'react-modal';
import formatFullName from 'utils/formatFullName';
import formatUSPhoneNumber from 'utils/formatUSPhoneNumber';
import isValidUSPhoneNumber from 'utils/isValidUSPhoneNumber';
import { v4 as uuid } from 'uuid';
import DatePicker from 'components/DatePicker/DatePicker';
import useDatePicker from 'api/hooks/useDatePicker';
import CheckOrdersFormContext from '../../../context/CheckOrdersFormContext';
import OrderFormContext from '../../../context/OrderFormContext';
import ViewDocuments from './ViewDocuments';

const NewOrderForm = ({
  id: formId,
  passHandleSubmitFn,
  setOrderCount,
}: {
  id: string;
  passHandleSubmitFn: (submitFn: any) => void;
  setOrderCount: (count: number) => void;
}) => {
  const [newOrdersCount, setNewOrdersCount] = useState([uuid()]);
  const [expandAllAccordions, setExpandAllAccordions] = useState(true);
  const [viewDocumentsModalConfig, setViewDocumentsModalConfig] = useState({
    isOpen: false,
    orderId: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useContext(CheckOrdersFormContext);
  const { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker({
    watch,
    setValue,
  });
  const { selectedPatient } = useContext(OrderFormContext);

  const latestOrderFormRef = useRef<HTMLDivElement>(null);

  const onSubmit = (data: any, addNewOrder?: true) => {
    if (addNewOrder) {
      setExpandAllAccordions(false);
      setNewOrdersCount([...newOrdersCount, uuid()]);
    }
  };

  const validatePhone = (phone: string) => {
    if (!isValidUSPhoneNumber(phone)) return 'Invalid number';
    return true;
  };

  useEffect(() => {
    setValue(`${formId}.selectedPatient`, selectedPatient);
  }, [formId, selectedPatient, setValue]);

  useEffect(() => {
    const submitFn = () => {
      return new Promise((resolve, reject) => {
        handleSubmit(
          (data) => {
            resolve(data);
          },
          () => reject(formId),
        )();
      });
    };

    passHandleSubmitFn(submitFn);
  }, [handleSubmit, formId, passHandleSubmitFn]);

  useEffect(() => {
    setTimeout(() => {
      if (latestOrderFormRef.current) {
        latestOrderFormRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }, 400);
  }, [newOrdersCount]);

  useEffect(() => {
    setOrderCount(newOrdersCount.length);
  }, [newOrdersCount.length, setOrderCount]);

  const modalStyle: Styles = {
    content: { maxWidth: 1600, width: '60%', height: '60%', borderRadius: 20, margin: 'auto', padding: '0' },
    overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  };

  const handleDeleteDocument = (orderId: string, documentName: string) => {
    const documents = watch(`${formId}.${orderId}.uploadDocuments`) as FileList;
    const newDocuments = new DataTransfer();
    Array.from(documents).forEach((document) => {
      if (document.name !== documentName) {
        newDocuments.items.add(document);
      }
    });

    setValue(`${formId}.${orderId}.uploadDocuments`, newDocuments.files);
  };

  const watchDocuments = watch(`${formId}.${viewDocumentsModalConfig.orderId}.uploadDocuments`);

  return (
    <>
      {newOrdersCount.map((id, orderFormIndex) => (
        <Fragment key={id}>
          {watchDocuments && watchDocuments?.length > 0 && (
            <Modal
              isOpen={viewDocumentsModalConfig.isOpen}
              onRequestClose={() => setViewDocumentsModalConfig({ ...viewDocumentsModalConfig, isOpen: false })}
              style={modalStyle}
            >
              <ViewDocuments
                formId={formId}
                orderId={viewDocumentsModalConfig.orderId}
                handleDeleteDocument={handleDeleteDocument}
              />
            </Modal>
          )}
          <div
            className="MyTasks-CheckOrders-modal-new-orders-anchor"
            ref={newOrdersCount.length - 2 === orderFormIndex ? latestOrderFormRef : undefined}
          />
          <Accordion
            key={id}
            className={orderFormIndex === 0 ? 'mod-first' : ''}
            title={`Order #${orderFormIndex + 1}`}
            error={errors?.[formId]?.[id] !== undefined}
            expanded={newOrdersCount.length - 1 === orderFormIndex ? true : expandAllAccordions}
            defaultExpanded
          >
            <div id={id} className="MyTasks-CheckOrders-modal-new-orders-new-order-form">
              {newOrdersCount.length > 1 && (
                <button
                  type="button"
                  className="MyTasks-CheckOrders-modal-new-orders-new-order-form-remove-btn"
                  onClick={() => {
                    clearErrors(formId);
                    setValue(id, undefined);
                    setNewOrdersCount((prev) => {
                      return prev.filter((x) => x !== id);
                    });
                  }}
                >
                  x
                </button>
              )}
              <section>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-header">
                  <span className="MyTasks-CheckOrders-modal-title">
                    New order for
                    <>
                      <span className="MyTasks-CheckOrders-modal-title-highlight">
                        {formatFullName(
                          selectedPatient?.firstname,
                          selectedPatient?.middleinitial,
                          selectedPatient?.lastname,
                        )}
                      </span>
                    </>
                    ?
                  </span>
                  <input
                    {...register(`${formId}.${id}.uploadDocuments`)}
                    id={`${formId}.${id}.uploadDocuments`}
                    type="file"
                    multiple
                    accept="application/pdf"
                  />
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-header-cta-container">
                    {watch(`${formId}.${id}.uploadDocuments`)?.length > 0 && (
                      <CTAButton
                        onClick={() => {
                          setViewDocumentsModalConfig({
                            isOpen: true,
                            orderId: id,
                          });
                        }}
                        text={`View Documents (${watch(`${formId}.${id}.uploadDocuments`)?.length})`}
                      />
                    )}
                    <CTAButton
                      onClick={() => {
                        const input = document.getElementById(`${formId}.${id}.uploadDocuments`) as HTMLInputElement;
                        input.click();
                      }}
                      invert
                      text="Upload"
                    />
                  </div>
                </div>
                {/* <InputTextField
                  {...register(`${formId}.${id}.orderId`, { required: true })}
                  name={`${formId}.${id}.orderId`}
                  status={errors?.[formId]?.[id]?.orderId ? 'error' : 'active'}
                  type="text"
                  label="Order ID"
                  className="mod-highlight"
                /> */}
              </section>
              <section>
                <span className="MyTasks-CheckOrders-modal-new-orders-new-order-form-title">Authorization details</span>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail">
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.authorizationPayer`, { required: true })}
                      name={`${formId}.${id}.authorizationPayer`}
                      type="text"
                      label="Payer"
                      status={errors?.[formId]?.[id]?.authorizationPayer ? 'error' : 'active'}
                    />
                    <DatePicker
                      {...register(`${formId}.${id}.authorizationStartDate`)}
                      placeholder="Select date..."
                      label="Start date"
                      date={handleSetDate(`${formId}.${id}.authorizationStartDate`)}
                      maxDate={handleSetDate(`${formId}.${id}.authorizationEndDate`) || undefined}
                      status={errors?.[formId]?.[id]?.authorizationStartDate ? 'error' : 'active'}
                      bottomLabel={errors?.[formId]?.[id]?.authorizationStartDate?.message}
                      onTextChange={(e) => handleOnTextChange(e, `${formId}.${id}.authorizationStartDate`)}
                      onChange={(date: Date) => handleDateChange(date, `${formId}.${id}.authorizationStartDate`)}
                      onClickOutside={
                        (date: Date | null) => handleOnDateClickOutside(date, `${formId}.${id}.authorizationStartDate`)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                    <DatePicker
                      {...register(`${formId}.${id}.authorizationEndDate`)}
                      placeholder="Select date..."
                      label="End date"
                      date={handleSetDate(`${formId}.${id}.authorizationEndDate`)}
                      minDate={handleSetDate(`${formId}.${id}.authorizationStartDate`) || undefined}
                      status={errors?.[formId]?.[id]?.authorizationEndDate ? 'error' : 'active'}
                      bottomLabel={errors?.[formId]?.[id]?.authorizationEndDate?.message}
                      onTextChange={(e) => handleOnTextChange(e, `${formId}.${id}.authorizationEndDate`)}
                      onChange={(date: Date) => handleDateChange(date, `${formId}.${id}.authorizationEndDate`)}
                      onClickOutside={
                        (date: Date | null) => handleOnDateClickOutside(date, `${formId}.${id}.authorizationEndDate`)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.authorizationFrequency`, { required: true })}
                      name={`${formId}.${id}.authorizationFrequency`}
                      type="number"
                      label="Frequency"
                      status={errors?.[formId]?.[id]?.authorizationFrequency ? 'error' : 'active'}
                    />
                  </div>
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.authorizationNumber`, { required: true })}
                      name={`${formId}.${id}.authorizationNumber`}
                      type="text"
                      label="Authorization #"
                      status={errors?.[formId]?.[id]?.authorizationNumber ? 'error' : 'active'}
                    />
                    <InputDropdownField
                      {...register(`${formId}.${id}.authorizationStatus`, { required: true })}
                      name={`${formId}.${id}.authorizationStatus`}
                      label="Authorization Status"
                      placeholder="Select status"
                      className={errors?.[formId]?.[id]?.authorizationStatus ? undefined : 'mod-greenHighlight'}
                      items={[
                        { label: 'Complete', value: 'completed' },
                        { label: 'Pending', value: 'pending' },
                        { label: 'Incomplete', value: 'incomplete' },
                        { label: 'Rejected', value: 'rejected' },
                      ]}
                      status={errors?.[formId]?.[id]?.authorizationStatus ? 'error' : 'active'}
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.totalQuantity`, { required: true })}
                      name={`${formId}.${id}.totalQuantity`}
                      type="number"
                      label="Total Quantity"
                      status={errors?.[formId]?.[id]?.totalQuantity ? 'error' : 'active'}
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.totalProductQuantity`, { required: true })}
                      name={`${formId}.${id}.totalProductQuantity`}
                      type="number"
                      label="Total Product Quantity"
                      status={errors?.[formId]?.[id]?.totalProductQuantity ? 'error' : 'active'}
                    />
                  </div>
                </div>
              </section>
              <section>
                <span className="MyTasks-CheckOrders-modal-new-orders-new-order-form-title">Product details</span>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail">
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.providerProductNumber`, { required: true })}
                      status={errors?.[formId]?.[id]?.providerProductNumber ? 'error' : 'active'}
                      name={`${formId}.${id}.providerProductNumber`}
                      type="text"
                      label="Provider product #"
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.productDescription`, { required: true })}
                      status={errors?.[formId]?.[id]?.productDescription ? 'error' : 'active'}
                      name={`${formId}.${id}.productDescription`}
                      type="text"
                      label="Product description"
                    />
                  </div>
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.productHcpcsCode`, { required: true })}
                      status={errors?.[formId]?.[id]?.productHcpcsCode ? 'error' : 'active'}
                      name={`${formId}.${id}.productHcpcsCode`}
                      type="text"
                      label="HCPCS code"
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.productTotalCost`, { required: true })}
                      status={errors?.[formId]?.[id]?.productTotalCost ? 'error' : 'active'}
                      name={`${formId}.${id}.productTotalCost`}
                      label="Total Cost"
                      placeholder="$"
                      className="mod-greenHighlight"
                    />
                  </div>
                </div>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail">
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.productSpecialInstructions`, { required: true })}
                      status={errors?.[formId]?.[id]?.productSpecialInstructions ? 'error' : 'active'}
                      name={`${formId}.${id}.productSpecialInstructions`}
                      type="textarea"
                      label="Special Instructions"
                    />
                  </div>
                </div>
              </section>
              <section>
                <span className="MyTasks-CheckOrders-modal-new-orders-new-order-form-title">Provider information</span>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail">
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.providerInformationProvider`, { required: true })}
                      status={errors?.[formId]?.[id]?.providerInformationProvider ? 'error' : 'active'}
                      name={`${formId}.${id}.providerInformationProvider`}
                      type="text"
                      label="Provider"
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.providerInformationPhone`, { validate: validatePhone })}
                      label="Phone"
                      placeholder="(201) 555-5555"
                      type="text"
                      status={errors?.[formId]?.[id]?.providerInformationPhone ? 'error' : 'active'}
                      bottomLabel={errors?.[formId]?.[id]?.providerInformationPhone?.message}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (errors?.[formId]?.[id]?.providerInformationPhone) {
                          clearErrors(`${formId}.${id}.providerInformationPhone`);
                        }
                        setValue(`${formId}.${id}.providerInformationPhone`, formatUSPhoneNumber(e.target.value));
                      }}
                      onBlur={(e) => {
                        setValue(`${formId}.${id}.providerInformationPhone`, formatUSPhoneNumber(e.target.value));
                      }}
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.providerInformationFax`, { validate: validatePhone })}
                      label="Fax"
                      placeholder="(201) 555-5555"
                      type="text"
                      status={errors?.[formId]?.[id]?.providerInformationFax ? 'error' : 'active'}
                      bottomLabel={errors?.[formId]?.[id]?.providerInformationFax?.message}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (errors?.[formId]?.[id]?.providerInformationFax) {
                          clearErrors(`${formId}.${id}.providerInformationFax`);
                        }
                        setValue(`${formId}.${id}.providerInformationFax`, formatUSPhoneNumber(e.target.value));
                      }}
                      onBlur={(e) => {
                        setValue(`${formId}.${id}.providerInformationFax`, formatUSPhoneNumber(e.target.value));
                      }}
                    />
                  </div>
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField
                      {...register(`${formId}.${id}.providerNpi`, { required: true })}
                      status={errors?.[formId]?.[id]?.providerNpi ? 'error' : 'active'}
                      name={`${formId}.${id}.providerNpi`}
                      type="number"
                      label="Provider NPI"
                    />
                    <InputTextField
                      {...register(`${formId}.${id}.providerTaxId`, { required: true })}
                      status={errors?.[formId]?.[id]?.providerTaxId ? 'error' : 'active'}
                      name={`${formId}.${id}.providerTaxId`}
                      type="number"
                      label="Provider Tax ID"
                    />
                  </div>
                </div>
              </section>
              <section>
                <span className="MyTasks-CheckOrders-modal-new-orders-new-order-form-title">Internal notes</span>
                <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail">
                  <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-detail-section">
                    <InputTextField {...register(`${formId}.${id}.internalNotes`)} type="textarea" label="Notes" />
                  </div>
                </div>
              </section>
            </div>
          </Accordion>
          {orderFormIndex === newOrdersCount.length - 1 && (
            <div className="MyTasks-CheckOrders-modal-new-orders-new-order-form-cta-container">
              <CTAButton invert onClick={handleSubmit(() => null)} text="Save" />
              <CTAButton
                onClick={handleSubmit((formData) => onSubmit(formData, true))}
                text="+ Add more orders for this patient"
              />
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
};

NewOrderForm.displayName = 'NewOrderForm';

export default NewOrderForm;
