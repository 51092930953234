import { DropboxFile } from 'models';
import { createContext, Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export type IInitiateAuthorizationOrderContextProps = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  form: UseFormReturn<FieldValues, any>;
  currentFile: DropboxFile;
  authorizationOrder: any;
  setAuthorizationOrder: Dispatch<SetStateAction<any>>;
  isEscalateModalOpen: boolean;
  setIsEscalateModalOpen: Dispatch<SetStateAction<boolean>>;
};

const InitiateAuthorizationOrderContext = createContext<IInitiateAuthorizationOrderContextProps>(
  {} as IInitiateAuthorizationOrderContextProps,
);

export default InitiateAuthorizationOrderContext;
