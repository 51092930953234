/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, Dispatch } from 'react';

const useDebounceState = (initialValue: any, delay: number): [any, Dispatch<any>, any, Dispatch<any>] => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return [debouncedValue, setValue, value, setDebouncedValue];
};

export default useDebounceState;
