import { getAllPortalCheckList } from 'api';
import { IPortalCheckListResponseData, IPortalCheckListSuccessResponse, IPortalCheckListErrorResponse } from 'models';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import isToday from 'utils/isToday';

type Options = Omit<
  UseQueryOptions<
    IPortalCheckListSuccessResponse,
    IPortalCheckListErrorResponse,
    IPortalCheckListSuccessResponse,
    string[]
  >,
  'queryKey' | 'queryFn'
>;

interface ReturnType {
  query: UseQueryResult<IPortalCheckListSuccessResponse, IPortalCheckListErrorResponse>;
  checkList: IPortalCheckListResponseData[];
  notificationsCount: number;
}

export const useFetchPortalCheckList = (options?: Options): ReturnType => {
  const query = useQuery(['useFetchPortalCheckList'], () => getAllPortalCheckList(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });

  const checkList = useMemo(() => {
    return query.data?.data || [];
  }, [query.data]);

  const notificationsCount = useMemo(() => {
    return query.data?.data.filter((x) => !isToday(x.lastChecked)).length || 0;
  }, [query.data?.data]);

  return { query, checkList, notificationsCount };
};

export default useFetchPortalCheckList;
