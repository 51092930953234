import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getNPIDirectoryList } from 'api';

export const useFetchPhysician = () => {
  const [npiSearchTerm, setNPISearchTerm] = useState('');

  const { data: searchData, isLoading: isPhysicianSearchLoading } = useQuery(
    ['searchNPIDirectory', npiSearchTerm],
    () => getNPIDirectoryList(npiSearchTerm),
  );

  const physicians = useMemo(() => {
    if (searchData && searchData.length > 3) {
      return searchData[3];
    }
    return [];
  }, [searchData]);

  const onPhysicianSearch = useCallback((query = '') => {
    setNPISearchTerm(query);
  }, []);

  return { onPhysicianSearch, physicians, isPhysicianSearchLoading };
};

export default useFetchPhysician;
