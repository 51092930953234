const Config = {
  baseUrl: process.env.REACT_APP_API_BASE_URL as string,
  cdnProxyBaseUrl: process.env.REACT_APP_CDN_PROXY_BASE_URL as string,
  contentUrl: process.env.REACT_APP_CONTENT_API_BASE_URL as string,
  sspBaseUrl: process.env.REACT_APP_SSP_API_BASE_URL as string,
  bcsBaseUrl: process.env.REACT_APP_BCS_API_BASE_URL as string,
  pdfBaseUrl: process.env.REACT_APP_PDF_API_BASE_URL as string,
  environment: process.env.REACT_APP_ENVIRONMENT as string,
  version: process.env.REACT_APP_VERSION as string,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  companyUrl: 'www.nymannings.com',
  companyName: 'Confucius Pharmacy Inc',
  companyManhattanStoreAddress: '25 Bowery New York Ny 10002',
  companyCSHotline: '(212) 966-4420',
  nodeEnv: process.env.NODE_ENV || 'development',
  dropboxAuthToken: process.env.REACT_APP_DROPBOX_AUTH_TOKEN as string,
};

export default Config;
