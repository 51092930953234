import { getManualTaskDetails, postCreateManualTask, putUpdateManualTask } from 'api';
import { CTAButton, InputDropdownField, InputTextField } from 'components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { RootState } from 'MyTypes';
import styles from './styles.module.scss';

interface Props {
  isUpdate?: boolean;
}

const CreateManualTask = ({ isUpdate }: Props) => {
  const currentUserDetails = useSelector((state: RootState) => state.userDetails.currentUserDetails);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
    watch,
  } = useForm();

  const { id } = useParams() as any;

  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(
    (props: any) => (isUpdate ? putUpdateManualTask(id, props) : postCreateManualTask(props)),
    {
      onSuccess: (res) => {
        if (res.status !== 'ok') return;

        queryClient.invalidateQueries({
          queryKey: 'useFetchManualTasks',
        });

        history.push('/task-manager/my-tasks');
      },
      onError: (err: any) => {
        alert(err.response.message);
      },
    },
  );

  const { data, isLoading: isFetchingDetails } = useQuery(
    ['fetchManualTaskDetails', id],
    () => getManualTaskDetails(id),
    {
      enabled: isUpdate,
      onError: (err: any) => {
        alert(err.response.message);
        history.push('/task-manager/my-tasks');
      },
    },
  );

  const users = [
    {
      value: 'admin@nymannings.com',
      label: 'Admin Account - Admin',
    },
    {
      value: 'test@nymannings.com',
      label: 'Test Account - User',
    },
  ];

  if (currentUserDetails?.username) {
    const index = users.findIndex((user) => user.value === currentUserDetails.username);
    const me = {
      value: currentUserDetails.username,
      label: 'Myself',
    };
    if (index > -1) {
      users[index] = me;
    } else {
      users.unshift(me);
    }
  }

  const statuses = [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
    {
      label: `Assigned by ${currentUserDetails?.username}`,
      value: `Assigned by ${currentUserDetails?.username}`,
    },
  ];

  if (data?.status) {
    const index = statuses.findIndex((status) => status.value === data.status);
    if (index < 0) {
      statuses.push({ label: data.status, value: data.status });
    }
  }

  useEffect(() => {
    if (history.location.pathname.toLowerCase().includes('my-tasks/create-task')) {
      setValue('assignee', currentUserDetails?.username);
      setValue('status', `Assigned by ${currentUserDetails?.username}`);
    }
  }, [history.location.pathname, currentUserDetails?.username, setValue]);

  useEffect(() => {
    if (!data?.data) return;
    reset(data.data);
  }, [data?.data, reset]);

  const onSubmit = () => {
    mutate(getValues());
  };

  return (
    <div className={styles.container}>
      {isFetchingDetails ? (
        <div className={styles.clipLoader}>
          <ClipLoader color="#06bbc9" />
        </div>
      ) : (
        <>
          <InputDropdownField
            className={styles.input}
            placeholder="Select Assign To"
            noShadow
            label="Assign To"
            items={users}
            status={errors.assignee ? 'error' : 'active'}
            bottomLabel={errors.assignee?.message}
            defaultValue={watch('assignee')}
            {...register('assignee', {
              required: 'Assign To is required',
            })}
          />
          <InputTextField
            className={styles.input}
            label="Title"
            noShadow
            {...register('title', {
              required: 'Required field',
            })}
            status={errors.title ? 'error' : 'active'}
            bottomLabel={errors.title?.message}
          />
          <InputTextField
            className={styles.input}
            label="Notes"
            noShadow
            type="textarea"
            status={errors.notes ? 'error' : 'active'}
            bottomLabel={errors.notes?.message}
            {...register('notes', {
              required: 'Required field',
            })}
          />
          <InputDropdownField
            className={styles.input}
            label="Status"
            noShadow
            {...register('status', {
              required: 'Required field',
            })}
            placeholder="Select Status"
            status={errors.status ? 'error' : 'active'}
            bottomLabel={errors.status?.message}
            defaultValue={watch('status')}
            items={statuses}
          />
          <CTAButton
            isLoading={isLoading}
            rectangled
            className={styles.ctaButton}
            text={isUpdate ? 'Update' : 'Create'}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      )}
    </div>
  );
};

export default CreateManualTask;
