import {
  useFetchPortalCheckSearchPatient,
  useFetchPortalCheckTasks,
  usePostCreateNewPatient,
  usePostCreateTask,
} from 'api/hooks';
import { CTAButton, DatePicker, InputDropdownField, InputTextField } from 'components';
import dayjs from 'dayjs';
import { RootState } from 'MyTypes';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal, { Styles } from 'react-modal';
import { useSelector } from 'react-redux';
import useDatePicker from 'api/hooks/useDatePicker';
import formatUSPhoneNumber from 'utils/formatUSPhoneNumber';
import isValidUSPhoneNumber from 'utils/isValidUSPhoneNumber';
import CheckOrdersContext from '../../context/CheckOrdersContext';
import CreateNewPatientContext from '../../context/CreateNewPatientContext';
import './CreateNewPatient.scss';

const CreateNewPatient = () => {
  const currentUserDetails = useSelector((state: RootState) => state.userDetails.currentUserDetails);
  const { setCreatePatientModalConfig } = useContext(CreateNewPatientContext);
  const {
    newOrderModalConfig: { payerDetails },
  } = useContext(CheckOrdersContext);
  const [isMissingInformationModalOpen, setIsMissingInformationModalOpen] = useState(false);
  const [focusField, setFocusField] = useState<string | null>(null);

  const {
    query: { refetch: refetchPortalCheckSearchPatient },
  } = useFetchPortalCheckSearchPatient();

  const {
    query: { refetch: refetchPortalCheckTasks },
  } = useFetchPortalCheckTasks();

  const { mutateAsync: mutateAsyncPostCreateNewPatient, isLoading: isPostCreatingNewPatient } = usePostCreateNewPatient(
    {},
  );
  const { mutateAsync: mutateAsyncPostCreateTask, isLoading: isPostCreatingTask } = usePostCreateTask({
    onSuccess: (res) => {
      if (res.status === 'ok') {
        refetchPortalCheckSearchPatient();
        refetchPortalCheckTasks();
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
    setFocus,
  } = useForm();

  const { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker({
    watch,
    setValue,
  });

  const values = watch();

  const emptyFields = useMemo(() => {
    const fields = Object.keys(values).filter((key) => values[key] === '');

    const fieldDetails = fields.map((field: string) => {
      const element =
        document.querySelector(`input[name="${field}"]`) || document.querySelector(`select[name="${field}"]`);
      const label = element?.getAttribute('aria-label');

      if (!element || !label) return null;

      return {
        label,
        field,
      };
    });

    return fieldDetails.filter((field) => field !== null) as {
      label: string;
      field: string;
    }[];
  }, [values]);

  const onSubmit = useCallback(
    (data) => {
      if (emptyFields.length > 0) {
        setIsMissingInformationModalOpen(true);
        return;
      }

      setCreatePatientModalConfig({
        isOpen: false,
        patientDetails: data,
      });
    },
    [emptyFields.length, setCreatePatientModalConfig],
  );

  const onEscalateToAdmin = useCallback(
    async (data) => {
      const { firstName, lastName, dateOfBirth: dateofbirth, address, primaryInsurance, internalNotes } = data;

      const createPatientPayload = {
        patientDetails: { firstName, lastName, dateofbirth: dayjs(dateofbirth).format('YYYYMMDD'), address },
        insurerDetails: { primaryInsurance },
      };

      mutateAsyncPostCreateNewPatient(createPatientPayload).then(({ data: { customerid } }) => {
        const taskPayload = {
          taskType: 'manual',
          taskNotes: internalNotes,
          taskTitle: `Missing Patient Information: ${emptyFields.map((field) => field.label).join(', ')}`,
          currentStatus: 'escalated',
          assignedPatient: customerid,
          carrierId: payerDetails?._id,
          previousOwnerId: currentUserDetails?.username,
          currentOwnerId: currentUserDetails?.username,
        };

        mutateAsyncPostCreateTask(taskPayload).then(() => {
          setIsMissingInformationModalOpen(false);
          setCreatePatientModalConfig({
            isOpen: false,
            patientDetails: data,
          });
        });
      });
    },
    [
      currentUserDetails?.username,
      emptyFields,
      mutateAsyncPostCreateNewPatient,
      mutateAsyncPostCreateTask,
      payerDetails?._id,
      setCreatePatientModalConfig,
    ],
  );

  const handleFocusField = useCallback((field: string) => {
    setIsMissingInformationModalOpen(false);
    setFocusField(field);
  }, []);

  const modalStyle: Styles = {
    content: { maxWidth: 1600, width: '80%', height: '80%', borderRadius: 20, margin: 'auto', padding: '2rem' },
    overlay: { zIndex: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
  };

  useEffect(() => {
    if (!focusField) return;
    setTimeout(() => {
      setFocus(focusField);
    });
  }, [focusField, setFocus]);

  return (
    <>
      <Modal
        isOpen={isMissingInformationModalOpen}
        onRequestClose={() => setIsMissingInformationModalOpen(false)}
        style={modalStyle}
      >
        <div className="MyTasks-CreateNewPatient-MissingInformation-modal">
          <div className="MyTasks-CreateNewPatient-MissingInformation-modal-header">
            <img src="/assets/icons/alert.svg" alt="" />
            <span>Missing Patient Information</span>
          </div>
          <div className="MyTasks-CreateNewPatient-MissingInformation-modal-details">
            <p>You are missing the following information:</p>
            <ul>
              {emptyFields.map(({ label, field }) => (
                <li key={label}>
                  <span>{label}</span>
                  <img onClick={() => handleFocusField(field)} src="/assets/icons/edit-2.svg" alt="" />
                </li>
              ))}
            </ul>
          </div>
          <InputTextField
            label="Internal Notes"
            {...register('internalNotes')}
            type="textarea"
            placeholder="Add notes"
          />
          <div className="MyTasks-CreateNewPatient-MissingInformation-modal-cta-container">
            <span>Admin will be notified to complete profile</span>
            <CTAButton
              text="Escalate to Admin"
              isLoading={isPostCreatingNewPatient || isPostCreatingTask}
              onClick={handleSubmit(onEscalateToAdmin)}
            />
          </div>
        </div>
      </Modal>

      <form className="MyTasks-CreateNewPatient-modal" onSubmit={handleSubmit(onSubmit)}>
        <section>
          <span className="MyTasks-CreateNewPatient-modal-title">Patient details</span>
          <div className="MyTasks-CreateNewPatient-modal-form">
            <InputTextField
              {...register('firstName', { required: true })}
              type="text"
              label="First Name"
              placeholder="John"
              status={errors.firstName ? 'error' : 'active'}
              autoFocus={focusField === 'firstName'}
            />
            <InputTextField
              {...register('lastName', { required: true })}
              type="text"
              label="Last Name"
              placeholder="Doe"
              status={errors.lastName ? 'error' : 'active'}
              autoFocus={focusField === 'lastName'}
            />
            <DatePicker
              {...register('dateOfBirth')}
              placeholder="Select date..."
              label="Date of Birth"
              date={handleSetDate('dateOfBirth')}
              onTextChange={(e) => handleOnTextChange(e, 'dateOfBirth')}
              onChange={(date: Date) => handleDateChange(date, 'dateOfBirth')}
              onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'dateOfBirth')}
              forceHideBottomLabel
            />
            <InputTextField
              className="mod-fullWidth"
              {...register('address')}
              type="text"
              label="Address"
              placeholder="123 4th Ave, New York, NY 10001"
              status={errors.address ? 'error' : 'active'}
              autoFocus={focusField === 'address'}
            />
            <InputTextField
              {...register('homePhone', {
                validate: (phone) => {
                  if (phone === '') return true;
                  if (!isValidUSPhoneNumber(phone)) return 'Invalid number';
                  return true;
                },
              })}
              type="text"
              label="Home Phone"
              placeholder="(201) 555-5555"
              status={errors.homePhone ? 'error' : 'active'}
              bottomLabel={errors.homePhone?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (errors.homePhone) {
                  clearErrors('homePhone');
                }
                setValue('homePhone', formatUSPhoneNumber(e.target.value));
              }}
              onBlur={(e) => {
                setValue('homePhone', formatUSPhoneNumber(e.target.value));
              }}
              autoFocus={focusField === 'homePhone'}
            />
            <InputTextField
              {...register('cellPhone', {
                validate: (phone) => {
                  if (phone === '') return true;
                  if (!isValidUSPhoneNumber(phone)) return 'Invalid number';
                  return true;
                },
              })}
              type="text"
              label="Cell Phone"
              placeholder="(201) 555-5555"
              status={errors.cellPhone ? 'error' : 'active'}
              bottomLabel={errors.cellPhone?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (errors.cellPhone) {
                  clearErrors('cellPhone');
                }
                setValue('cellPhone', formatUSPhoneNumber(e.target.value));
              }}
              onBlur={(e) => {
                setValue('cellPhone', formatUSPhoneNumber(e.target.value));
              }}
              autoFocus={focusField === 'cellPhone'}
            />
          </div>
        </section>
        <section>
          <span className="MyTasks-CreateNewPatient-modal-title">Insurer details</span>
          <div className="MyTasks-CreateNewPatient-modal-form">
            <InputDropdownField
              {...register('primaryInsurance', { required: true })}
              placeholder="Select Primary Insurance"
              label="Primary Insurance"
              items={['Medicare', 'CIGNA']}
              status={errors.primaryInsurance ? 'error' : 'active'}
            />
            <InputTextField
              {...register('primaryInsuranceMemberId', { required: true })}
              type="number"
              label="Member ID"
              status={errors.primaryInsuranceMemberId ? 'error' : 'active'}
              autoFocus={focusField === 'primaryInsuranceMemberId'}
            />
            <InputDropdownField
              {...register('secondaryInsurance')}
              placeholder="Select Secondary Insurance"
              label="Secondary Insurance"
              items={['Medicare', 'CIGNA']}
              status={errors.secondaryInsurance ? 'error' : 'active'}
            />
            <InputTextField
              {...register('secondaryInsuranceMemberId')}
              type="number"
              label="Member ID"
              status={errors.secondaryInsuranceMemberId ? 'error' : 'active'}
              autoFocus={focusField === 'secondaryInsuranceMemberId'}
            />
          </div>
        </section>
        <CTAButton text="Create" type="submit" />
      </form>
    </>
  );
};

export default CreateNewPatient;
