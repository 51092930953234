import { Card, ColumnConfig, Table, TableInfiniteScrollWrapper } from 'components';
import { DashboardLayout } from 'layouts';

import { useCallback } from 'react';
import { useFetchSearchProducts } from 'api/hooks';

const ProductCatalog = () => {
  const {
    products,
    query: { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage },
  } = useFetchSearchProducts();
  const columnConfig: ColumnConfig[] = [
    {
      heading: 'Equip Type',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].equipType,
    },
    {
      heading: 'Sub Type',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].subType,
    },
    {
      heading: 'Make',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].make,
    },
    {
      heading: 'sku',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].sku,
    },
    {
      heading: 'hcpcs',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].hcpcs,
    },
    {
      heading: 'Description',
      flex: '0 0 200px',
      renderColumn: (index) => products[index].description,
    },
  ];

  return (
    <DashboardLayout title="Product Catalog">
      <Card noPadding>
        <Table
          loadingRows={isLoading}
          rowHeight={80}
          itemCount={products.length}
          columnsConfig={columnConfig}
          getRowKey={useCallback((index) => `${products[index]?.hcpcs}-${products[index]?.sku}`, [products])}
          renderFixedSizeList={(ListComponent) => (
            <TableInfiniteScrollWrapper
              hasNextPage={hasNextPage || false}
              isNextPageLoading={isFetchingNextPage}
              items={products}
              loadNextPage={() => {
                setTimeout(() => {
                  fetchNextPage();
                }, 1000);
              }}
            >
              {({ onItemsRendered, ref, itemCount, renderRow }) => (
                <ListComponent
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  itemCount={itemCount}
                  renderRow={renderRow}
                />
              )}
            </TableInfiniteScrollWrapper>
          )}
        />
      </Card>
    </DashboardLayout>
  );
};

export default ProductCatalog;
