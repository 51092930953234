import { CTAButton, InputDropdownField, InputTextField, InputAutoCompleteField, DatePicker } from 'components';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { useFetchCustomer, useFetchPreviewDropboxFile, useFetchSearchProducts, useFetchPhysician } from 'api/hooks';
import { useFieldArray } from 'react-hook-form';
import useDatePicker from 'api/hooks/useDatePicker';
import InitiateEmailFaxOrderContext from '../../context';
import styles from './PrescriptionDetails.module.scss';

const PrescriptionDetails = () => {
  const { setCurrentStep, form, currentFile, setIsEscalateModalOpen } = useContext(InitiateEmailFaxOrderContext);
  const { previewSrc } = useFetchPreviewDropboxFile(currentFile.path_display);
  const { onPhysicianSearch, physicians, isPhysicianSearchLoading } = useFetchPhysician();
  const {
    setSearchValue: setSearchProductValue,
    products,
    setDebouncedSearchValue: setDebouncedSearchProductValue,
    query: { isLoading: isSearchProductLoading },
  } = useFetchSearchProducts();
  const {
    register,
    watch,
    formState: { errors },
    getValues,
    handleSubmit,
    clearErrors,
    setValue,
    control,
  } = form;
  const { handleSetDate, handleOnTextChange, handleDateChange, handleOnDateClickOutside } = useDatePicker(form);
  const customerId = watch('primaryDetails.customerId');
  const { patient } = useFetchCustomer(customerId);

  const [productsCount, setProductsCount] = useState<number>(getValues('prescriptionDetails.products')?.length || 1);

  useFieldArray({
    control,
    name: 'prescriptionDetails.products',
  });

  const prescriptionDetails = watch('prescriptionDetails');
  const physician = watch('prescriptionDetails.physician');

  useEffect(() => {
    setValue('prescriptionDetails.medicare_medicaid', patient?.medicare_medicaid);

    setValue('prescriptionDetails.primaryInsurance', patient?.primaryins);
    setValue('prescriptionDetails.primaryInsuranceMemberNumber', patient?.primaryins_memberno);

    setValue('prescriptionDetails.secondaryInsurance', patient?.secondaryins);
    setValue('prescriptionDetails.secondaryInsuranceMemberNumber', patient?.secondaryins_memberno);

    setValue('prescriptionDetails.tertiaryInsurance', patient?.tertiaryins);
    setValue('prescriptionDetails.tertiaryInsuranceMemberNumber', patient?.tertiaryins_memberno);
  }, [patient, setValue]);

  useEffect(() => {
    onPhysicianSearch(physician);
  }, [physician, onPhysicianSearch]);

  const watchSearchProductQueries = Array(productsCount).map((i) => watch(`prescriptionDetails.products.[${i}].hcpcs`));

  useEffect(() => {
    watchSearchProductQueries.forEach((query) => {
      if (query === '') setSearchProductValue('');
      setDebouncedSearchProductValue(query);
    });
  }, [setDebouncedSearchProductValue, setSearchProductValue, watchSearchProductQueries]);

  const handleNext = useCallback(() => {
    setCurrentStep((prev) => prev + 1);
  }, [setCurrentStep]);

  const handleBack = useCallback(() => {
    setCurrentStep((prev) => prev - 1);
  }, [setCurrentStep]);

  const handleEscalate = useCallback(() => {
    setIsEscalateModalOpen(true);
  }, [setIsEscalateModalOpen]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.previewContainer}>
          <iframe title="preview" src={previewSrc} />
        </div>
        <div className={styles.formContainer}>
          <span className={styles.title}>Prescription details</span>
          <hr />
          <InputDropdownField
            className={styles.inputField}
            label="Order Type *"
            noShadow
            placeholder="Select order type"
            status={errors.prescriptionDetails?.orderType ? 'error' : 'active'}
            bottomLabel={errors.prescriptionDetails?.orderType?.message}
            items={['DME', 'Incontinence-Enteral', 'Other']}
            defaultValue={watch('prescriptionDetails.orderType')}
            {...register('prescriptionDetails.orderType', {
              required: 'Required field',
            })}
          />
          <InputDropdownField
            className={styles.inputField}
            label="Delivery method"
            noShadow
            placeholder="Select delivery method"
            status={errors.prescriptionDetails?.deliveryMethod ? 'error' : 'active'}
            bottomLabel={errors.prescriptionDetails?.deliveryMethod?.message}
            items={['Delivery', 'Pickup', 'Unknown']}
            defaultValue={watch('prescriptionDetails.deliveryMethod')}
            {...register('prescriptionDetails.deliveryMethod')}
          />
          <DatePicker
            {...register('prescriptionDetails.rxDate')}
            className={styles.inputField}
            placeholder="Select date..."
            label="RX Date *"
            date={handleSetDate('prescriptionDetails.rxDate')}
            onTextChange={(e) => handleOnTextChange(e, 'prescriptionDetails.rxDate')}
            onChange={(date: Date) => handleDateChange(date, 'prescriptionDetails.rxDate')}
            onClickOutside={(date: Date | null) => handleOnDateClickOutside(date, 'prescriptionDetails.rxDate')}
            forceHideBottomLabel
            noShadow
          />
          <InputAutoCompleteField
            className={styles.inputField}
            label="Physician"
            noShadow
            placeholder="Search physician"
            items={physicians.map((data: any = []) => {
              return {
                label: `${data[0]} - ${data[3]} - ${data[1]} - ${data[4]}`,
                value: `${data[0]} - ${data[3]} - ${data[1]} - ${data[4]}` || '',
              };
            })}
            defaultValue={physician}
            {...register('prescriptionDetails.physician')}
            onSelect={(value: string) => {
              if (errors.prescriptionDetails?.physician) {
                clearErrors('prescriptionDetails.physician');
              }
              setValue('prescriptionDetails.physician', value);
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                onPhysicianSearch(e.currentTarget.value);
              }
            }}
            isLoading={isPhysicianSearchLoading}
            bottomLabel={errors.prescriptionDetails?.physician?.message}
            status={errors.prescriptionDetails?.physician ? 'error' : 'active'}
          />
          {!!watch('prescriptionDetails.medicare_medicaid') && (
            <>
              <hr />
              <InputTextField
                className={styles.inputField}
                label="Medicare/Medicaid"
                noShadow
                placeholder=""
                defaultValue={watch('prescriptionDetails.medicare_medicaid')}
                {...register('prescriptionDetails.medicare_medicaid')}
                disabled
              />
            </>
          )}
          {!!(
            watch('prescriptionDetails.primaryInsurance') || watch('prescriptionDetails.primaryInsuranceMemberNumber')
          ) && (
            <>
              <hr />
              <InputTextField
                className={styles.inputField}
                label="Primary Insurance"
                noShadow
                placeholder="Primary insurance"
                defaultValue={watch('prescriptionDetails.primaryInsurance')}
                {...register('prescriptionDetails.primaryInsurance')}
                disabled
              />
              <InputTextField
                className={styles.inputField}
                label="Primary Insurance Member Number"
                noShadow
                placeholder="Primary Insurance Member Number"
                defaultValue={watch('prescriptionDetails.primaryInsuranceMemberNumber')}
                {...register('prescriptionDetails.primaryInsuranceMemberNumber')}
                disabled
              />
              <div className={styles.checkboxContainer}>
                <div className={styles.inputField}>
                  <Checkbox
                    {...register('prescriptionDetails.primaryPayerAuthorizationRequestRequired', {
                      required: !(
                        prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired ||
                        prescriptionDetails?.secondaryPayerAuthorizationRequestRequired
                      )
                        ? 'At least one payer must have authorization request required'
                        : undefined,
                    })}
                    checked={!!prescriptionDetails?.primaryPayerAuthorizationRequestRequired}
                    onChange={(e) => {
                      setValue('prescriptionDetails.primaryPayerAuthorizationRequestRequired', e.target.checked);
                      if (e.target.checked) {
                        setValue('prescriptionDetails.tertiaryPayerAuthorizationRequestRequired', false);
                        setValue('prescriptionDetails.secondaryPayerAuthorizationRequestRequired', false);
                      }
                    }}
                  />
                  <span>Authorization Request Required</span>
                </div>
              </div>
              {errors.prescriptionDetails?.primaryPayerAuthorizationRequestRequired && (
                <div className={styles.errorLabel}>
                  {errors.prescriptionDetails?.primaryPayerAuthorizationRequestRequired.message}
                </div>
              )}
            </>
          )}
          {!!(
            watch('prescriptionDetails.secondaryInsurance') ||
            watch('prescriptionDetails.secondaryInsuranceMemberNumber')
          ) && (
            <>
              <hr />
              <InputTextField
                className={styles.inputField}
                label="Secondary Insurance"
                noShadow
                placeholder="Secondary insurance"
                defaultValue={watch('prescriptionDetails.secondaryInsurance')}
                {...register('prescriptionDetails.secondaryInsurance')}
                disabled
              />
              <InputTextField
                className={styles.inputField}
                label="Secondary Insurance Member Number"
                noShadow
                placeholder="Secondary Insurance Member Number"
                defaultValue={watch('prescriptionDetails.secondaryInsuranceMemberNumber')}
                {...register('prescriptionDetails.secondaryInsuranceMemberNumber')}
                disabled
              />
              <div className={styles.checkboxContainer}>
                <div className={styles.inputField}>
                  <Checkbox
                    {...register('prescriptionDetails.secondaryPayerAuthorizationRequestRequired', {
                      required:
                        !(
                          prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired ||
                          prescriptionDetails?.primaryPayerAuthorizationRequestRequired
                        ) ||
                        (prescriptionDetails?.primaryPayerAuthorizationRequestRequired === 'undefined' &&
                          prescriptionDetails?.secondaryPayerAuthorizationRequestRequired === 'undefined' &&
                          prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired === 'undefined')
                          ? 'At least one payer must have authorization request required'
                          : undefined,
                    })}
                    checked={!!prescriptionDetails?.secondaryPayerAuthorizationRequestRequired}
                    onChange={(e) => {
                      setValue('prescriptionDetails.secondaryPayerAuthorizationRequestRequired', e.target.checked);
                      if (e.target.checked) {
                        setValue('prescriptionDetails.tertiaryPayerAuthorizationRequestRequired', false);
                        setValue('prescriptionDetails.primaryPayerAuthorizationRequestRequired', false);
                      }
                    }}
                  />
                  <span>Authorization Request Required</span>
                </div>
              </div>
              {errors.prescriptionDetails?.secondaryPayerAuthorizationRequestRequired && (
                <div className={styles.errorLabel}>
                  {errors.prescriptionDetails?.secondaryPayerAuthorizationRequestRequired.message}
                </div>
              )}
            </>
          )}
          {!!(
            watch('prescriptionDetails.tertiaryInsurance') || watch('prescriptionDetails.tertiaryInsuranceMemberNumber')
          ) && (
            <>
              <hr />
              <InputTextField
                className={styles.inputField}
                label="Tertiary Insurance"
                noShadow
                placeholder="Tertiary insurance"
                defaultValue={watch('prescriptionDetails.tertiaryInsurance')}
                {...register('prescriptionDetails.tertiaryInsurance')}
                disabled
              />
              <InputTextField
                className={styles.inputField}
                label="Tertiary Insurance Member Number"
                noShadow
                placeholder="Tertiary Insurance Member Number"
                defaultValue={watch('prescriptionDetails.tertiaryInsuranceMemberNumber')}
                {...register('prescriptionDetails.tertiaryInsuranceMemberNumber')}
                disabled
              />
              <div className={styles.checkboxContainer}>
                <div className={styles.inputField}>
                  <Checkbox
                    {...register('prescriptionDetails.tertiaryPayerAuthorizationRequestRequired', {
                      required: !(
                        prescriptionDetails?.secondaryPayerAuthorizationRequestRequired ||
                        prescriptionDetails?.primaryPayerAuthorizationRequestRequired
                      )
                        ? 'At least one payer must have authorization request required'
                        : undefined,
                    })}
                    checked={!!prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired}
                    onChange={(e) => {
                      setValue('prescriptionDetails.tertiaryPayerAuthorizationRequestRequired', e.target.checked);
                      if (e.target.checked) {
                        setValue('prescriptionDetails.secondaryPayerAuthorizationRequestRequired', false);
                        setValue('prescriptionDetails.primaryPayerAuthorizationRequestRequired', false);
                      }
                    }}
                  />
                  <span>Authorization Request Required</span>
                </div>
              </div>
              {errors.prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired && (
                <div className={styles.errorLabel}>
                  {errors.prescriptionDetails?.tertiaryPayerAuthorizationRequestRequired.message}
                </div>
              )}
            </>
          )}
          <hr />
          {Array.from({ length: productsCount }).map((_, index) => (
            <Fragment key={(index + 1).toString()}>
              <div className="InputDropdownField-input-label" style={{ paddingBottom: '0' }}>
                Product *
              </div>
              <div className={styles.splitInputContainer}>
                <InputAutoCompleteField
                  className={styles.inputField}
                  noShadow
                  placeholder="Search HCPCS"
                  items={products.map((data: any = []) => {
                    return {
                      label: `${data.hcpcs} - ${data.description}`,
                      value: data.hcpcs || '',
                    };
                  })}
                  isLoading={isSearchProductLoading}
                  defaultValue={watch(`prescriptionDetails.products.[${index}].hcpcs`)}
                  {...register(`prescriptionDetails.products.[${index}].hcpcs`)}
                  onSelect={(value: string) => {
                    if (errors.prescriptionDetails?.products?.[index]?.hcpcs) {
                      clearErrors(`prescriptionDetails.products.[${index}].hcpcs`);
                    }
                    setValue(`prescriptionDetails.products.[${index}].hcpcs`, value);
                    const product = products.find((p) => p.hcpcs === value);
                    setValue(
                      `prescriptionDetails.products.[${index}].productCategory`,
                      `${product?.equipType} - ${product?.subType}`,
                    );
                    setValue(`prescriptionDetails.products.[${index}].productItem`, product?.description);
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    if (e.key === 'Enter') {
                      setSearchProductValue(e.currentTarget.value);
                    }
                  }}
                  // isLoading={isPhysicianSearchLoading}
                  bottomLabel={errors.prescriptionDetails?.products?.[index]?.hcpcs?.message}
                  status={errors.prescriptionDetails?.products?.[index]?.hcpcs ? 'error' : 'active'}
                />
              </div>
              <div className={styles.splitInputContainer}>
                <InputTextField
                  className={styles.inputField}
                  noShadow
                  placeholder="Product Category"
                  status={errors.prescriptionDetails?.products?.[index]?.productCategory ? 'error' : 'active'}
                  bottomLabel={errors.prescriptionDetails?.products?.[index]?.productCategory?.message}
                  defaultValue={watch(`prescriptionDetails.products.[${index}].productCategory`)}
                  {...register(`prescriptionDetails.products.[${index}].productCategory`, {
                    required: 'Required field',
                  })}
                />
                <InputTextField
                  className={styles.inputField}
                  noShadow
                  placeholder="Product Item"
                  defaultValue={watch(`prescriptionDetails.products.[${index}].productItem`)}
                  {...register(`prescriptionDetails.products.[${index}].productItem`, {
                    required: 'Required field',
                  })}
                  status={errors.prescriptionDetails?.products?.[index]?.productItem ? 'error' : 'active'}
                  bottomLabel={errors.prescriptionDetails?.products?.[index]?.productItem?.message}
                />
              </div>
              <div className={styles.splitInputContainer}>
                <InputTextField
                  {...register(`prescriptionDetails.products.[${index}].productQuantity`, {
                    required: 'Required field',
                    validate: (value) => {
                      if (value < 1) {
                        return 'Quantity must be greater than 0';
                      }
                      return true;
                    },
                  })}
                  className={styles.inputField}
                  label=""
                  noShadow
                  placeholder="Enter quantity"
                  type="number"
                  status={errors.prescriptionDetails?.products?.[index]?.productQuantity ? 'error' : 'active'}
                  bottomLabel={errors.prescriptionDetails?.products?.[index]?.productQuantity?.message}
                />
                <InputDropdownField
                  className={styles.inputField}
                  noShadow
                  placeholder="Unit of measure *"
                  items={['EA', 'CS', 'BX', 'PK', 'PR']}
                  defaultValue={watch(`prescriptionDetails.products.[${index}].uom`)}
                  {...register(`prescriptionDetails.products.[${index}].uom`, {
                    required: 'Required field',
                  })}
                  status={errors.prescriptionDetails?.products?.[index]?.uom ? 'error' : 'active'}
                  bottomLabel={errors.prescriptionDetails?.products?.[index]?.uom?.message}
                />
              </div>
            </Fragment>
          ))}
          <CTAButton
            className={styles.addAnotherProductCtaButton}
            text="Add another product"
            rectangled
            onClick={() => setProductsCount((prev) => prev + 1)}
          />
          <div className={styles.ctaContainer}>
            <div>
              <CTAButton onClick={handleBack} rectangled text="Back" invert />
            </div>
            <div>
              <CTAButton onClick={handleEscalate} rectangled text="Escalate" invert />
              <CTAButton onClick={handleSubmit(handleNext)} rectangled text="Next" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionDetails;
